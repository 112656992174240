import { useEffect, useState } from "react";
import { getAddressList, deleteAddress } from "../../../services/shipping";
import { notificationService } from "../../../services/notifications/notificationService";
import DeleteConfirm from "./deleteConfirm";
import NoDataFound from "../../../services/utils/noDataPopUp";
import { Grid } from "@mui/material";
import './profile.css';
import { Icons } from "../../../images/reactIcons";

const Address = () => {

    const [address, setAddress] = useState([]);
    const [deleteId, setDeleteId] = useState(null);

    const getAllAddress = () => {
        getAddressList()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    setAddress(data);
                }
            })
            .catch((error) => {
                setAddress([]);
            });
    };

    const deleteAddressHandler = (val) => {
        if (val) {
            deleteAddress(deleteId)
                .then((response) => {
                    if (response.success && response.receiveObj) {
                        const data = response.receiveObj;
                        getAllAddress();
                        setDeleteId(null);
                        notificationService.sendMessage({ type: "success", title: "Address Data", text: data.error })
                    }
                })
                .catch((error) => {
                    notificationService.sendMessage({ type: "error", title: "Address Data", text: error.receiveObj.message })
                });
        } else {
            setDeleteId(null);
        }
    };

    useEffect(() => {
        getAllAddress();
    }, []);

    return (
        <>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 font-articulat mt-4" style={{ fontSize: '16px', fontWeight: '600' }}>Address</div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 font-articulat">
                <Grid container style={{ margin: '15px 0px', display: "flex", height: "auto" }} columnGap={3} rowGap={3}>
                    {address.length !== 0 ? (
                        address.map(ele => {
                            return (
                                <Grid item lg={5} md={5} sm={5} xs={12} className="cart-boxs" key={ele?.id}>
                                    <div className="address-box">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className=" text-sm font-semibold">{(ele.title ? ele.title : "") + " " + (ele.first_name ? ele.first_name : "") + " " + (ele.last_name ? ele.last_name : "")}</div>
                                            <div className=" text-right text-xl"><Icons.FaTrash className="text-[#FF0000] cursor-pointer h-4 w-4" aria-hidden="true" onClick={() => setDeleteId(ele.id)}/></div>
                                        </div>
                                        <div className="text-xs font-normal" style={{ paddingRight: '30px' }}>{(ele.address_line_1 ? ele.address_line_1 : "") + " " + (ele.address_line_2 ? ele.address_line_2 : "") + " " + (ele.city ? ele.city : "") + " " + (ele.state ? ele.state : "") + " " + (ele.country ? ele.country : "") + " " + (ele.postal_code ? ele.postal_code : "")}</div>
                                        <div className="text-xs font-normal">{ele.country_code + "-" + ele.phone_number}</div>
                                        <div className="text-xs font-normal">{ele.mail}</div>
                                    </div>
                                </Grid>
                            )
                        })
                    ) : (
                        <NoDataFound />
                    )}
                </Grid>
            </div>


            {deleteId && <DeleteConfirm setShowModal={(val) => deleteAddressHandler(val)} message="Are You Sure.You want to delete ?" />}
        </>
    )
}
export default Address;