import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_ITEM, ADD_ALL_ITEMS_TO_CART, RESET_CART_ITEMS } from "../constants";


const initialState = {
    cart: [],
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_ITEMS_TO_CART:
            return {
                ...state,
                cart: action.payload
            };
        case ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, { ...action.payload }]
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart:state.cart.filter(item => 
                    action.payload?.textureId !== null && action.payload?.textureId !== undefined
                        ? !(item.variant_id === action.payload.variantId && item.texture_id === action.payload.textureId) 
                        : item.variant_id !== action.payload.variantId
                )
            };
        case UPDATE_CART_ITEM:
            return {
                ...state,
                cart: state.cart.map(item => 
                    action.payload?.textureId !== null && action.payload?.textureId !== undefined
                        ? (item.variant_id === action.payload.productId && item.texture_id === action.payload.textureId) 
                            ? { ...item, quantity: action.payload.quantity } 
                            : item
                        : item.variant_id === action.payload.productId 
                            ? { ...item, quantity: action.payload.quantity } 
                            : item
                )
                    
            };
        case RESET_CART_ITEMS:
            return {
                ...state,
                cart: []
            };
        default:
            return state;
    }
};

export default cartReducer;