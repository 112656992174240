import { useEffect, useState } from "react";
import { fetchGetApi } from "../../../services/apis.functions/api.functions";
import { Container, Stack } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { SpinLoader } from "../../../app/loader/loader";
import './dispatchdelivery.css'
import ApplicationHelmet from "../../../navigation/navigation";

const DispatchDelivery = () => {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetchGetApi('/dispatch_and_delivery', {})
            .then(res => {
                setLoading(false)
                if (res?.data?.status === "Success") {
                    setData(res?.data?.data);
                };
            })
            .catch(error => {
                return error;
            })
    }, []);
    return (
            <Container maxWidth={false} style={{ paddingTop: '150px', paddingBottom: '50px' }} className="dispatchcontainer">
                <ApplicationHelmet/>
                <Box>
                    <Stack>
                        <Typography variant="h5" className="dispatchanddelivery">
                            Dispatch and Delivery
                        </Typography>
                    </Stack>
                    <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                        {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                    </div>
                    <Box className="dispatchContent" marginTop={2}>
                        {data.map(content => {
                            return (
                                <Box key={content?.id}>
                                    <Typography variant="body2" style={{fontFamily:'Greycliff CF' }} className="dispatchdiscription" dangerouslySetInnerHTML={{ __html: content?.content }} />
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Container>
    )
}
export default DispatchDelivery;