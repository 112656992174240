import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import "./style.css";
import images from "../../../images/images";
import { fetchPostApi } from "../../../services/apis.functions/api.functions";
import { toast } from "react-toastify";

const HomeSubscribe = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSubscribe = async () => {
    if (!email) {
      setError('Please enter your email');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    const data = {
      newsletter: {
        email: email
      }
    };
    try {
      const res = await fetchPostApi('/privacy_policies/news_letter', data);
      if (res?.status === 201) {
        setMessage(res);
        setError(null);
      } else {
        setError('An unexpected error occurred');
      }
    } catch (error) {
      setError(error?.response?.data?.error || 'An unexpected error occurred');
    }
    setEmail('');
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    } else if (message?.status === 201) {
      toast.success(message?.data?.message);
    }
  }, [message, error]);

  return (
    <>
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-7 bg-[#EBC794] subscribeBox">
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 mt-4">
          <div className="lg:col-span-6 md:col-span-7 sm:col-span-12 text-center p-2 ml-5 mt-9 mb-9">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 divide-x">
              <div className="lg:col-span-9 md:col-span-9 sm:col-span-9 font-articulat">
                <input
                  type="text"
                  label="Email"
                  name="email"
                  id="email"
                  className="h-full w-full bg-white text-sm font-normal mr-2 font-articulat p-3"
                  placeholder="Enter your email to receive newsletters for updates, sales, and other offers."
                  style={{ fontSize: "12px", color: "#333333", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
               </div>
              <span
                className="lg:col-span-3 md:col-span-3 sm:col-span-3 text-center p-2 bg-[#163f16] font-articulat font-normal text-lg text-white focus:outline-none focus:ring-4 hover:bg-[#163f16] focus:ring-[#163f16] cursor-pointer"
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#FBF5F1",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => {
                  if (isValidEmail) {
                    handleSubscribe();
                  }
                }}
              >
                Subscribe Now
              </span>
            </div>
          </div>
          <div className="lg:col-span-6 md:col-span-5 sm:col-span-12 text-right mt-9 mr-2 subscribeicons">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 social-icons" style={{ alignItems: 'center' }}>
              <span className="lg:col-span-8 md:col-span-4 sm:col-span-1"></span>
              <span className="lg:col-span-1 md:col-span-2 sm:col-span-2 text-center mt-1 cursor-pointer">
                <a href="https://www.facebook.com/gprealfoods/" target="_blank" rel="noopener noreferrer">
                  <img src={images.facebook} className="object-cover mt-1 w-5 h-5" alt="facebook" loading="lazy" />
                </a>
              </span>
              <span className="lg:col-span-1 md:col-span-2 sm:col-span-3 text-center mt-1 cursor-pointer">
                <a href="https://www.linkedin.com/company/grind-pound/" target="_blank" rel="noopener noreferrer">
                  <img src={images.linkdin} className="object-cover mt-1 w-5 h-5" alt="linkedIn" loading="lazy" />
                </a>
              </span>
              <span className="lg:col-span-1 md:col-span-2 sm:col-span-3 text-center mt-1 cursor-pointer">
               <Link to="https://www.instagram.com/grindpound/" target="_blank"><img src={images.instagram} className="object-cover mt-1 w-5 h-5" alt="instagram" loading="lazy"/></Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Responsive subscribeBox */}
      <div className="subscribeBox-1">
        <Grid container spacing={4} sx={{ mt: 7, backgroundColor: '#EBC794' }}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <div className="mt-9">
                  <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={2} sm={2}></Grid>
                    <Grid item xs={2} sm={2} className="text-center cursor-pointer flex justify-center">
                      <a href="https://www.facebook.com/gprealfoods/" target="_blank" rel="noopener noreferrer">
                        <img src={images.facebook} alt="Facebook" className="object-cover mt-1" />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} className="text-center cursor-pointer flex justify-center">
                      <a href="https://www.linkedin.com/company/grind-pound/" target="_blank" rel="noopener noreferrer">
                        <img src={images.linkdin} alt="Twitter" className="object-cover mt-1 w-6" />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} className="text-center cursor-pointer flex justify-center">
                      <Link to="https://www.instagram.com/grindpound/" target="_blank"><img src={images.instagram} loading="lazy" alt="Instagram" className="object-cover mt-1" /></Link>
                    </Grid>
                    <Grid item xs={2} sm={2}></Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '40px' }}>
                  <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        type="text"
                        name="email"
                        placeholder="Enter your Email to get newsletter updates, sales, and other offers"
                        className="subscribeinputbox"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (validateEmail(e.target.value)) {
                            setIsValidEmail(true);
                            setError('');
                          } else {
                            setIsValidEmail(false);
                            setError('Please enter a valid email address.');
                          }
                        }}
                        InputProps={{ style: { textAlign: 'center', fontSize: '14px' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-center">
                      <button className="subscributtonbox buttoncommon text-white py-3 text-xs" 
                       onClick={() => {
                        if (isValidEmail) {
                          handleSubscribe();
                        }
                      }}
                      >
                        Subscribe Now</button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default HomeSubscribe;
