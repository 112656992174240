import React from 'react';
import './style.css';

export const SpinLoader = ({ height, width }) => {
  return (
    <div className="custom-loader-container">
      <div className="circle" style={{ height, width }}></div>
    </div>
  );
};


const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader"> </div>{' '} Processing...
    </div>
  );
};

export default Loader;
