import axios from "axios";
import { AWS_API_URL } from "../../constant";

export const getToken = () => localStorage.getItem('token')?.replace(/"/g, "");

export const fetchPostApi = async (url, data) => {
    try {
        const response = await axios.post(AWS_API_URL + url, data);
        return response;
    } catch (error) {
        throw error; 
    }
};

export const fetchGetApi = async (url, data) => {
    try {
        const response = await axios.get(AWS_API_URL + url, data);
        return response;
    } catch (error) {
        return error;
    }
};