// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
    display: inline-grid;
    height: 100vh;
    width: 100%
};`, "",{"version":3,"sources":["webpack://./src/app/style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;IACb;AACJ,CAAA","sourcesContent":[".main-container {\n    display: inline-grid;\n    height: 100vh;\n    width: 100%\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
