function NoDataFound() {

    return (
        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold mt-4"
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                padding: "10px"
            }}
        >
            <span className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold mt-4">No Data Found!</span>
        </div>
    )
};

export default NoDataFound;