import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileInput from "../../../components/Shared/ProfileInput";
import TextInput from "../../../components/Shared/TextInput";
import TextInputWithIcon from "../../../components/Shared/TextInputWithIcon";
import SelectInput from "../../../components/Shared/SelectInput";
import HomeSubscribe from "../../public/Home/homeSubsrice";
import { setUserProfile } from "../../../services/profile";
import "./Profile.css";
import { SpinLoader } from "../../../app/loader/loader";
import images from "../../../images/images";
import { useNavigate } from "react-router-dom";
import TermsPopus from "./Profilepopup";
import { addItemsToCart } from "../../../services/checkout";
import { Icons } from "../../../images/reactIcons";

const Profile = () => {
    const [isModalOpen, setIsModalOpen] = useState({
        termsmodal: false,
        termndprivacylink: ''
    });
    const [profileData, setProfileData] = useState({});
    const [profile, setProfile] = useState(null);
    const [name, setName] = useState({});
    const cartStore = useSelector((state) => state.cartReducer.cart);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [checkbox, setCheckbox] = useState(null);
    const [checkboxError, setCheckboxError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();
    const redirectHandler = (url) => {
        window.location.replace(`${window.location.origin}${url}`);
    };

    const submitHandler = () => {
        setMsg("");
        const formData = new FormData();

        // Form validation
        if (!name.title || name.title === "Choose Title") {
            setName({ ...name, title_error: true });
            return;
        }

        if (!name.first_name) {
            setName({ ...name, first_name_error: true });
            return;
        }

        if (!emailValidate(email)) {
            setEmailError(true);
            return;
        }

        if (!checkbox) {
            setCheckboxError(true);
            return;
        }
        setLoader(true);
        // Form data preparation
        if (profile) {
            formData.append("user[attachment]", profile);
        }
        formData.append("user[title]", name.title);
        formData.append("user[first_name]", name.first_name);
        formData.append("user[last_name]", name?.last_name || '');
        formData.append("user[email]", email);
        formData.append("user[phone_number]", profileData.phone_number);
        formData.append("user[acceptance]", checkbox);

        // Submitting form data
        setUserProfile(formData)
            .then(async (response) => {
                setLoader(false);
                if (response.success && response.receiveObj) {
                    if (cartStore?.length !== 0) {
                        await Promise.all(cartStore?.map(async (obj) => {
                            await addItemsToCart({ "product_id": obj.id, "quantity": obj.quantity, "variant_id": obj.variant_id, "texture_id": obj.texture_id !== null && obj.texture_id !== undefined  ? obj?.texture_id : null });
                        }));
                    }
                    redirectHandler("/home");
                };
            })
            .catch((error) => {
                setLoader(false);
                let errors = "";
                if (error.receiveObj?.response?.data?.errors) {
                    error.receiveObj?.response?.data?.errors.map((vals) => {
                        if (error.receiveObj?.response?.data?.errors?.length === 1) {
                            errors += vals;
                        } else {
                            errors += vals + ", ";
                        }
                    });
                } else {
                    errors = error?.receiveObj?.message;
                };
                setMsg(errors);
            });
    };

    const setProfileHandler = (e) => {
        setProfile(e.target.files[0]);
    };

    const emailValidate = (email) => {
        let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
        if (email?.match(pattern) && email) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (state) {
            setProfileData({ country_code: state.code, phone_number: state.phone });
        };
    }, []);
    const toggleModal = (modalType) => {
        setIsModalOpen((prevState) => ({
            termsmodal: !prevState.termsmodal,
            termndprivacylink: modalType
        }));
    };;
    return (
        <>
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-8 mt-28">
                <div className="lg:col-span-5 md:col-span-3 sm:col-span-1"></div>
                <div className="lg:col-span-2 md:col-span-6 sm:col-span-10 mt-3 profileupload">
                    <ProfileInput
                        defaultValue={profile}
                        onChange={(e) => setProfileHandler(e)}
                    />
                </div>
                <div className="lg:col-span-5 md:col-span-3 sm:col-span-1"></div>
                <div className="lg:col-span-3 md:col-span-2 sm:col-span-1"></div>
                <div className="lg:col-span-6 md:col-span-8 sm:col-span-10 mt-3 text-lg p-2">
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 gap-4 mb-4">
                        <div className="lg:col-span-2 md:col-span-2 sm:col-span-2">
                            <SelectInput
                                onChange={(e) =>
                                    setName({
                                        ...name,
                                        title: e.target.value,
                                        title_error: false,
                                    })
                                }
                                defaultValue={name?.title}
                                type="text"
                                id="title"
                                placeholder="Enter Title"
                                isLabelShow={true}
                                showLabel="Title"
                                options={["Title", "Mr.", "Mrs.", "Ms.", "Other"]}
                                error={name.title_error}
                            />
                            {name.title_error && (
                                <span className="text-sm font-normal text-[#FF0000]">
                                    Title is Required.
                                </span>
                            )}
                        </div>
                        <div className="lg:col-span-5 md:col-span-5 sm:col-span-5">
                            <TextInput
                                onChange={(e) =>
                                    setName({
                                        ...name,
                                        first_name: e.target.value,
                                        first_name_error: false,
                                    })
                                }
                                defaultValue={name?.first_name}
                                type="text"
                                id="first_name"
                                errorMessage="First name is required."
                                placeholder="Enter First Name"
                                isLabelShow={true}
                                showLabel="First Name"
                                error={name.first_name_error}
                            />
                            {name.first_name_error && (
                                <span className="text-sm font-normal text-[#FF0000]">
                                    First Name is Required.
                                </span>
                            )}
                        </div>
                        <div className="lg:col-span-5 md:col-span-5 sm:col-span-5">
                            <TextInput
                                onChange={(e) =>
                                    setName({ ...name, last_name: e.target.value })
                                }
                                defaultValue={name?.last_name}
                                type="text"
                                id="last_name"
                                errorMessage="Last Name is Required."
                                placeholder="Enter Last Name"
                                isLabelShow={true}
                                showLabel="Last Name"
                                error={name?.lastNameError}
                            />
                        </div>
                    </div>
                    <TextInputWithIcon
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(false);
                        }}
                        defaultValue={email}
                        icon={<Icons.FaEnvelope/>}
                        type="text"
                        id="email"
                        placeholder="Enter email address."
                        isLabelShow={true}
                        error={emailError}
                        showLabel="Email"
                        errorMessage="Email address is required."
                    />
                    {emailError && (
                        <span className="text-sm font-normal text-[#FF0000]">
                            Invalid Email Address.
                        </span>
                    )}
                    <span className="block mb-2 font-sm font-articulat font-semibold text-xs mt-4">
                        Phone
                    </span>
                    <div className="flex lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 border-gray-200 border rounded-md mt-3 p-1 bg-[#FBF5F1] profilephoneno">
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3 text-xs font-articulat font-normal p-1.5 pr-3 text-center">
                            {profileData.country_code}
                        </div>
                        <div className="lg:col-span-10 md:col-span-6 sm:col-span-6 text-xs font-articulat font-normal block w-full p-1.5 pl-3  border-l border-gray-400">
                            {profileData.phone_number}
                        </div>
                        <div className="lg:col-span-1 md:col-span-3 sm:col-span-3 text-xs font-articulat font-normal p-1.5 text-right">
                            <img
                                src={images.right}
                                loading="lazy"
                                className="object-cover"
                                alt="profileDataImage"
                            />
                        </div>
                    </div>
                    <div className="flex mt-5 ml-1">
                        <input
                            id="active"
                            name="active"
                            type="checkbox"
                            value={checkbox}
                            onChange={(e) => {
                                setCheckbox(e.target.checked);
                                setCheckboxError(false);
                            }}
                            className="w-4 h-4 mt-.5 text-blue-600 border-gray-400 border-b rounded"
                        />
                        <label
                            htmlFor="checkbox"
                            className="ms-2 ml-3 text-sm  font-poppins text-[#1C1243]"
                        >
                           I accept <span onClick={e => toggleModal('terms-of-use')}  className="hover:bg-[#009898] hover:text-[#ffffff] text-[#009898]" style={{ borderBottom: "1px solid #009898", }}>Terms & Conditions</span> and <span onClick={e => toggleModal('privacy-policy')} className="hover:bg-[#009898] hover:text-[#ffffff] text-[#009898]" style={{ borderBottom: "1px solid #009898", }}>Privacy Policy</span>
                        </label>
                    </div>
                    {checkboxError && (
                        <span className="text-sm font-normal text-[#FF0000]">
                            Please accept Terms & Privacy
                        </span>
                    )}
                    {msg && (
                        <span className="text-sm font-normal text-[#FF0000]">{msg}</span>
                    )}
                </div>
                <div className="lg:col-span-3 md:col-span-2 sm:col-span-1"></div>
                <div className="lg:col-span-3 md:col-span-2 sm:col-span-1"></div>
                <div className="lg:col-span-6 md:col-span-8 sm:col-span-10 mt-3 p-2">
                    <button
                        className="mr-3 select-none rounded-md font-articulat buttoncommon text-xs text-white p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                        type="button"
                        onClick={submitHandler}
                        style={{
                            fontSize: "14px",
                            padding: "10px 30px"
                        }}
                    >
                        {loader ? <SpinLoader height="20px" width="20px"/> : "Create"}
                    </button>
                    <button
                        className="select-none border text-[#FF0000] rounded-md font-articulat bg-white text-xs p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                        type="button"
                        onClick={() => redirectHandler("/home")}
                        style={{
                            fontSize: "14px",
                            padding: "10px 30px"
                        }}
                    >
                        Cancel
                    </button>
                </div>
                <div className="lg:col-span-3 md:col-span-2 sm:col-span-1"></div>
                {isModalOpen && <TermsPopus isModalOpen={isModalOpen} toggleModal={toggleModal} />}
            </div>
            <HomeSubscribe />
        </>
    );
};

export default Profile;
