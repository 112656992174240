import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CartList from "../view/public/CartList";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Collapse, Grid, ListItemIcon } from '@mui/material';
import './navbar.css'
import { fetchPostApi } from '../services/apis.functions/api.functions';
import { updateFetch, updateProducts } from '../reducers/search/actions';
import { navigateToNext } from '../navigation/navigation';
import images from '../images/images';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getFilterProduct } from '../services';
import FinalLogos from "../images/finallogo.jpg"
import useDebounce from './useDedounce';

const drawerWidth = 240;
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #000',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingRight: '30px',
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '9ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function DrawerAppBar(props) {
    const { window } = props;
    const { search } = useLocation();
    const queryParams = React.useMemo(() => search.substring(4, search.length), [search]);
    const [auth, setAuth] = React.useState(false);
    const [current, setCurrent] = React.useState(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showCart, setShowCart] = React.useState(false);
    const [showSearch, setShowSearch] = React.useState(false);
    const [flag, setFlag] = React.useState(false);
    const cartStore = useSelector(state => state.cartReducer.cart);
    const searchStore = useSelector(state => state.searchReducer);
    const [searchKey, setSearchKey] = useState('');
    const [openSubcategories, setOpenSubcategories] = useState(false);
    const [openSubcateg, setOpenSubcateg] = useState('');
    const [filterSubcat, setFilterSubCat] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productList, setProductList] = useState([]);
    const name = auth ? 'Profile' : "Login";
    const link = auth ? '/profileaccount' : "/login";
    const sortedProductList = productList?.categories?.length > 0 ? productList?.categories?.sort((a, b) => a.position - b.position) : [];

    //  Large screen
    const navItems = (sortedProductList || []).map((item, index) => ({
        id: item.id,
        position: item.position,
        name: item.name.trim(),
        link: `/product/${item?.position}`,
        subCategory: 1,

    }));
    // small screen
    const navItems1 = [
        { name: sortedProductList[0]?.name.trim() || 'Default 1', link: `/product/${sortedProductList[0]?.position}`, subCategory: 1, },
        { name: sortedProductList[1]?.name.trim() || 'Default 2', link: `/product/${sortedProductList[1]?.position}`, subCategory: 1, },
        { name: sortedProductList[2]?.name.trim() || 'Default 3', link: `/product/${sortedProductList[2]?.position}`, subCategory: 1, },
        { name: 'Location', link: '/location' },
        { name: name, link: link },
        {
            name: 'About', link: '',
            subCategories: [
                { name: "Our Story", link: "/ourstory" },
                { name: "Our Blogs", link: "/ourblogs" },
                { name: "Reviews", link: "/reviews" },
            ]
        },
        {
            name: 'Help', link: '',
            subCategories: [
                { name: "Privacy Policy", link: "/privacypolicy" },
                { name: "Terms and Conditions", link: "/termsandconditions" },
                { name: "Order Status", link: "/orderstatus" },
                { name: "Dispatch and Delivery", link: "/dispatchdelivery" },
                { name: "Return and Cancellation", link: "/returncancellation" },
                { name: "FAQ", link: "/faq" },
            ]
        }
    ];
    // medium device
    const navItems2 = [
        { name: sortedProductList[0]?.name.trim() || 'Default 1', link: `product/${sortedProductList[0]?.position}`, subCategory: 1, },
        { name: sortedProductList[1]?.name.trim() || 'Default 2', link: `product/${sortedProductList[1]?.position}`, subCategory: 1, },
        { name: sortedProductList[2]?.name.trim() || 'Default 3', link: `product/${sortedProductList[2]?.position}`, subCategory: 1, },
        {
            name: 'About', link: '',
            subCategories: [
                { name: "Our Story", link: "/ourstory" },
                { name: "Our Blogs", link: "/ourblogs" },
                { name: "Reviews", link: "/reviews" },
            ]
        },
        {
            name: 'Help', link: '', subCat: true,
            subCategories: [
                { name: "Privacy Policy", link: "/privacypolicy" },
                { name: "Terms and Conditions", link: "/termsandconditions" },
                { name: "Order Status", link: "/orderstatus" },
                { name: "Dispatch and Delivery", link: "/dispatchdelivery" },
                { name: "Return and Cancellation", link: "/returncancellation" },
                { name: "FAQ", link: "/faq" },
            ]
        }
    ];

    const handleDrawerToggle = (e, url, subCategory) => {
        setOpenSubcategories(false);
        setMobileOpen(prevState => !prevState);
        if (url) {
            if (subCategory) {
                navigateToNext(e, url, navigate, subCategory);
            } else {
                navigateToNext(e, url, navigate);
            }
        };
    };

    const currentPage = (val) => {
        if (val) {
            setCurrent(val.substring(0, 1))
        };
    };

    useEffect(() => {
        currentPage(queryParams)
        var token = localStorage.getItem('token');

        if (token?.length > 0) {
            setAuth(!auth);
        };
    }, [window?.location?.pathname]);

    const filterSubCategory = (name, screen) => {
        const filteredSubCate = screen === 'small' ? navItems1.filter((f) => f.name === name) : navItems2.filter((f) => f.name === name);
        setFilterSubCat(filteredSubCate[0]?.subCategories);
    }
    // small device
    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2, ml: 3 }}>
                <img src={FinalLogos} loading="lazy" alt='logo-navbar' className="object-cover mt-1 cursor-pointer w-44 h-auto" onClick={(e) => handleDrawerToggle(e, "/home")} />
            </Typography>
            <Divider />
            <List>
                {navItems1.map((item, i) => {
                    if (!item?.subCategories) {
                        return (
                            <ListItem key={i} disablePadding>
                                <ListItemButton sx={{ textAlign: 'center' }} className='hover:text-[#009898]'>
                                    <ListItemText className="headerfonts" primary={item.name} onClick={(e) => handleDrawerToggle(e, item.link, (Math.random() * (i + 1) + item?.subCategory))} />
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        const isOpen = (openSubcateg === item.name) && openSubcategories;
                        return (
                            <Fragment key={i}>
                                <ListItem disablePadding>
                                    <ListItemButton className='hover:text-[#009898]' onClick={() => {
                                        if (openSubcateg === item.name) {
                                            setOpenSubcategories(!openSubcategories);
                                        } else {
                                            filterSubCategory(item.name, 'small');
                                            setOpenSubcateg(item.name);
                                            setOpenSubcategories(true);
                                        }
                                    }}>
                                        <ListItemText primary={item.name} className='hover:text-[#009898] headerfonts' />
                                        {(openSubcateg === item.name && openSubcategories) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {filterSubcat?.length > 0 && filterSubcat.map((val, j) => {
                                            return (
                                                <ListItem key={j} style={{ paddingTop: '0px', paddingBottom: "0px" }}>
                                                    <ListItemButton className='hover:text-[#009898]'>
                                                        <ListItemText className="headerfonts" style={{ paddingLeft: "0px" }} inset primary={val?.name} onClick={(e) => handleDrawerToggle(e, val.link, (Math.random() * (j + 1) + val?.subCategory))} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            </Fragment>
                        )
                    }
                })}
            </List>
        </Box>
    );
    // medium device
    const drawer1 = (
        <Box>
            <Typography variant="h6" sx={{ my: 2, ml: 3 }}>
                <img src={FinalLogos} loading="lazy" className="object-cover mt-1 cursor-pointer w-44" onClick={(e) => handleDrawerToggle(e, "/home")} alt='fianlLogos' />
            </Typography>
            <Divider />
            <List>
                {navItems2.map((item, k) => {
                    if (!item?.subCategories) {
                        return (
                            <ListItem key={k} disablePadding>
                                <ListItemButton className='hover:text-[#009898]'>
                                    <ListItemText className="headerfonts" primary={item.name} onClick={(e) => handleDrawerToggle(e, item.link, (Math.random() * (k + 1) + item?.subCategory))} />
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        const isOpen = (openSubcateg === item.name) && openSubcategories;
                        return (
                            <Fragment key={k}>
                                <ListItem disablePadding>
                                    <ListItemButton className='hover:text-[#009898]' onClick={() => {
                                        if (openSubcateg === item.name) {
                                            setOpenSubcategories(!openSubcategories);
                                        } else {
                                            filterSubCategory(item.name, 'small');
                                            setOpenSubcateg(item.name);
                                            setOpenSubcategories(true);
                                        }
                                    }}>
                                        <ListItemText primary={item.name} className='hover:text-[#009898] headerfonts' />
                                        {(openSubcateg === item.name && openSubcategories) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {filterSubcat?.length > 0 && filterSubcat.map((val, i) => {
                                            return (
                                                <ListItem key={i} style={{ paddingTop: '0px', paddingBottom: "0px" }}>
                                                    <ListItemButton className='hover:text-[#009898]'>
                                                        <ListItemText className="headerfonts" style={{ paddingLeft: "0px" }} inset primary={val?.name} onClick={(e) => handleDrawerToggle(e, val.link, (Math.random() * (i + 1) + val?.subCategory))} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            </Fragment>
                        )
                    }
                })}
            </List>
        </Box>
    );

    const debounceSearch = useDebounce(searchKey, 500)
    const searchData = async () => {
        const data = {
            "name": debounceSearch
        };
        try {
            const res = await fetchPostApi('/products/search', data);
            if (res?.data?.product?.length !== 0) {
                setFlag(false);
                dispatch(updateProducts(res?.data?.product));
            } else {
                dispatch(updateProducts([]));
                setFlag(true);
            };
            dispatch(updateFetch(true));
        } catch (error) {
            return error;
        };
    };

    useEffect(() => {
        if (debounceSearch === '') {
            dispatch(updateProducts([]));
            setFlag(false);
        } else {
            if (debounceSearch) {
                searchData();
            }
        };
    }, [debounceSearch]);

    const closeListBox = () => {
        dispatch(updateProducts([]));
    };

    const handleSearchClose = () => {
        if (searchKey === "") {
            setShowSearch(false);
        };
        setFlag(false);
        setSearchKey("");
        dispatch(updateProducts([]));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.listBox') === null) { closeListBox(); };
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => { document.body.removeEventListener('click', handleClickOutside); };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.navappbar') === null) {
                setShowSearch(false);
                setSearchKey('');
                setFlag(false);
            };
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => { document.body.removeEventListener('click', handleClickOutside); };
    }, []);

    const handleListClick = (e, items) => {
        dispatch(updateProducts([]));
        setSearchKey('');
        setShowSearch(false);
        const newCategory = encodeURIComponent(items?.category?.name);
        const newSubCategory = encodeURIComponent(items?.subcategory?.name);
        navigateToNext(e, `/productdetails/${newCategory}/${newSubCategory}/${items?.id}`, navigate);
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    const getCategory = (category) => {
        const payload = {
            keyword: "",
        };

        getFilterProduct(payload)
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj.data;
                    setProductList(data);
                };
            })
            .catch((error) => {
                setProductList([])
            });
    };
    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
        if (!showCart) {
            navigate();
        }
    }, [showCart]);

    return (
        <Box sx={{ display: 'flex', }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: 'white', color: 'black', marginTop: '50px' }} className='navappbar'>
                <Toolbar>
                    <Box
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ display: { sm: 'none' } }}
                        className='w-full'
                    >
                        <Grid container style={{ marginTop: '10px', display: "flex", alignItems: "center" }}>
                            <Grid item xs={1} sm={4} md={4} lg={4} xl={4} onClick={() => { setMobileOpen((prevState) => !prevState); }}>
                                <MenuIcon sx={{ zIndex: 99 }} />
                            </Grid>
                            <Grid item xs={9} sm={4} md={4} lg={4} xl={4} style={{ display: "flex", justifyContent: "center" }}>
                                <img src={FinalLogos} alt='final-logo' loading="lazy" className="object-cover cursor-pointer" style={{ width: '110px' }} onClick={(e) => navigateToNext(e, "/home", navigate)} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={4} lg={4} xl={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <img src={images.search} alt='search-image' loading="lazy" style={{ width: '18px', height: '18px', marginRight: '15px' }} onClick={() => { setShowSearch(!showSearch) }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={4} lg={4} xl={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ position: 'relative' }} onClick={() => { setShowCart(!showCart); setShowSearch(false); }}>
                                    <img src={images?.cart} alt='cart-image' loading="lazy" />
                                    {cartStore?.length > 0 && (
                                        <span
                                            className='cartIcon'
                                            style={{
                                                width: "21px",
                                                height: "21px",
                                                padding: "3px 5px",
                                                borderRadius: "10px",
                                                backgroundColor: "red",
                                                fontSize: "10px",
                                                color: "rgb(255, 255, 255)",
                                                fontWeight: "bold",
                                                position: "absolute",
                                                zIndex: "-9",
                                                top: "-13px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >{cartStore?.length}</span>
                                    )}
                                </Box>
                            </Grid>
                            {showSearch && (
                                <Grid item xs={12} sm={7} md={8} lg={4} xl={4} style={{ marginTop: '10px' }}>
                                    <Search style={{ position: "relative" }} className='search-container'>
                                        <SearchIconWrapper onClick={searchData}>
                                            <img src={images.search} loading="lazy" alt='image-search' style={{ width: '18px', height: '18px' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            value={searchKey}
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={e => { setSearchKey(e.target.value) }}
                                            onKeyDown={e => { if (e.key === 'Enter') { searchData(); } }}
                                            className='search-input'
                                        />
                                        {searchKey !== "" && (searchStore?.products?.length !== 0 || flag) && (
                                            <Box
                                                sx={{ position: 'absolute', width: '100%', bgcolor: 'background.paper', top: '38px' }}
                                                className="listBox"
                                            >
                                                <nav onBlur={() => { dispatch(updateProducts([])) }}>
                                                    <List>
                                                        {!flag ? (
                                                            searchStore?.products?.slice(0, 5).map((items, i) => {
                                                                return (
                                                                    <ListItem key={i} disablePadding onClick={(e) => handleListClick(e, items)}>
                                                                        <ListItemButton>
                                                                            <ListItemIcon>
                                                                                <img src={items?.attachment_url[0]} style={{ width: '30px', height: '30px' }} alt='list-items' loading="lazy" />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={items?.name} className='listText' />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        ) : (
                                                            <ListItem style={{ padding: '0px' }}>
                                                                <ListItemButton>
                                                                    <ListItemText primary="No data found!" className='listText headerfonts' />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    </List>
                                                </nav>
                                            </Box>
                                        )}
                                        <CloseIcon style={{ position: "absolute", right: "8px", fontSize: "20px", top: "20%" }} onClick={handleSearchClose} />
                                    </Search>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {/* medium device */}
                    <Box
                        color="inherit"
                        aria-label="open drawer1"
                        edge="start"
                        className='navbarsubdiv'>
                        <Grid container spacing={6}>
                            <Grid item xs={4} sm={1} md={1} lg={4} xl={4} style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }} onClick={() => { setMobileOpen((prevState) => !prevState); }}>
                                <MenuIcon style={{ zIndex: 99 }} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={4} xl={4} >
                                <img src={FinalLogos} alt='final-logos' loading="lazy" className="object-cover cursor-pointer h-12" onClick={(e) => navigateToNext(e, "/home", navigate)} />
                            </Grid>
                            <Grid item className='navbarmainiconsec' xs={4} sm={7} md={8} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button key={1} sx={{ color: 'black', minWidth: '30px', padding: '0px', paddingRight: '10px' }} onClick={(e) => navigateToNext(e, "/location", navigate)}>
                                    <img src={images.location} alt='image-location' loading="lazy" />
                                </Button>
                                <Search style={{ position: "relative" }}>
                                    <SearchIconWrapper onClick={searchData}>
                                        <img src={images.search} alt='image-search' style={{ width: '20px', height: '20px' }} loading="lazy" />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        value={searchKey}
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={e => { setSearchKey(e.target.value) }}
                                        onKeyDown={e => { if (e.key === 'Enter') { searchData(); } }}
                                        className='search-input'
                                    />
                                    {searchKey !== "" && (searchStore?.products?.length !== 0 || flag) && (
                                        <Box
                                            sx={{ position: 'absolute', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                            className="listBox"
                                        >
                                            <nav onBlur={() => { dispatch(updateProducts([])) }}>
                                                <List>
                                                    {!flag ? (
                                                        searchStore?.products?.slice(0, 5).map((items, i) => {
                                                            return (
                                                                <ListItem key={i} disablePadding onClick={(e) => handleListClick(e, items)}>
                                                                    <ListItemButton>
                                                                        <ListItemIcon>
                                                                            <img src={items?.attachment_url[0]} loading="lazy" alt='list-item-icon' style={{ width: '30px', height: '30px' }} />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={items?.name} className='listText' />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    ) : (
                                                        <ListItem style={{ padding: '0px' }}>
                                                            <ListItemButton>
                                                                <ListItemText primary="No data found!" className='listText headerfonts' />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </nav>
                                        </Box>
                                    )}
                                    {searchKey !== "" && <CloseIcon style={{ position: "absolute", right: "8px", fontSize: "20px", top: "20%" }} onClick={handleSearchClose} />}
                                </Search>
                                <Button key={3} sx={{ color: 'black', minWidth: '30px', padding: '0px', paddingLeft: '15px', paddingRight: '10px' }} onClick={(e) => navigateToNext(e, auth ? "/profileaccount" : "/login", navigate)}>
                                    <img src={images.profile} alt='profile-image' loading="lazy" />
                                </Button>
                                <Button key={4} sx={{ color: 'black', position: "relative", display: "flex", justifyContent: "center", alignItems: "center", minWidth: '30px', padding: '0px' }} onClick={() => {
                                    setShowCart(true)
                                }}>

                                    <img src={images.cart} alt='cart-image' loading="lazy" />
                                    {cartStore?.length > 0 && (
                                        <span
                                            style={{
                                                width: "21px",
                                                height: "21px",
                                                padding: "3px 5px",
                                                borderRadius: "10px",
                                                backgroundColor: "red",
                                                fontSize: "10px",
                                                color: "#fff",
                                                fontWeight: "bold",
                                                position: "absolute",
                                                zIndex: "-9",
                                                top: "-13px",
                                                right: "-4px"
                                            }}
                                        >{cartStore?.length}</span>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* large Device */}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        className='navbarmainDiv'
                    >
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={4} sm={4} md={2} lg={3} xl={4} style={{ textAlign: 'left' }}>
                                <img src={FinalLogos} alt='logo-navbar' loading="lazy" className="object-cover cursor-pointer mt-1 w-40 h-auto" onClick={(e) => navigateToNext(e, "/home", navigate)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'center', marginTop: '14px', display: 'flex', gap: '8px' }}>
                                {navItems.map((item, i) => (
                                    <Button
                                        key={item.position}
                                        sx={{
                                            color: (current === i ? 'red' : 'black'),
                                            textTransform: "unset",
                                            fontSize: "16px",
                                            fontFamily: 'Greycliff CF',
                                            '&:hover': {
                                                color: '#009898',
                                                backgroundColor: '#FFFFFF'
                                            }
                                        }}
                                        onClick={(e) => {
                                            navigateToNext(e, item.link, navigate, Math.random() * (i + 1) + item.subCategory)
                                        }}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </Grid>
                            <Grid item xs={4} sm={4} md={5} lg={5} xl={4} style={{ textAlign: 'right', marginTop: '12px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                <Button key={1} sx={{ color: 'black', minWidth: '36px' }} className='location' onClick={(e) => navigateToNext(e, "/location", navigate)}><img src={images.location} loading="lazy" alt='location-image' className='h-7 w-7' /></Button>
                                <Search style={{ position: "relative", margin: '6px 8px' }} className='search'>
                                    <SearchIconWrapper onClick={searchData}>
                                        <img src={images.search} loading="lazy" alt='search-image' style={{ width: '17px', height: '17px' }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        value={searchKey}
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        //  onChange={e => { setSearchKey(e.target.value); setFlag(false); }}
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                                                setSearchKey(value);
                                                setFlag(false);
                                            }
                                        }}
                                        onKeyDown={e => { if (e.key === 'Enter') { searchData(); } }}
                                    />
                                    {searchKey !== "" && (searchStore?.products?.length !== 0 || flag) && (
                                        <Box
                                            sx={{ position: 'absolute', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                            className="listBox"
                                        >
                                            <nav onBlur={() => { dispatch(updateProducts([])) }}>
                                                <List>
                                                    {!flag ? (
                                                        searchStore?.products?.slice(0, 5).map((items, i) => {
                                                            return (
                                                                <ListItem key={i} disablePadding onClick={(e) => handleListClick(e, items)}>
                                                                    <ListItemButton>
                                                                        <ListItemIcon>
                                                                            <img src={items?.attachment_url[0]} loading="lazy" alt='image-list-iconUrl' style={{ width: '30px', height: '30px' }} />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={items?.name} className='listText' />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    ) : (
                                                        <ListItem style={{ padding: '0px' }}>
                                                            <ListItemButton>
                                                                <ListItemText primary="No data found!" className='listText headerfonts' />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </nav>
                                        </Box>
                                    )}
                                    {searchKey !== "" && <CloseIcon style={{ position: "absolute", right: "8px", fontSize: "20px", top: "20%" }} onClick={handleSearchClose} />}
                                </Search>
                                <Button key={3} sx={{ color: 'black', minWidth: '40px' }} className='login' onClick={(e) => navigateToNext(e, auth ? "/profileaccount" : "/login", navigate)}><img src={images.profile} loading="lazy" alt='image-profile-alt' className='h-6 w-6' /></Button>
                                <Button key={4} sx={{ textAlign: "right", color: "black", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", minWidth: '40px' }} onClick={() => {

                                    setShowCart(true)
                                }}>
                                    <img src={images?.cart} alt='cart-image' loading="lazy" className='h-6 w-6' />
                                    {cartStore?.length > 0 && (
                                        <span
                                            style={{
                                                width: "21px",
                                                height: "21px",
                                                padding: "3px 5px",
                                                borderRadius: "10px",
                                                backgroundColor: "red",
                                                fontSize: "10px",
                                                color: "rgb(255, 255, 255)",
                                                fontWeight: "bold",
                                                position: "absolute",
                                                zIndex: "-9",
                                                top: "-7px",
                                                right: "0px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontFamily: 'Greycliff CF'
                                            }}
                                        >{cartStore?.length}</span>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Typography>
                </Toolbar>
            </AppBar>
            {showCart && <CartList cartList={cartStore} setShowModal={(v) => setShowCart(v)} auth={auth} />}
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={(e) => handleDrawerToggle(e, "")}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        },
                    }}
                    slotProps={{
                        backdrop: {
                            style: {
                                backgroundColor: 'transparent'
                            },
                        },
                    }}
                    className='drawer-1'
                >
                    <Box>
                        <IconButton
                            onClick={() => { setMobileOpen(false) }}
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {drawer}
                </Drawer>
            </nav>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={(e) => handleDrawerToggle(e, "")}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        }
                    }}
                    slotProps={{
                        backdrop: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                    className='drawer-1'
                >
                    <Box>
                        <IconButton
                            onClick={() => { setMobileOpen(false) }}
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                            }}
                            className='menu-btnss'
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {drawer1}
                </Drawer>
            </nav>
        </Box>
    );
}

DrawerAppBar.propTypes = {
    window: PropTypes.func,
};

export default DrawerAppBar;