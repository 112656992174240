const images = {
    location: require('./location.svg').default,
    search: require('./search.svg').default,
    profile: require('./profile.svg').default,
    cart: require('./cart.svg').default,
    cartActive: require('./cartActive.svg').default,
    cartInactive: require('./cartInactive.svg').default,
    milled: require('./milled.svg').default,
    packing: require('./packing.svg').default,
    flours: require('./flours.svg').default,
    facebook: require('./facebook.svg').default,
    twitter: require('./twitter.svg').default,
    instagram: require('./instagram.svg').default,
    youtube: require('./youtube.svg').default,
    down: require('./down.png').default,
    right: require('./right.svg').default,
    subscriptionicon: require('./subscriptionplan.svg').default,
    linkdin:require('./linkedin.svg').default,
};

export default images;