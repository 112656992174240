import { useState, useEffect } from 'react';

const useZoomLevel = () => {
  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleZoom = () => {
      setZoomLevel(window.devicePixelRatio);
    };

    window.addEventListener('resize', handleZoom);
    const zoomCheckInterval = setInterval(handleZoom, 100);

    return () => {
      window.removeEventListener('resize', handleZoom);
      clearInterval(zoomCheckInterval);
    };
  }, []);

  return zoomLevel;
};

export default useZoomLevel;
