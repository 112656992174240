import { useEffect, useState } from "react";
import { isMobile, useMobileOrientation } from 'react-device-detect';
import HomeSubscribe from "../Home/homeSubsrice";
import { getBaneerImg } from "../../../services/index";
import { notificationService } from "../../../services/notifications/notificationService";
import { getOurStoryList } from "../../../services/footer";
import { Box, Typography } from "@mui/material";
import "./ourstory.css";
import { fetchAboutUs } from "../../../services/checkout";
import { SpinLoader } from "../../../app/loader/loader";
import ApplicationHelmet from "../../../navigation/navigation";
const OurStory = () => {
  const [bannerList, setBannerList] = useState([]);
  const [ourStoryList, setOurStory] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  const [advertiseList, setAdvertiseList] = useState([]);
  const { isLandscape } = useMobileOrientation();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    fetchAboutUs()
      .then(res => {
        setLoading(false)
        if (res?.status === "Success") {
          setData(res?.data);
        };
      })
      .catch(error => {
        return error;
      })
  }, []);
  const getBannerList = () => {
    getBaneerImg()
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj.banners;
          const banner = data.filter((ele) => ele.banner_type === "banner");
          const advertise = data.filter(
            (ele) => ele.banner_type === "advertise"
          );
          const promotion = data.filter(
            (ele) => ele.banner_type === "promotion"
          );
          setAdvertiseList(advertise);
          setPromotionList(promotion);
          setBannerList(banner);
        }
      })
      .catch((error) => {
        setBannerList([]);
        setAdvertiseList([]);
        setPromotionList([]);
        notificationService?.sendMessage({
          type: "error",
          title: "Banner List",
          text: error.receiveObj?.message,
        });
      });
    getOurStoryList()
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj.banners;
          setOurStory(data);
        }
      })
      .catch((error) => {
        setOurStory([]);
        notificationService.sendMessage({
          type: "error",
          title: "Our Story List",
          text: error.receiveObj.message,
        });
      });
  };
  useEffect(() => {
    setLoading(true)
    getBannerList();
  }, []);

  const aboutBannerImage = (isMobile && !isLandscape) ? data[0]?.mobile_image.url : data[0]?.image_url.url;

  return (
    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 orderstorymaindiv">
      <ApplicationHelmet />
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
        <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
          {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
        </div>
        <div className={`${(isMobile && !isLandscape) ? 'bans-img-mobile' : 'bans-img'}`}>
          <img
            src={aboutBannerImage}
            className={"slide object-cover w-full h-full"}
            alt="bannerImage"
            loading="lazy"
          />
        </div>
      </div>
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-center text-xl font-articulat font-semibold outstorywhatisbox">
        About Us
      </div>
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
        {data.map(content => {
          return (
            <Box key={content?.id}>
              <Typography variant="body2" className="ourstoryImg" dangerouslySetInnerHTML={{ __html: content?.content }} />
            </Box>
          );
        })}
      </div>
      <HomeSubscribe />
    </div>
  );
};

export default OurStory;
