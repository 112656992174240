import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import FinalLogos from "../../images/finallogo.jpg";
import './LoginModal.css';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    '&:hover': {
        backgroundColor: "#009898"
    },
}));

function LoginModal({ loginModal, setLoginModal, navigate }) {
    const handleNavigate = () => {
        navigate("/login");
        setLoginModal(false);
    };

    const handleClose = () => {
        setLoginModal(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={loginModal}
                onClose={handleClose}
                PaperProps={{
                    className: "login_modal"
                }}
            >
                <DialogTitle style={{ textAlign: 'center', position: 'relative' }}>
                    <img
                        src={FinalLogos}
                        className="object-cover cursor-pointer mb-2 login_popup_logo"
                        style={{ display: 'block', margin: '5px auto' }}
                        alt='FinalLogos'
                        loading="lazy"
                    />
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontFamily:' Greycliff CF',textAlign: 'center',color:'#4c474b'}}>
                    You need to log in first to proceed further.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='button_div' style={{ justifyContent: 'center',marginBottom:"14px" }}>
                    <ColorButton variant="contained" className="login_button buttoncommon" onClick={handleNavigate}>Login</ColorButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default LoginModal;
