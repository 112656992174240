import {  useState } from "react";
import HomeSubscribe from "../Home/homeSubsrice"
import OtpInput from "./otp_input";
import { resendOTP } from "../../../services/login";
import { Grid } from "@mui/material";
import { SpinLoader } from "../../../app/loader/loader";

const OTPValidation = (props) => {
    const [otp, setOTP] = useState("");
    const [resendOtp, setResendOtp] = useState(false);

    const onOtpSubmit = (value) => {
        if (value.length === 4) {
            props.onOtpSubmit(value);
        } else {
            props?.setMsg({ msg: "Please enter a valid OTP!", error: true });
        };
    };

    const resendOTPHandler = () => {
        setTimeout(() => {
            props?.setMsg({ msg: " ", error: false });
        }, 3000);
        const num = props.number.split(" ");
        const obj = {}
        obj['country_code'] = num[0];
        obj['phone_number'] = num[1];
        resendOTP(obj)
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    props?.setMsg({ msg: data.message, error: false });
                };
            })
            .catch((error) => {
                props?.setMsg({ msg: error.receiveObj.message, error: true });
            });
    };

    return (
        <>
            <Grid container className="commentForm mb-5 mt-48 p-5">
                <Grid item xs={1} sm={2} md={3} lg={3} xl={3}></Grid>
                <Grid item xs={10} sm={8} md={6} lg={6} xl={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center text-xl p-2 font-articulat font-normal" style={{ fontSize: '16px' }}>We have share a code of your phone number <br />{props.number} <span className="cursor-pointer text-[#009898] hover:text-[#009800]" onClick={() => props.editNumber()}>Edit</span></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center"><OtpInput length={4} handleSubmit={onOtpSubmit} onChange={(val) => { setOTP(val.join("")); props?.setMsg({ msg: "", error: false }); }} onOtpSubmit={(val) => { setOTP(val); props.callBackMessage() }} resendOtp={resendOtp} />
                            {props.msg?.msg && <span className="text-sm font-normal" style={{ marginTop: "15px", display: "block", color: props?.msg?.error ? "red" : 'green' }}>{props.msg?.msg}</span>}</Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <button
                                style={{ fontSize: "14px" }}
                                className="rounded-lg mt-3 w-full font-articulat text-xs text-white p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] h-10 focus:shadow-none active:opacity-[0.85] active:shadow-none buttoncommon"
                                type="submit" onClick={() => { !props.loader && onOtpSubmit(otp) }}>
                                {props.loader ? <SpinLoader height="20px" width="20px"/> : 'Verify'}
                            </button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center text-[#009898] font-articulat text-xs cursor-pointer hover:text-[#135353]" style={{ fontSize: "14px" }} onClick={() => {resendOTPHandler(); setResendOtp(true)}}>Resend OTP </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={2} md={3} lg={3} xl={3}></Grid>
            </Grid>
            <HomeSubscribe />
        </>
    )
}
export default OTPValidation;