import { CHECK_PAGE_OPEN, UPDATE_FETCH, UPDATE_PRODUCTS } from "../constants";

export const checkPage = (payload) => ({
    type: CHECK_PAGE_OPEN,
    payload: payload
});

export const updateProducts = (payload) => ({
    type: UPDATE_PRODUCTS,
    payload: payload
});

export const updateFetch = (payload) => ({
    type: UPDATE_FETCH,
    payload: payload
});