import { Helmet } from "react-helmet";
export const navigateToNext = (e, url, navigate, subCategory) => {
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
        window.open(window.location.origin + url, '_blank');
    } else {
        subCategory ?  navigate(url,  { state: { subCategory: subCategory } }) : navigate(url);
    };
};
 const ApplicationHelmet  = () => {
    return (
        <>
         <Helmet>
         <title>Grind and pound </title>
                <meta name="description" content="hello grind" />
                <meta property="og:title" content=""/>
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
         </Helmet>
        </>
    )
 }
 
 export default ApplicationHelmet;