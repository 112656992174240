
import React, { useEffect, useRef } from "react";
import Loader from "../components/Loader";
import ScrollToTopButton from "../components/ButtonComm/ScrollToTopButton";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../footer"

const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      window.scrollTo(0, 0);
    } else {
      hasMounted.current = true;
    }
  });


  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };

    setTimeout(handleScroll, 100);
    return () => {
    };
  }, [pathname, search]);

  return null;
};


const Layout = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <div className="main-container">

        <Navbar />
        <Loader />
        <ScrollToTopButton />
        <div className="main-content">
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default React.memo(Layout);