// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    margin: 170px 0px;
}

.heading {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    font-family:Greycliff CF !important;
}

.locationIcon {
    font-size: 20px !important;
    margin-right: 5px;
}

.pin-input {
    width: 100%;
    label {
        font-size: 14px;
    }

    input {
        font-family:Greycliff CF !important;
        font-size: 14px;
    }
}

.chips {
    font-family:Greycliff CF !important;
    width: 120px;
}

.check-zip-codes {
    display: flex !important;
    margin-top: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/view/public/Location/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,0BAA0B;IAC1B,mCAAmC;AACvC;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX;QACI,eAAe;IACnB;;IAEA;QACI,mCAAmC;QACnC,eAAe;IACnB;AACJ;;AAEA;IACI,mCAAmC;IACnC,YAAY;AAChB;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;AAC9B","sourcesContent":[".main {\n    margin: 170px 0px;\n}\n\n.heading {\n    display: flex;\n    align-items: center;\n    font-size: 14px !important;\n    font-family:Greycliff CF !important;\n}\n\n.locationIcon {\n    font-size: 20px !important;\n    margin-right: 5px;\n}\n\n.pin-input {\n    width: 100%;\n    label {\n        font-size: 14px;\n    }\n\n    input {\n        font-family:Greycliff CF !important;\n        font-size: 14px;\n    }\n}\n\n.chips {\n    font-family:Greycliff CF !important;\n    width: 120px;\n}\n\n.check-zip-codes {\n    display: flex !important;\n    margin-top: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
