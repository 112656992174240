import { combineReducers } from '@reduxjs/toolkit';
import cartReducer from './cart/index';
import searchReducer from './search/index';
import subscriptionReducer from './subscription/index';


const rootReducer = combineReducers({
    cartReducer: cartReducer,
    searchReducer: searchReducer,
    subscriptionReducer: subscriptionReducer
});

export default rootReducer;