import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import "./reacttable.css";
import NoDataFound from "../../services/utils/noDataPopUp";
import { Icons } from "../../images/reactIcons";

const ReactTable = ({
  columns,
  data,
  pageCount,
  totalRow,
  handleUpdateCartItem,
  setDeleteId,
  responsivedata,
  setShowModal,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: totalRow,
      },
      pageCount: pageCount,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [pageArr, setPageArr] = useState([]);
  React.useEffect(() => {
    const arr = [];
    for (let i = 1; i <= pageCount; i++) {
      arr.push(i);
    }
    setPageArr(arr);
  }, [pageCount]);
  
  return (
    <div className="overflow-x-auto font-articulat">
      <table
        {...getTableProps()}
        className="table table-compact table-zebra w-full tablemaincontainer"
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => {
                return(
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="border font-poppins text-[#1C1243] p-3"
                  key={column.Header}
                >
                  {column.render("Header")}
                </th>
              )})}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === "QTY") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="border text-center text-sm font-poppins text-[#1C1243]"
                          key={cell.column.id}
                        >
                          <span className="checkouttableaddcard">
                            <button
                              disabled={row?.values?.quantity === 1}
                              onClick={() =>
                                handleUpdateCartItem(
                                  row?.original?.texture_id,
                                  row?.original?.variant_id,
                                  row?.values?.quantity - 1
                                )
                              }
                              className="text-2xl"
                            >
                          -
                            </button>
                            <span className="lg:p-2.5 md:p-[7px]">
                              {cell.render("Cell")}
                            </span>
                            <button
                              onClick={() =>
                                handleUpdateCartItem(
                                  row?.original?.texture_id,
                                  row?.original?.variant_id,
                                  row?.values?.quantity + 1
                                )
                              }
                              className="text-xl"
                            >
                              +
                            </button>
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="border text-center text-sm font-poppins text-[#1C1243]"
                          key={cell.column.id}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr className="hover" key="no-data">
              <td colSpan={columns.length} className="text-center font-poppins">
                <NoDataFound />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="checkoutbox">
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2 }}
          className="checkoutboxres"
        >
          {responsivedata?.map((ele, index) => {
            const eleTexture = ele?.textures?.length > 0 && ele?.textures.filter((t) => t.id === ele?.texture_id);
           const product = ele?.variants?.length > 0 && ele.variants.filter((v) => v.id === ele.variant_id);
            return(
            <div
              key={index}
              className="checkoutboxressub"
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #FBF5F1",
                padding: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img
                    src={ele?.attachment_url[0]}
                    className="object-cover h-15"
                    style={{ width: "74px", height: "81px" }}
                    alt={ele.name}
                    loading="lazy"
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography className="commontypography">
                  {ele.name} {eleTexture?.length > 0 && `${(eleTexture[0]?.name)}` }
                  </Typography>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography className="commontypography">
                      Net wt. :{" "}
                      <span className="commontypographyamount">
                        {product.length > 0 ? (product[0]?.net_wt + product[0]?.unit ) : ele?.net_wt + ele?.unit}
                      </span>
                    </Typography>
                    <Grid item>
                      <div className="text-center" style={{textAlign:"-webkit-center"}}>
                        <Icons.FaTrash
                          className="text-[#FF0000] cursor-pointer"
                          aria-hidden="true"
                          onClick={() => {
                            setShowModal(true);
                            setDeleteId(ele);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="increasebox">
                    <Grid item xs={4} className="commontypographyamount">
                      ₹<span>{product.length > 0 ? ((product[0]?.discount_price !== null && parseFloat(product[0]?.discount_price) !== 0) ? product[0]?.discount_price : product[0]?.price) : (ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price}</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span className="checkoutincreasebox">
                        <button
                          disabled={ele.quantity === 1}
                          onClick={() =>
                            handleUpdateCartItem(ele?.texture_id, ele.variant_id, ele.quantity - 1)
                          }
                          className="text-xl"
                        >
                       -
                        </button>
                        <span
                          style={{ padding: "10px" }}
                          className="commontypographyamount"
                        >
                          {ele?.quantity}
                        </span>
                        <button
                          onClick={() =>
                            handleUpdateCartItem(ele?.texture_id, ele.variant_id, ele.quantity + 1)
                          }
                        >
                          +
                        </button>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="commontypographyamount">
                      ₹<span>{product.length > 0 ? ((product[0]?.discount_price !== null && parseFloat(product[0]?.discount_price) !== 0) ? (product[0]?.discount_price * ele?.quantity) : (product[0]?.price * ele?.quantity)) : ((ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? (ele.cost_price * ele?.quantity) : ele.master_price * ele?.quantity)}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )})}
        </Grid>
      </div>
    </div>
  );
};

export default ReactTable;
