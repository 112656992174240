import React, { useEffect, useState } from "react";
import { fetchGetApi } from "../../../services/apis.functions/api.functions";

const TermsPopup = ({ isModalOpen, toggleModal }) => {
  const [termsOfSale, setTermsOfSale] = useState([]);
  const [privacyPolicies, setPrivacyPolicies] = useState([]);

  useEffect(() => {
    fetchGetApi('/terms_of_uses', {})
    .then(res => {
        if (res?.data?.status === "Success") {
          setTermsOfSale(res?.data?.data);
        };
    })
    .catch(error => {
        return error;
    })
  }, []);

  useEffect(() => {
    fetchGetApi('/privacy_policies', {})
      .then(res => {
        if (res?.data?.status === "Success") {
          setPrivacyPolicies(res?.data?.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const renderContent = () => {
    const contentList = isModalOpen?.termndprivacylink === "terms-of-use" ? termsOfSale : privacyPolicies;
    
    if (!contentList || contentList.length === 0) {
      return <p>Data not found</p>;
    }
  
    return contentList.map((content, index) => (
      <div key={index}>
        <p className="text-xs termscontent" dangerouslySetInnerHTML={{ __html: content?.content }} />
      </div>
    ));
  };

  return (
    <>
      {isModalOpen?.termsmodal && (
        <div
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {isModalOpen?.termndprivacylink === "terms-of-use" ? "Terms and Conditions" : "Privacy Policy"}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4 h-72 overflow-y-scroll">
                {renderContent()}
              </div>
              <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={toggleModal}
                  type="button"
                  style={{color:'#FBF5F1'}}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 buttoncommon"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsPopup;
