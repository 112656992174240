import { useEffect, useState } from "react";
import { checkAuth } from "../../../app/checkAuth";
import AccountInfo from "./accountInfo";
import Address from "./address";
import MyOrder from "./myOrder";
import Subscription from "./subscription";
import Vouchers from "./vouchers";
import { getProfileList } from "../../../services/profile";
import { notificationService } from "../../../services/notifications/notificationService";
import { Icons } from "../../../images/reactIcons";

const ProfileAccount = () => {
    let getStatus = localStorage.getItem('setCheckStatus');
    const [tab, setTab] = useState(getStatus ? 1 : 0);
    const [checkStatus, setCheckStatus] = useState(false);
    const [profile, setProfile] = useState('');

    const currentComponent = (tab) => {
        switch (tab) {
            case 0: return <AccountInfo profile={profile} getProfile={getProfile} />;
            case 1: return <MyOrder setCheckStatus={setCheckStatus}/>;
            case 2: return <Address />;
            case 3: return <Vouchers />;
            case 4: return <Subscription />;
            default: return <div>Invalid tab</div>; 
        }
    };

    const getProfile = () => {
        getProfileList()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    setProfile(data);
                };
            })
            .catch((error) => {
                notificationService.sendMessage({ type: "error", title: "Profile Data", text: error.receiveObj.message })
                localStorage.removeItem('token');
                localStorage.removeItem("userId");
                localStorage.removeItem("selectedAddress");
                setTimeout(() => {
                    window.location.replace(window.location.origin + '/login');
                }, 1000)
            });
    };

    const redirectPage = (url) => {
        window.location.replace(window.location.origin + url);
    };

    const logoutHandler = () => {
        setTimeout(() => {
            localStorage.clear();
            redirectPage('/login');
        }, 1000);
    };

    useEffect(() => {
        checkAuth();
        getProfile();
        localStorage.removeItem('setCheckStatus');
    }, []);


    return (
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-5 mt-28">
            <div className="lg:col-span-2 md:col-span-1 sm:col-span-0"></div>
            <div className="lg:col-span-8 md:col-span-10 sm:col-span-12">
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 flex justify-between">
                        <div className="text-lg font-articulat font-semibold">Profile</div>
                        <div className="text-xs font-articulat font-semibold text-right">
                            <button className="bg-[#FF0000] p-2 flex text-white rounded-lg hover:shadow-lg hover:border-blue-200 focus:border-blue-400" onClick={() => logoutHandler()}><Icons.MdOutlineLogout className="h-4 w-4"/> Logout</button>
                        </div>
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
                        {profile && <span className="text-sm font-articulat font-semibold">{profile.title + " " + profile.first_name + " " + (profile.last_name !== undefined ? profile.last_name : "")}</span>}
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold mt-3"><hr /></div>
                    <ul className="profile-tabing -mb-px lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm font-articulat font-semibold text-center cursor-pointer mt-2 ">
                        <li className="me-5" onClick={() => { setTab(0) }}>
                            <span className={tab === 0 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-[#009898] dark:border-[#009898]" :
                                "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898] hover:border-gray-300 dark:hover:text-gray-300"} aria-current="page">Account info</span>
                        </li>
                        <li className="me-5" onClick={() => { setTab(1) }}>
                            <span className={tab === 1 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-[#009898] dark:border-[#009898]" :
                                "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898] hover:border-gray-300 dark:hover:text-gray-300"} aria-current="page">My order</span>
                        </li>
                        <li className="me-5" onClick={() => { setTab(2) }}>
                            <span className={tab === 2 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-[#009898] dark:border-[#009898]" :
                                "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898] hover:border-gray-300 dark:hover:text-gray-300"} aria-current="page">Address</span>
                        </li>
                        <li className="me-5" onClick={() => { setTab(3) }}>
                            <span className={tab === 3 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-[#009898] dark:border-[#009898]" :
                                "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898] hover:border-gray-300 dark:hover:text-gray-300"} aria-current="page">Vouchers</span>
                        </li>
                    </ul>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold"><hr /></div>
                    {currentComponent(tab)}</div>
            </div>
            <div className="lg:col-span-2 md:col-span-1 sm:col-span-0"></div>
        </div>
    )
};

export default ProfileAccount;