import { ADD_SUBSCRIPTION, REMOVE_SUBSCRIPTION } from "../constants";


const initialState = {
    subscription: null
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload
            };
        case REMOVE_SUBSCRIPTION:
            return {
                ...state,
                subscription: null
            };
        default:
            return state;
    }
};

export default subscriptionReducer;