// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 640px) {
  .deletebuttondiv{
    display: flex;
  }
  .cancelbutton{
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/view/private/ProfileAccount/deleteconfirm.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["@media only screen and (max-width: 640px) {\n  .deletebuttondiv{\n    display: flex;\n  }\n  .cancelbutton{\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
