// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

.custom-loader-container {
    position: relative;
    /* Change position to relative */
    width: -moz-fit-content;
    width: fit-content;
    /* Set width to fit the content */
    height: -moz-fit-content;
    height: fit-content;
    /* Set height to fit the content */
    margin: auto;
    /* Center the loader */
}

.circle {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #009898;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
    /* Center the loader */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/app/loader/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,uBAAkB;IAAlB,kBAAkB;IAClB,iCAAiC;IACjC,wBAAmB;IAAnB,mBAAmB;IACnB,kCAAkC;IAClC,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,kCAAkC;IAClC,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgb(255, 255, 255);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.loader {\n    border: 6px solid #f3f3f3;\n    border-top: 6px solid #3498db;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n    margin-right: 10px;\n}\n\n.custom-loader-container {\n    position: relative;\n    /* Change position to relative */\n    width: fit-content;\n    /* Set width to fit the content */\n    height: fit-content;\n    /* Set height to fit the content */\n    margin: auto;\n    /* Center the loader */\n}\n\n.circle {\n    border: 4px solid #f3f3f3;\n    border-top: 4px solid #009898;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    transform: translate(-50%, -50%);\n    /* Center the loader */\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
