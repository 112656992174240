export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const ADD_ALL_ITEMS_TO_CART = 'ADD_ALL_ITEMS_TO_CART';
export const RESET_CART_ITEMS = 'RESET_CART_ITEMS';
export const CHECK_PAGE_OPEN = 'CHECK_PAGE_OPEN';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_FETCH = 'UPDATE_FETCH';

// Subscriptions
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';
export const CLEAR_CART = 'CLEAR_CART';