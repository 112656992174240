import CommingSoonMainPage from "../../../components/greatethings/commingsoonpage";
import ApplicationHelmet from "../../../navigation/navigation";

const PageNotFound = () => {
    return (
        <> 
            <ApplicationHelmet/>
            <CommingSoonMainPage/>
        </>
    );
};

export default PageNotFound;