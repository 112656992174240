import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedShippingRoutes = ({ element: Component }) => {
    let cartDetails = 0;
    const persistRoot = localStorage.getItem('persist:root');

    if (persistRoot) {
        try {
            const parsedPersistRoot = JSON.parse(persistRoot);
            const persistedCart = JSON.parse(parsedPersistRoot.cartReducer);
            cartDetails = persistedCart?.cart?.length || 0;
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    return cartDetails > 0 ? <Component /> : <Navigate to="/home" />;
};

export default ProtectedShippingRoutes;
