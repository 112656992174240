// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width:639px) {
    .leave-div {
        display: flex;
        justify-content: space-between;
    }

    .leave-btns {
        display: flex;
        justify-content: space-between;

        button {
            width: 48%;
        }
        margin: 15px !important
    }

    .leave-list{
        display: flex;
    }
}`, "",{"version":3,"sources":["webpack://./src/view/private/ProfileAccount/leavereviews.css"],"names":[],"mappings":";AACA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,aAAa;QACb,8BAA8B;;QAE9B;YACI,UAAU;QACd;QAIA;IAHJ;;IAMA;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n@media screen and (max-width:639px) {\n    .leave-div {\n        display: flex;\n        justify-content: space-between;\n    }\n\n    .leave-btns {\n        display: flex;\n        justify-content: space-between;\n\n        button {\n            width: 48%;\n        }\n    }\n\n    .leave-btns{\n        margin: 15px !important;\n    }\n\n    .leave-list{\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
