// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dispatch-header-title {
  display: flex;
  align-items: center;
}

.dispatchanddelivery {
  font-family:Greycliff CF !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #000;
  font-weight: bold !important;
}

.dispatchdiscription {
  p {
    padding: 10px;
    font-size: 14px;
  }

  h1 {
    padding: 8px;
    font-size: 20px;
  }

  h2 {
    padding: 8px;
    font-size: 18px;
  }

  ol {
    margin-left: 0;
    margin-right: 0;
    padding-left: 22px;
  }
  ul {
    margin-left: 0;
    margin-right: 0;
    padding-left: 22px;
  }

  ol li {
    margin-bottom: 8px;
  }
  ul li {
    margin-bottom: 8px;
  }

}

@media screen and (max-width: 900px) {
  .dispatchanddelivery {
    margin-top: 12px;
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .dispatchanddelivery {
    font-size: 18px;
    line-height: 40px;
  }
}
@media screen and (max-width: 639px) {
 .dispatchcontainer{
  width: 100% !important;
  max-width: 100%;
 }
}`, "",{"version":3,"sources":["webpack://./src/view/public/DispatchDelivery/dispatchdelivery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;;AAEF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;AACA;CACC;EACC,sBAAsB;EACtB,eAAe;CAChB;AACD","sourcesContent":[".dispatch-header-title {\n  display: flex;\n  align-items: center;\n}\n\n.dispatchanddelivery {\n  font-family:Greycliff CF !important;\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: 0px;\n  text-align: center;\n  color: #000;\n  font-weight: bold !important;\n}\n\n.dispatchdiscription {\n  p {\n    padding: 10px;\n    font-size: 14px;\n  }\n\n  h1 {\n    padding: 8px;\n    font-size: 20px;\n  }\n\n  h2 {\n    padding: 8px;\n    font-size: 18px;\n  }\n\n  ol {\n    margin-left: 0;\n    margin-right: 0;\n    padding-left: 22px;\n  }\n  ul {\n    margin-left: 0;\n    margin-right: 0;\n    padding-left: 22px;\n  }\n\n  ol li {\n    margin-bottom: 8px;\n  }\n  ul li {\n    margin-bottom: 8px;\n  }\n\n}\n\n@media screen and (max-width: 900px) {\n  .dispatchanddelivery {\n    margin-top: 12px;\n    font-size: 20px;\n  }\n}\n\n@media screen and (max-width: 650px) {\n  .dispatchanddelivery {\n    font-size: 18px;\n    line-height: 40px;\n  }\n}\n@media screen and (max-width: 639px) {\n .dispatchcontainer{\n  width: 100% !important;\n  max-width: 100%;\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
