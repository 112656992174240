import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isMobile, useMobileOrientation } from 'react-device-detect';
import './productcarousel.css'

const ProductBaneer = ({ data }) => {
    const [slide, setSlide] = useState(0);
    const location = useLocation();
    const { isLandscape } = useMobileOrientation();
    const images = (isMobile && !isLandscape) ? data?.category_mobile_images : data?.category_images;

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide(prevSlide => prevSlide === images.length - 1 ? 0 : prevSlide + 1);
        }, 2000);

        // const queryParams = new URLSearchParams(location.search);
        // const id = queryParams.get('id');

        setSlide(0);

        return () => clearInterval(interval);
    }, [images.length, location.search]);

    return (
        <div className={`${(isMobile && !isLandscape) ? "product-custm-carousel-mobile" : "product-custm-carousel"}`}>
            {images.map((item, idx) => (
                <div key={idx} className={`${slide === idx ? "slides" : "slides slides-hidden"} `}>
                    <img
                        src={item?.image_url}
                        alt={item?.image_url}
                        key={idx}
                        loading="lazy"
                        className={`object-cover w-full h-full`}
                    />
                </div>
            ))}
            <span className="indicators">
                {images.map((_, idx) => (
                    <button
                        key={"circle" + idx}
                        className={`slidebutton rounded-lg m-1 w-12 h-1 cursor-pointer ${idx === slide ? "active" : ""}`}
                        onClick={() => setSlide(idx)}
                        aria-label={`Slide ${idx + 1}`}
                    >
                    </button>
                ))}
            </span>
        </div>
    );
};


export default ProductBaneer;