import React, { useEffect, useState } from "react";
import HomeSubscribe from "./homeSubsrice";
import CarouselBaneer from "../../../components/carousel";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from 'react-redux';
import { getFilterProduct, getBaneerImg, getProductBanner } from "../../../services/index";
import { notificationService } from '../../../services/notifications/notificationService';
import Popup from "../../private/Product/popup";
import { Box, Divider, Grid } from "@mui/material";
import { addAllItemsToCart, addToCart, removeFromCart } from "../../../reducers/cart/actions";
import { addItemsToCart, deleteCartItem, fetchCartProducts } from "../../../services/checkout";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { SpinLoader } from "../../../app/loader/loader";
import images from "../../../images/images";
import './home.css';
import { recntBlogList } from "../../../services/footer";
import { DateFormatter } from "../../../components/Shared/date_formatter";
import ApplicationHelmet from "../../../navigation/navigation";


export const fetchCartList = (dispatch) => {
    fetchCartProducts()
        .then((response) => {
            if (response?.cart_items) {
                let data = response?.cart_items.map(val => { return { ...val?.product, quantity: val?.quantity, variant_id: val?.variant?.id, texture_id: (val?.texture_id !== null && val?.texture_id !== undefined) ? val?.texture_id : null } });
                dispatch(addAllItemsToCart(data));
            } else {
                if (response?.response?.data?.error === 'Invalid or expired token') {
                    localStorage.removeItem('token');
                    localStorage.removeItem("userId");
                    localStorage.removeItem("selectedAddress");
                }
            }
        })
        .catch((error) => {
            return error;
        });
};

const Home = () => {
    const [productCategory, setProductCategory] = useState([]);
    const [productList, setProductList] = useState([]);
    const [bannerList, setBannerList] = useState([]);
    const [promotionList, setPromotionList] = useState([]);
    const [advertiseList, setAdvertiseList] = useState([]);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const userId = localStorage.getItem('userId');
    const [auth, setAuth] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartStore = useSelector(state => state.cartReducer.cart);
    const [tab, setTab] = useState(1);
    const [addProduct, setAddProduct] = useState(null);
    const [blog, setBlog] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [clickedImageId, setClickedImageId] = useState(null);

    const { state } = useLocation();
    const getBlog = () => {
        recntBlogList()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj.data;
                    setBlog(data)
                }
            })
            .catch((error) => {
                setBlog([]);
                notificationService.sendMessage({ type: "error", title: "Blog List", text: error.receiveObj.message })
            });
    }

    const updateCartList = () => {
        fetchCartProducts()
            .then(async (response) => {
                if (response?.cart_items.length > 0) {
                    let data = response?.cart_items.map(val => {
                        return { ...val?.product, quantity: val?.quantity, variant_id: val?.variant?.id, texture_id: (val?.texture_id !== null && val?.texture_id !== undefined) ?  val?.texture_id : null }
                    });
                    if (state && cartStore?.length !== 0) {
                        await Promise.all(cartStore?.map(async (obj) => {
                            addItemsToCart({ "product_id": obj.id, "quantity": obj.quantity, "variant_id": obj.variant_id, "texture_id": (obj?.texture_id !== null && obj?.texture_id !== undefined) ? obj?.texture_id : null });
                        }));

                        let newArray = cartStore?.concat(data);
                        const uniqueArray = newArray.filter((item, index, self) => {
                            const firstIndex = self.findIndex((t) => {
                                return t.id === item.id && t.variant_id === item.variant_id && (item.texture_id !== null && item.texture_id !== undefined  ? t.texture_id === item.texture_id : true);
                            });
                            return index === firstIndex;
                        });

                        dispatch(addAllItemsToCart(uniqueArray));
                    } else {
                        dispatch(addAllItemsToCart(data));
                    }
                } else {
                    if (cartStore?.length !== 0) {
                        await Promise.all(cartStore?.map(async (obj) => {
                            await addItemsToCart({ "product_id": obj.id, "quantity": obj.quantity, "variant_id": obj.variant_id, "texture_id": obj.texture_id !== null && obj.texture_id !== undefined  ? obj?.texture_id : null });
                        }));
                    }
                    const uniqueArray = cartStore.filter((item, index, self) => {
                        const firstIndex = self.findIndex((t) => {
                            return t.id === item.id && t.variant_id === item.variant_id && (item.texture_id != null && item.texture_id !== undefined  ? t.texture_id === item.texture_id : true);
                        });
                        return index === firstIndex;
                    });

                    dispatch(addAllItemsToCart(uniqueArray));
                    if (response?.response?.data?.error === 'Invalid or expired token') {
                        setAuth(false);
                        localStorage.removeItem('token');
                        localStorage.removeItem("userId");
                        localStorage.removeItem("selectedAddress");
                        setToken(null);
                    }
                }
            })
            .catch((error) => {
                return error;
            });
    };

    const getProductCategory = (category) => {
        const payload = {
            keyword: "",
            subcategory_id: category
        };

        getFilterProduct(payload)
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj.data;
                    setProductCategory(data);
                };
            })
            .catch((error) => {
                setProductCategory([])
                notificationService.sendMessage({ type: "error", title: "Products List", text: error.receiveObj.message })
            });
    };
    const getProductList = (category) => {
        const payload = {
            "position": `${category}`,
            "subcategory_id": "",
        }
        getProductBanner(payload)
            .then((response) => {
                setLoading(false);
                if (response.success && response.receiveObj) {
                    const data = response?.receiveObj?.data;
                    setProductList(data);
                }
            })
            .catch((error) => {
                setProductList([]);
                notificationService.sendMessage({
                    type: "error",
                    title: "Banner List",
                    text: error.receiveObj.message,
                });
            });
    };
    const getBannerList = () => {
        getBaneerImg()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj.banners;
                    const banner = data.filter(ele => ele.banner_type === 'banner');
                    const advertise = data.filter(ele => ele.banner_type === 'advertise');
                    const promotion = data.filter(ele => ele.banner_type === 'promotion');
                    setAdvertiseList(advertise);
                    setPromotionList(promotion);
                    setBannerList(banner);
                }
            })
            .catch((error) => {
                setBannerList([])
                setAdvertiseList([]);
                setPromotionList([]);
                notificationService?.sendMessage({ type: "error", title: "Banner List", text: error.receiveObj.message })
            });
    };

    const handleAddToCart = (product) => {
        const { variant_id, texture_id} = product;
        const productInCart = cartStore?.find(item => item.id === product.id && item.variant_id === product.variant_id && ((product.texture_id !== null && product.texture_id !== undefined) ? item.texture_id === product.texture_id : true));
        if (productInCart) {
            if (auth) {
                deleteCartItem({productId: userId, variantId: variant_id, texture_id: texture_id})
                    .then(() => { fetchCartList(dispatch) });
            }
            dispatch(removeFromCart(product.id, product.variant_id, product?.texture_id));
        } else {
            if (auth) {
                addItemsToCart({ "product_id": product.id, "quantity": 1, variant_id: product.variant_id, texture_id: product?.texture_id })
                    .then(() => { fetchCartList(dispatch) });
            }
            dispatch(addToCart({...product, quantity: 1}));
            setAddProduct({ ...product, quantity: 1, variant_id: product.variant_id, texture_id: product?.texture_id });
            setTimeout(() => { setAddProduct(null) }, 2000);
        };
    };

    useEffect(() => {
        localStorage.getItem('token') && updateCartList();
    }, [])

    useEffect(() => {
        setLoading(true);
        getBannerList();
        getProductCategory();
        getBlog();
        getProductList(1)
    }, []);

    const handleImageClick = (id) => {
        setClickedImageId(id);
        navigate('/blogdetails?id=' + id);
    };
    const handleNavigate = (e) => {
        e.preventDefault();
        const url = '/ourblogs';
        if (e.ctrlKey || e.metaKey) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
    };

    useEffect(() => {
        if (token) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, [token]);

    return (
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 home-container">
            <ApplicationHelmet/>
            {addProduct && <Popup product={addProduct} />}
            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                <CarouselBaneer data={bannerList} />
            </div>
            <div className="categories-large lg:col-span-12 md:col-span-12 sm:col-span-12">
                <div className="cat-title lg:col-span-12 md:col-span-12 sm:col-span-12 mt-12 text-center text-2xl p-2 font-articulat font-semibold">
                    Shop By
                </div>
                <div className="flex justify-center lg:col-span-12 md:col-span-12 sm:col-span-12">
                    <ul className="flex">
                        <li className="text-center cursor-pointer" style={{ padding: '0px 15px' }} onClick={() => { getProductList(1); setTab(1) }}>
                            <span className={tab === 1 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-blue-500 dark:border-blue-500" :
                                "inline-block p-1 rounded-t-lg hover:text-[#009898] hover:border-[#009898] dark:hover:text-[#009898] font-articulat"} aria-current="page">
                                Shop All
                            </span>
                        </li>
                        {productCategory?.categories
                            ?.filter(ele => ele.name !== "Shop All")
                            ?.sort((a, b) => a.position - b.position)
                            .map((ele) => (
                                <li key={ele.id} className="text-center cursor-pointer" style={{ padding: '0px 15px' }} onClick={() => { getProductList(ele.position); setTab(ele.position + 1) }}>
                                    <span className={tab === ele.position + 1 ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active dark:text-blue-500 dark:border-blue-500" :
                                        "inline-block p-1 rounded-t-lg hover:text-[#009898] hover:border-[#009898] dark:hover:text-[#009898] font-articulat"} aria-current="page">
                                        {ele.name}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 productdetailsyoumaybox">  
                <Grid container spacing={2} className='p-5'>
                    {productList?.products?.map((ele) => {
                        let cartShow = cartStore?.find(item => item.id === ele.id);
                        let prodId = ele.id;
                        const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;
                        if (prodId === 42 && userId != 184) {
                            return null;
                        }
                        return (
                            <Grid key={ele.id} item xs={6} sm={6} md={4} lg={3} xl={3}>
                                <Box>
                                    <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 m-3">
                                        {/* <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 rounded-xl inset-0 flex"> */}
                                            <Link to={productUrl}>
                                                <img
                                                    src={ele.attachment_url[0]}
                                                    alt="homeProductImage"
                                                    loading="lazy"
                                                    className="2xl:w-full 2xl:h-full sm:w-[320px] sm:h-[250px] m-auto rounded-lg cursor-pointer object-cover"
                                                />
                                            </Link>
                                        {/* </div> */}
                                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal mt-2 h-10 hover:text-[#268126]">
                                            <Link to={productUrl}>
                                                {ele.name}
                                            </Link>
                                        </div>
                                        <Grid container spacing={2} className="product-content bg-white" style={{ marginTop: "-10px" }}>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="product-content1">
                            
                                                    <span className="text-[#333333] text-xs font-articulat font-normal">Net wt. : </span>
                                                    <span className="text-xs font-articulat font-semibold">{ele.net_wt + ele.unit}</span>
                                
                                            </Grid>
                                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} className="home-divd">
                                                <Divider orientation="vertical" />
                                            </Grid>
                                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7} className="product-content2">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={8} sm={8} md={9} lg={9} xl={9} className="text-right">
                                                        <span className="text-[#667479] text-xs font-articulat font-normal">MRP : </span>
                                                        <span className="text-xs font-articulat font-semibold">₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price}</span>
                                                        {ele.master_price && <span className="text-[#333333] text-xs font-articulat font-light"> {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? <span className="line-through"> {"₹" + ele.master_price}</span> : ''}</span>}
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="text-right mr-10 productaddcardbut">
                                                        <span className="cart-icn" style={{ textAlign: '-webkit-right' }}>
                                                            <img
                                                                className="cursor-pointer w-7 h-7"
                                                                alt="cartShowImage"
                                                                loading="lazy"
                                                                src={cartShow ? images.cartActive : images.cartInactive}
                                                                onClick={() => handleAddToCart(ele)}
                                                            />
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Box>
                            </Grid>
                        );
                    })}
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-9">
                        <hr />
                    </div>
                </Grid>
            </div>

            <div className="productdetailsyoumaybox-1 p-4">
                {productList?.products?.map((ele) => {
                    let prodId = ele.id;
                    let cartShow = cartStore?.find(item => item.id === ele.id);
                    const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;

                    if (prodId === 42 && userId != 184) return null;

                    return (
                        <div className="" key={ele.id}>
                            <div className="productdetailsyoumayboxsubbox">
                                <Link to={productUrl}>
                                    <img
                                        alt="productImageHome"
                                        loading="lazy"
                                        src={ele.attachment_url[0]}
                                        className="w-full m-auto rounded-lg cursor-pointer product-res-img"
                                    />
                                </Link>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal sm:h-10 xxs:h-[66px] mt-2 cursor-pointer responsivexs hover:text-[#268126]">
                                    <Link to={productUrl}>{ele.name}</Link>
                                </div>
                                <div className="productdetailsyoumayboxpricebox bg-white">
                                    <div>
                                        <div>
                                            <span className="text-[#999999] text-xs font-articulat font-normal responsivexstext">
                                                Net wt. :
                                            </span>
                                            <span className="text-xs font-articulat font-semibold responsivexstext">
                                                {ele.net_wt + ele.unit}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="text-[#667479] text-xs font-articulat font-normal">
                                                MRP :
                                            </span>
                                            <span className="text-xs font-articulat font-semibold">
                                                ₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price}
                                            </span>
                                            <span className="text-[#333333] text-xs font-articulat font-light line-through">
                                                {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? "₹" + ele.master_price : ''}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <Tooltip
                                            anchorSelect=".cart"
                                            place="left"
                                            style={{ backgroundColor: "#FBF5F1", color: "#222" }}
                                        >
                                            Add to cart
                                        </Tooltip>
                                        <div className="text-right lg:col-span-3 md:col-span-3 sm:col-span-3">
                                            <span style={{ textAlign: '-webkit-right' }}>
                                                <img className="cursor-pointer img-size" alt="addtocartImage" loading="lazy" src={cartShow ? images.cartActive : images.cartInactive} onClick={() =>
                                                    handleAddToCart(ele)
                                                } />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>





            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4 text-center text-2xl p-2 font-articulat font-semibold ">We are solving the biggest problem – Our Daily Food</div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-6">
                <Grid container spacing={0} className="bg-black text-white divide-x">
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid container spacing={0} className="p-10 mi-card3">
                            <Grid item xs={3} sm={2} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline" }}><img src={images.flours} alt="floursImage" loading="lazy" className="object-cover mt-1 cursor-pointer" /></Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} >
                                <div className="text-lg font-articulat font-semibold">Made To Order</div>
                                <div className="font-articulat" style={{ fontSize: '14px', fontWeight: '400', marginTop: '8px' }}>We do not stock real foods. We prepare your real foods after your order is received. This ensures 100% freshness and eliminates the need to use preservatives.</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="divd-con">
                        <div className="divd-line"></div>
                    </div>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid container spacing={0} className="p-10 mi-card1">
                            <Grid item xs={3} sm={2} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline" }}><img src={images.milled} alt="milledImage" loading="lazy" className="object-cover mt-1 cursor-pointer" /></Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} >
                                <div className="text-lg font-articulat font-semibold">Traditionally Prepared</div>
                                <div className="font-articulat" style={{ fontSize: '14px', fontWeight: '400', marginTop: '8px' }}> We use ancient wisdom of slow, cold pressing and traditional methods for preparation of real foods. This ensures that your food retains all its nutrients i.e. minerals, vitamins, antioxidants and fibre, taste and aroma present naturally</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="divd-con">
                        <div className="divd-line"></div>
                    </div>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid container spacing={0} className="p-10 mi-card2">
                            <Grid item xs={3} sm={2} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline" }}><img src={images.packing} alt="packingImage" loading="lazy" className="object-cover mt-1 cursor-pointer" /></Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} >
                                <div className="text-lg font-articulat font-semibold">Real Foods</div>
                                <div className="font-articulat" style={{ fontSize: '14px', fontWeight: '400', marginTop: '8px' }}>We bring pure, natural and whole foods to your table. Your foods are not industrially processed or refined. There is absolutely nothing mixed, stale or added. We serve just honest, real foods – the way mother earth always intended our food to be.</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="divd-con">
                        <div className="divd-line"></div>
                    </div>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid container spacing={0} className="p-10 mi-card3">
                            <Grid item xs={3} sm={2} md={3} lg={3} xl={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline" }}><img src={images.flours} loading="lazy" alt="floursImage" className="object-cover mt-1 cursor-pointer" /></Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} >
                                <div className="text-lg font-articulat font-semibold">Eco - Friendly Packaging</div>
                                <div className="font-articulat" style={{ fontSize: '14px', fontWeight: '400', marginTop: '8px' }}>We pack and deliver your real foods in biocompostable bags which fully convert into organic matter providing nutrients to the mother earth</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-12 text-center text-2xl p-2 font-articulat font-semibold ">Stop eating food like products. Start eating real foods</div>
                {promotionList?.map(ele => {
                    return (
                        <div key={ele?.id} className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-12" style={{ display: "flex", position: "relative", textAlign: "center", height: "521px" }}>
                            <img src={ele.image_url} loading="lazy" alt="prmotionListImage" className="w-full cursor-pointer" />
                            <div className="absolute inset-0 flex flex-col justify-center items-center text-center" style={{ padding: '0px 20px' }}>
                                <span className="text-xl font-articulat" style={{ color: ele.text_color, width: "60%" }}>
                                    {ele.title}
                                </span>
                            </div>
                        </div>
                    );
                })}

                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-12 text-center text-2xl p-2 font-articulat font-semibold">Recent Blogs</div>

                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 mt-2">
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 mt-5">
                            {blog && blog.map(ele => (
                                <div key={ele?.id} className="lg:col-span-4 md:col-span-6 sm:col-span-6 m-2 rounded-xl homeResponsiveBlogs">
                                    <img
                                        src={ele?.image?.url}
                                        alt="blogImage"
                                        loading="lazy"
                                        className={`h-full w-full cursor-pointer ourblogimgdiv ${clickedImageId === ele.id ? 'clicked' : ''}`}
                                        onClick={() => handleImageClick(ele.id)}
                                    />
                                    <div className="text-sm p-2 font-articulat font-semibold cursor-pointer">
                                        {ele.title}
                                    </div>
                                    <div className="text-xs ml-2 font-articulat font-normal text-gray-500 cursor-pointer">
                                        {DateFormatter(ele.created_at, 'LL')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button className="bg-[#163f16] w-48 h-12 text-[#FFFFFF] rounded-2xl" onClick={handleNavigate}>See All</button>
                </div>
                <HomeSubscribe />
            </div>
        </div>
    );
}

export default Home;