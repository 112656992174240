import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Chip, Grid, Container, Stack } from '@mui/material';
import "./style.css";
import { availablePinCodes } from '../../../services/orders';
import { Icons } from '../../../images/reactIcons';
import ApplicationHelmet from '../../../navigation/navigation';


const Location = () => {
    const [message, setMessage] = useState('');
    const [pinCode, setPinCode] = useState("");
    const [pinCodeList, setPinCodeList] = useState([]);


    const checkPinHandler = () => {
        const regex = /[^0-9]/g;
        if (pinCode?.length !== 6 || regex?.test(pinCode)) {
            setMessage({ error: true, msg: 'Please enter a valid 6-digit PIN code number!' });
            setPinCode("");
        } else {
            setMessage(null);
            const payload = { "pin_code": pinCode };

            availablePinCodes(payload)
                .then((response) => {
                    if (response.status) {
                        setMessage({ error: false, msg: "Delivery available!" });
                        setPinCode("");
                    } else {
                        setMessage({ error: true, msg: "We apologize, but we do not offer service in your area!" });
                        setPinCode("");
                    };
                })
                .catch((error) => {
                    if (!error?.status) {
                        setMessage({ error: true, msg: "We apologize, but we do not offer service in your area!" });
                        setPinCode("");
                    } else {
                        setMessage({ error: true, msg: "Something went wrong. Please try again!" });
                        setPinCode("");
                    };
                });
        }
    };

    const fetchPicodes = () => {
        availablePinCodes({})
            .then((response) => {
                if (response.zip_codes) {
                    setPinCodeList(response.zip_codes);
                };
            })
            .catch((error) => {
                return error;
            });
    };

    useEffect(() => {
        fetchPicodes();
    }, []);

    return (
        <Container className='main' maxWidth={false}>
            <ApplicationHelmet/>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" className='heading gap-1'>
                    <Icons.IoLocationSharp />
                          Check pin code availability
                    </Typography>
                    <Grid container spacing={2} className='check-zip-codes'>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <TextField
                                onChange={(e) => {
                                    setPinCode(e.target.value);
                                    setMessage(null);
                                    // setZipAvailability({ check: false, available: false });
                                }}
                                value={pinCode}
                                type="text"
                                id="pin"
                                pattern="[0-9]*"
                                maxLength="6"
                                inputProps={{ pattern: '[0-9]*', maxLength: 6 }}
                                error={false}
                                // helperText="Pin Code is Required."
                                placeholder="Enter Pin Code"
                                label=""
                                className='pin-input'
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Button onClick={checkPinHandler} variant="contained" size='small' style={{ padding: '7px 25px', fontFamily:'Greycliff CF' }} className='buttoncommon'>Check</Button>
                        </Grid>
                        {message && (
                            <Grid item xs={12}>
                                <Typography color={message.error === false ? 'green' : 'red'} sx={{fontFamily:'Greycliff CF'}}>
                                    {message.msg}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack direction="row">
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ fontWeight: 'bold', marginLeft: '15px' }} className='heading'>Available pincodes!</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {pinCodeList.map((val, i) => {
                                    return (
                                        <Chip key={i} label={val} style={{ margin: '15px' }} color="success" variant="outlined" onClick={() => setPinCode(val)} className='chips' />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );  
}

export default Location;
