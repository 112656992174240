// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
}

.scroll-to-top button {
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; 
  height: 35px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-to-top button:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonComm/ScrollToTopButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,kDAAkD;AACpD;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".scroll-to-top {\n  position: fixed;\n  bottom: 15px;\n  right: 15px;\n  z-index: 1000;\n}\n\n.scroll-to-top button {\n  min-width: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px; \n  height: 35px; \n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  transition: background-color 0.3s, box-shadow 0.3s;\n}\n\n.scroll-to-top button:hover {\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
