import { axiosGetWithToken, postWithToken } from "../checkout";

export const orderStatus = async (id) => {
    try {
        const response = await axiosGetWithToken(`/orders/${id}/show_status`);
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const availablePinCodes = async (data) => {
    try {
        const response = await postWithToken(`/products/available_zip_codes`, data);
        return response;
    } catch (error) {
        console.log(error);
    };
};