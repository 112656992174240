import { CHECK_PAGE_OPEN, UPDATE_PRODUCTS,UPDATE_FETCH } from "../constants";


const initialState = {
    pageOpen: false,
    loader: false,
    products: [],
    isFetched: false
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_PAGE_OPEN:
            return {
                ...state,
                pageOpen: action.payload
            };
        case UPDATE_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case UPDATE_FETCH:
            return {
                ...state,
                isFetched: action.payload
            };
        default:
            return state;
    };
};

export default searchReducer;