import { notificationService } from '../services/notifications/notificationService';
import { getProfileList } from '../services/profile';


var token = localStorage.getItem('token');
export const checkAuth = () => {
    if (!token) {
        notificationService.sendMessage({ type: "error", title: "Please login First", text: "You Profile Not Found." })
        window.location.replace(window.location.origin + '/login');
    } else {
        return true;
    }
}

export const checkValidToken = async () => {
    try {
        const response = await getProfileList();
        if (response.success) {
            return true;
        } 
    } catch (error) {
        console.error(error);
        return false;
    }
}
