import { useState } from "react";
import TextInput from "../../../components/TextInput";
import HomeSubscribe from "../Home/homeSubsrice";
import OTPValidation from "./otpValid";
import { setPhoneNumber, verifyOTP } from "../../../services/login";
import { Grid } from "@mui/material";
import { SpinLoader } from "../../../app/loader/loader";
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [isValid, setisValid] = useState(false);
    const [number, setNumber] = useState('');
    // const [code, setCode] = useState("+91");
    const code = "+91";
    const [numberError, setNumberError] = useState(false);
    const [otpErrorMessage, setOtpErrorMessage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState({ msg: "", error: false });
    const navigate = useNavigate();

    const onOtpSubmit = (otp) => {
        setLoader(true);
        const payload = {
            phone_number: number,
            otp: otp
        };
        verifyOTP(payload)
            .then((response) => {
                if (response.success && response.receiveObj) {
                    setLoader(false);
                    const data = response.receiveObj;
                    setMsg({ msg: data.message, error: false });
                    setTimeout(() => {
                        if (data.authorization) {
                            const obj = data?.authorization;
                            localStorage.removeItem("token");
                            localStorage.removeItem("userId");
                            localStorage.removeItem("selectedAddress");
                            localStorage.setItem("token", JSON.stringify(obj));
                            localStorage.setItem("userId", data?.user_id);
                            navigate('/home', { state: true });
                            window.location.reload();
                        } else {
                            navigate('/profile', { state: { code: code, phone: number } });
                        };
                        setMsg({ msg: "", error: false });
                    }, 500);
                }
            })
            .catch((error) => {
                setLoader(false);
                let errors = "";
                if (error.receiveObj?.response?.data?.errors) {
                    error.receiveObj?.response?.data?.errors?.forEach(vals => {
                        if (error.receiveObj?.response?.data?.errors?.length === 1) {
                            errors += vals;
                        } else {
                            errors += vals + ", ";
                        }
                    });
                } else {
                    setMsg({ msg: error.receiveObj.message, error: true });
                };
                setMsg({ msg: errors, error: true });
            });
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const regex = /[^0-9]/g;
        if (number?.length !== 10 || regex?.test(number)) {
            setMsg({ msg: "Invalid phone number!", error: true });
        } else if (!loader) {
            setLoader(true);
            const payload = {
                user: {
                    country_code: code,
                    phone_number: number
                }
            };
            setPhoneNumber(payload)
                .then((response) => {
                    if (response.success && response.receiveObj) {
                        setLoader(false);
                        const data = response.receiveObj;
                        setMsg({ msg: data.message, error: false });
                        setTimeout(() => {
                            if (data.message === 'OTP sent successfully.') {
                                setisValid(true);
                            }
                        }, 500);

                        setTimeout(() => {
                            setMsg({ msg: "", error: false });
                        }, 3000);
                    };
                })
                .catch((error) => {
                    setLoader(false);
                    setNumber('');
                    setMsg({ msg: error.receiveObj.message, error: true });
                });
        };
    };

    const editNumber = () => {
        setMsg({ msg: "", error: false });
        setisValid(false);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                width: '100%'
            }}
        >
            {isValid ? (
                <OTPValidation
                    setLoader={setLoader}
                    loader={loader}
                    error={otpErrorMessage}
                    number={code + " " + number}
                    onOtpSubmit={(val) => {
                        onOtpSubmit(val)
                    }}
                    ditNumber={() => { setisValid(false); setNumber(number) }}
                    callBackMessage={() => setOtpErrorMessage(null)}
                    editNumber={editNumber}
                    setMsg={setMsg}
                    msg={msg}
                />
            ) : (
                <>
                    <form className="commentForm ml-5 mr-5 mb-5 mt-48 p-5" onSubmit={onSubmitHandler}>
                        <Grid container>
                            <Grid item xs={0} sm={2} md={3} lg={3} xl={3}></Grid>
                            <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center p-2 font-articulat font-semibold" style={{ fontSize: '20px' }}>Login Account</Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center p-2 font-articulat font-normal" style={{ fontSize: '16px' }}>Please enter your mobile number to get OTP</Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                                        <Grid container className={(numberError ? "errorMessage border-red-200 " : "") + "divide-x border p-1 font-articulat rounded-xl"}>
                                            <Grid item xs={3} sm={3} md={2} lg={2} xl={2} className="text-center p-2">+91</Grid>
                                            <Grid item xs={9} sm={9} md={10} lg={10} xl={10} className="text-center">
                                                <TextInput
                                                    showLabel={false}
                                                    value={number}
                                                    type="text"
                                                    label="Phone"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    pattern="[0-9]*"
                                                    maxLength="10"
                                                    placeholder="Phone Number"
                                                    error={numberError}
                                                    errorMessage="Invalid Phone Number."
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === '' || /^[0-9][0-9]{0,9}$/.test(value)) {
                                                            setNumber(value);
                                                            if (value.length !== 10 && value.length !== 0) {
                                                                setMsg({ msg: "Please enter a valid phone number and Number should be start from 6-9", error: "Please enter a valid phone number" });
                                                                setNumberError(true);
                                                            } else {
                                                                setMsg({ msg: "", error: "" });
                                                                setNumberError(false);
                                                            }
                                                        }}}
                                                />
                                            </Grid>
                                        </Grid>
                                        {msg?.msg && <span className="text-sm font-normal" style={{ marginTop: "15px", display: "block", color: msg?.error ? "red" : 'green' }}>{msg.msg}</span>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <button
                                            style={{ fontSize: "14px" }}
                                            className="rounded-lg buttoncommon mt-3 w-full font-articulat text-xs text-white p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] h-10 focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                            type="submit">
                                            {loader ? <SpinLoader height="20px" width="20px" /> : 'Login'}
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={0} sm={2} md={3} lg={3} xl={3}></Grid>
                        </Grid>
                    </form>
                    <HomeSubscribe />
                </>
            )}
        </div>
    );
}

export default Login;
