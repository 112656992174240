// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileupload {
  margin: auto;
}

.border-l {
  border-left: 1px solid #252020;
}

.termscontent {
  p {
    padding: 5px;
  }

  h4 {
    padding: 5px;
  }

  h3 {
    padding: 5px;
  }

  ul {
    li {
      padding: 5px;
    }
  }

  ol {
    li {
      padding: 5px;
    }
  }
}


@media screen and (min-width:900px) {
  .profileupload {
    margin: 25px auto;
  }
}

@media screen and (max-width:640px) {
  .profilephoneno {
    display: flex;
  }
}

@media screen and (max-width:440px) {
  .profileupload {
    margin: 5px auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/view/private/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE;MACE,YAAY;IACd;EACF;;EAEA;IACE;MACE,YAAY;IACd;EACF;AACF;;;AAGA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".profileupload {\n  margin: auto;\n}\n\n.border-l {\n  border-left: 1px solid #252020;\n}\n\n.termscontent {\n  p {\n    padding: 5px;\n  }\n\n  h4 {\n    padding: 5px;\n  }\n\n  h3 {\n    padding: 5px;\n  }\n\n  ul {\n    li {\n      padding: 5px;\n    }\n  }\n\n  ol {\n    li {\n      padding: 5px;\n    }\n  }\n}\n\n\n@media screen and (min-width:900px) {\n  .profileupload {\n    margin: 25px auto;\n  }\n}\n\n@media screen and (max-width:640px) {\n  .profilephoneno {\n    display: flex;\n  }\n}\n\n@media screen and (max-width:440px) {\n  .profileupload {\n    margin: 5px auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
