import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, Typography, Divider, Rating, Box } from '@mui/material';
import { fetchGetApi } from '../../../services/apis.functions/api.functions';
import { SpinLoader } from '../../../app/loader/loader';
import ApplicationHelmet from '../../../navigation/navigation';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: '50px',
        paddingTop: '150px',
    },
    card: {
        textAlign: 'center',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '120px',
        display: 'block',
        margin: 'auto',
        borderRadius: '50%',
        marginTop: '15px'
    },
}));

const Heading = styled(Typography)({
    textAlign: 'center',
    marginBottom: '30px',
    fontWeight: "bold"
});


const Reviews = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetchGetApi('/reviews/admin_reviews', {})
            .then(res => {
                setLoading(false)
                setData(res?.data);

            })
            .catch(error => {
                return error;
            })
    }, []);

    return (
        <Box className={classes.mainContainer}>
            <ApplicationHelmet/>
            <Heading variant="h5" gutterBottom sx={{fontFamily:'Greycliff CF'}}>
                Customers Reviews
            </Heading>
            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                    {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                </div>
            <Grid container spacing={4}>
                {data?.map((card) => (
                    <Grid key={card.id} item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.card}>
                        <img src={card?.user_image?.url} alt="card" loading="lazy" className={classes.image}  style={{width:'150px',height:'150px'}}/>
                            <CardContent>
                                <Typography variant="h6" component="h2" gutterBottom sx={{fontFamily:'Greycliff CF'}}> 
                                {card.user_name}
                                </Typography>
                                <Rating value={card.rating} readOnly />
                                <Divider style={{ margin: '8px 0' }} />
                                <Typography variant="body2" color="textSecondary" sx={{fontFamily:'Greycliff CF'}}>
                                {card.review}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Reviews;
