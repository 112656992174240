import { useEffect, useState } from "react";
import { DateFormatter } from "../../../components/Shared/date_formatter";
import AddUpdateReview from "./addUpdateReview";
import "./reviews.css";
import LoginModal from "../../../components/login-modal";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../images/reactIcons";

const Reviews = (props) => {
    const [show, setShow] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate();

    const rating = (n) => {
        const arr = [];
        for (let i = 0; i < n; i++) {
            arr.push(i)
        }
        return arr;
    }
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            setAuth(true);
        } else {
            setAuth(false);
        };
    }, []);

    return (
        <>
            {show && <AddUpdateReview productId={props?.productId} refresh={() => { setShow(!show); props?.refresh() }} setShowModal={() => setShow(!show)} />}
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 mt-3 review-div">
                    <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 ">
                        {props?.reviews?.length < 1 && <p className="text-sm font-articulat font-semibold">No Review Found</p>}
                    </div>
                    <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 text-right">
                        <button className="focus:outline-none font-md rounded-lg text-sm px-5 py-2.5 p-1 text-white buttoncommon hover:bg-[#009898] focus:ring-4 focus:ring-[#009898]"
                            onClick={() => {
                                if (auth) {
                                    setShow(!show)
                                } else {
                                    setLoginModal(true);
                                };
                            }}
                        >Add your Review</button>
                    </div>
                </div>
            </div>
            {props?.reviews?.length > 0 && <>{props?.reviews?.map(ele =>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 p-2" key={ele?.id}>
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 review-list">
                        {ele?.user?.profile_pic ? <img src={ele?.user?.profile_pic} alt="reviewsProfileImage" loading="lazy" className="items-center h-16 lg:col-span-1 md:col-span-1 sm:col-span-1" style={{ "borderRadius": "100%" }} /> : <Icons.FaCircleUser style={{ fontSize: '64px' }}/>}
                        <div className="lg:col-span-11 md:col-span-11 sm:col-span-11 text-left mt-2 pl-5">
                            <div className="text-base font-articulat font-semibold">{ele.user?.name}</div>
                            <div className="flex gap-1 items-center text-sm font-articulat font-normal mt-3">
                                {rating(ele.rating).map((r, index) => (
                                    <Icons.FaStar key={index} className="text-[#E9C400] h-4 w-4" aria-hidden="true" />
                                ))}
                                <span>&nbsp;&nbsp;(Posted on {DateFormatter(ele.created_at, 'LL')})</span>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm font-articulat font-normal mt-3 ml-2">{ele.content}</div>
                </div>
            )}</>}
            <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} navigate={navigate} />
            <br />
        </>
    )
}
export default Reviews;