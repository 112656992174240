import axios from "axios";
import { AWS_API_URL } from "../../constant";

export const headerPromotion = async () => {
    try {
        const response = await axios.get(AWS_API_URL + "/promotions");
        return response.data;
    } catch (error) {
        throw (error);
    };
};