import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../reducers/cart/actions";
import { deleteCartItem } from "../../../services/checkout";
import { fetchCartList } from "../Home";
import "./cartlist.css";
import { useNavigate } from "react-router-dom";
import { navigateToNext } from "../../../navigation/navigation";
import { Icons } from "../../../images/reactIcons";

const CartList = (props) => {
    const { setShowModal, auth } = props;
    const cartStore = useSelector((state) => state.cartReducer.cart);
    const userId = localStorage.getItem('userId');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modalRef = useRef();

    const handleRemoveFromCart = (productId, variantId, textureId) => {
        if (auth) {
            deleteCartItem({ productId: userId, variantId: variantId, textureId: textureId })
                .then(() => { fetchCartList(dispatch) });
        }
        dispatch(removeFromCart(productId, variantId, textureId));
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        }
        // Bind the event listener to the document
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowModal]);

    return (
        <>
            <div className="justify-center items-right flex  fixed inset-0 z-50 outline-none focus:outline-none">
                <div
                    className="relative w-auto ml-auto mr-10 max-w-3xl h-fit cart-mod"
                    ref={modalRef}
                >
                    <div className="border-0 shadow-lg relative flex flex-col w-80 bg-white outline-none focus:outline-none shoppingcardresp" style={{ borderRadius: '5px' }}>
                        {/*body*/}
                        <div className="relative flex-auto" style={{ padding: '15px' }}>
                            <div className="shoppingcardheadingsec" style={{ display: 'flex', justifyContent: "space-between" }}>
                                <div className="lg:col-span-9 md:col-span-9 sm:col-span-9 xs:col-span-9 text-sm font-semibold font-articulat" style={{ display: 'flex', alignItems: 'center' }}>
                                    {cartStore?.length <= 0 ? (
                                        <span className="text-sm text-[#FF0000] p-2">
                                            Your cart is empty.
                                        </span>
                                    ) : (
                                        cartStore?.length + " Item added in your cart"
                                    )}
                                </div>
                                <div
                                    className="lg:col-span-3 md:col-span-3 sm:col-span-3 xs:col-span-3 text-xl font-semibold text-right cursor-pointer text-[#FF0000]"
                                    onClick={() => {
                                        setShowModal(false);
                                        if (window.location.pathname === '/shipping' && cartStore.length <= 0) {
                                            window.location.replace(window.location.origin + '/home');
                                        }
                                    }}
                                >
                                    <Icons.BiSolidXCircle className="h-6 w-6" aria-hidden="true"/>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 font-articulat " style={cartStore?.length > 2 ? { height: '150px', overflowY: "scroll" } : null}>
                                {cartStore?.map((ele, index) => {
                                    const filterVariantItem = ele?.variants?.length > 0 && ele?.variants?.filter((el) => el.id === ele.variant_id);
                                    return (
                                        <div className="shoppingproductdetails" key={index}>
                                            <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 xs:col-span-4 dt-text1" key={ele?.id} style={{ marginTop: '10px' }}>
                                                <img
                                                    src={ele.attachment_url[0]}
                                                    className="object-cover h-12 rounded-xl cart-res-img"
                                                    alt="cartStoreImage"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 xs:col-span-5 dt-text2" style={{ marginTop: '10px' }}>
                                                <div style={{ fontSize: '14px', fontWeight: '400' }} className="cart-item-title">{ele.name}</div>
                                                <span className="text-[#009898]" style={{ fontSize: '12px', fontWeight: '400' }}>
                                                    {ele.quantity} X ₹{
                                                        filterVariantItem.length > 0
                                                            ? ((filterVariantItem[0]?.discount_price !== null && parseFloat(filterVariantItem[0]?.discount_price) !== 0)
                                                                ? filterVariantItem[0]?.discount_price
                                                                : filterVariantItem[0]?.price)
                                                            : ((ele?.cost_price !== null && parseFloat(ele?.cost_price) !== 0)
                                                                ? ele.cost_price
                                                                : ele.master_price)
                                                    }                                                </span>
                                                {ele.master_price && <span className="text-[#999999] font-articulat font-light line-through" style={{ fontSize: '12px', fontWeight: '400' }}>
                                                    {filterVariantItem.length > 0 ? ((filterVariantItem[0]?.discount_price !== null && parseFloat(filterVariantItem[0]?.discount_price) !== 0) ? "₹" + filterVariantItem[0].price : '') : (ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? "₹" + ele.master_price : ''}
                                                </span>}
                                            </div>
                                            <div
                                                className="lg:col-span-1 md:col-span-1 sm:col-span-1 xs:col-span-3 mr-2 text-xl text-[#FF0000] font-semibold text-right cursor-pointer dt-text3"
                                                onClick={() => handleRemoveFromCart(ele.id, ele.variant_id, ele?.texture_id)}
                                                style={{ marginTop: '15px' }}
                                            >
                                                <Icons.FaTrash
                                                    className="text-[#FF0000] cursor-pointer place-items-end"
                                                    aria-hidden="true"
                                                    style={{ fontSize: '12px'  }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 font-articulat" style={{ padding: '15px' }}>
                            {cartStore?.length !== 0 && (
                                <>
                                    <div className="shoppingcardtotal">
                                        <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 mb-2 ml-1 font-semibold"> {" "} Total{" "} </div>
                                        <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 mb-2 mr-2 text-right font-semibold" style={{ marginRight: '10px' }}>
                                            ₹ {cartStore?.reduce((t, val) => {
                                                const variant = val?.variants?.find(variant => variant?.id === val?.variant_id);
                                                const variantPrice = (variant?.discount_price !== null && parseFloat(variant?.discount_price) !== 0) ? parseFloat(variant?.discount_price)
                                                    : parseFloat(variant?.price);
                                                const masterPrice = (val?.cost_price !== null && parseFloat(val?.cost_price) !== 0)
                                                    ? parseFloat(val?.cost_price)
                                                    : parseFloat(val?.master_price) || 0;
                                                const price = variantPrice || masterPrice;
                                                return t + (price * val?.quantity);
                                            }, 0)}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="shoppingcardbutton">
                                {cartStore?.length !== 0 ? (
                                    <>
                                        <button
                                            className="text-[#FF0000] border lg:col-span-6 md:col-span-6 sm:col-span-6 bg-white font-bold px-6 py-2 text-sm rounded shadow hover:shadow-lg focus:outline-none mr-2 mb-1"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="lg:col-span-6 md:col-span-6 sm:col-span-6 buttoncommon border-red text-white font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-2 mb-1"
                                            type="button"
                                            onClick={(e) => { navigateToNext(e, '/checkout', navigate); setShowModal(false); }}
                                        >
                                            Go to Cart
                                        </button>
                                    </>
                                ) : <button
                                    className="lg:col-span-6 md:col-span-6 sm:col-span-6 buttoncommon border-red text-white font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-2 mb-1 w-48"
                                    type="button"
                                    onClick={(e) => { navigateToNext(e, '/home', navigate); setShowModal(false); }}
                                >
                                    Continue to Shopping
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default CartList;