import React, { useState } from "react";
import NoDataFound from "../../../services/utils/noDataPopUp";
import { Grid, Typography, Button, Box } from "@mui/material";
import { getCurrentDate } from "../../../services/utils/function";
import './subscription.css'
import { Icons } from "../../../images/reactIcons";
import ApplicationHelmet from "../../../navigation/navigation";

const Subscription = () => {
    const [extnd, setExtnd] = useState({ id: null, show: false });
    const data = [
        {
            id: 1,
            plan: '3 Months @ 15% discount',
            orderdate: '28 Feb 2024',
            totalamount: '3000',
            availableamount: '2545',
            order_items: [{ id: 1, orderid: '#WU3746HGG12' }, { id: 2, orderid: '#WU3746HGG12' }],
        },
        {
            id: 2,
            plan: '3 Months @ 15% discount',
            orderdate: '28 Feb 2024',
            totalamount: '3000',
            availableamount: '2545',
            order_items: [{ id: 3, orderid: '#WU3746HGG12' }],
        }
    ]
    return (
        <>
           <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4">
                <ApplicationHelmet/>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Grid>
                        <Typography variant="h6" className="font-articulat" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Greycliff CF' }}>
                            Subscription
                        </Typography>
                        <Typography variant="body1" className="font-articulat valid-title">
                            Valid Till
                            <span style={{ marginLeft: '6px' }}>
                                30 May 2024
                            </span>
                        </Typography>
                    </Grid>

                    <Grid>
                        <Button className="renewbutton">
                            Renew
                        </Button>
                    </Grid>
                </Grid>

                {data.length !== 0 ? (
                    data.map((ele, index) => {
                        return (
                            <div key={index.toString()} style={{ border: "1px solid #FBF5F1", marginTop: "20px", borderRadius: "10px", fontFamily: 'Greycliff CF' }}>
                                <Box className={`header-container ${extnd?.id === index && extnd.show ? "head-styl" : "head-instyl"}`}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={3} md={3} sm={3}>
                                            <div className="header-tile">Current Plan</div>
                                            <div className="header-subtitle">{ele?.plan}</div>
                                        </Grid>
                                        <Grid item xs={12} className="subscriptionaliment" lg={3} md={3} sm={3}>
                                            <div className="header-tile">Start Date </div>
                                            <div className="header-subtitle">{getCurrentDate(ele?.orderdate)}</div>
                                        </Grid>
                                        <Grid lg={1} md={1} sm={1}></Grid>
                                        <Grid item xs={6} className="subscriptionaliment" lg={2} md={2} sm={2}>
                                            <div className="header-tile">Total Amount</div>
                                            <div className="header-subtitle">{getCurrentDate(ele?.totalamount)}</div>
                                        </Grid>
                                        <Grid item xs={6} className="subscriptionavailableamount" lg={2} md={2} sm={2}>
                                            <div className="header-tile">Available Amount</div>
                                            <div className="header-subtitle">{ele?.availableamount}</div>
                                        </Grid>
                                        <Grid item xs={12} lg={1} md={1} sm={1} className="extndbutton" >
                                            {extnd?.id === index && extnd.show ? (
                                                <Icons.IoIosArrowUp
                                                    className="cursor-pointer"
                                                    style={{ color: "#999999" }}
                                                    aria-hidden="true"
                                                    onClick={() => setExtnd({ id: index, show: false })}
                                                />
                                            ) : (
                                                <Icons.IoIosArrowDown
                                                    className="cursor-pointer"
                                                    style={{ color: "#999999" }}
                                                    aria-hidden="true"
                                                    onClick={() => setExtnd({ id: index, show: true })}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                                {extnd?.id === index && extnd.show && (
                                    <>
                                        {ele?.order_items.map((prod, i) => {
                                            return (
                                                <div key={prod.id}>
                                                    <Grid container spacing={2} style={{ padding: '15px', borderBottom: ele?.order_items.length === (i + 1) ? 'none' : '1px solid #FBF5F1' }}>
                                                        <Grid item xs={12} className="subscriptionorderid" lg={4} md={4} sm={4}>
                                                            <div className="order-d-text">
                                                                <span className="text-[#999999]">Order ID : </span>
                                                                <span style={{ fontWeight: "600", color: "#005956" }}>#WU3746HGG12</span></div>
                                                        </Grid>
                                                        <Grid item xs={6} className="subscriptionaliment" lg={4} md={4} sm={4}>
                                                            <div className="order-d-text">
                                                                <span className="text-[#999999]">Order Date: </span>
                                                                <span style={{ fontWeight: "600" }}>Jan 3, 2024</span></div>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: 'end' }} lg={4} md={4} sm={4}>
                                                            <div className="order-d-text">
                                                                <span className="text-[#999999]">Discount : </span>
                                                                <span style={{ fontWeight: "600" }}>₹ 205.00</span></div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        )
                    })
                ) : (
                    <NoDataFound />
                )}
            </div>
        </>
    )
};

export default Subscription;