import { useEffect, useState } from "react";
import HomeSubscribe from "../Home/homeSubsrice";
import { getFaq } from "../../../services/footer";
import NoDataFound from "../../../services/utils/noDataPopUp";
import "./style.css";
import { SpinLoader } from "../../../app/loader/loader";
import { Icons } from "../../../images/reactIcons";
import ApplicationHelmet from "../../../navigation/navigation";


const FAQ = () => {
    const [faq, setFaq] = useState([]);
    const [category, setCategory] = useState("Returns");
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const getFaqList = () => {
        getFaq()
            .then((response) => {
                setLoading(false)
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    setFaq(data);
                };
            })
            .catch((error) => {
                setFaq({});
            });
    };

    useEffect(() => {
        setLoading(true)
        getFaqList();
    }, []);

    return (
        <>
            <ApplicationHelmet/>
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 mt-36">
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xl p-2 pl-0 font-articulat font-semibold">FAQ</div>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm p-2 pl-0 mt-2 font-articulat font-semibold"> <span className="cursor-pointer" onClick={() => (window.location.replace(window.location.origin + '/home'))}>Home / </span><span className="text-[#009898]">FAQ</span></div>
                <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                    {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                </div>
                <div className="lg:col-span-2 md:col-span-3 sm:col-span-4 faq-head">
                    {faq.map((val, i) => {
                        return <div key={i} className={(val?.title === category ? "font-semibold border-b border-black" : "font-normal") + " cursor-pointer text-sm p-1 mt-1 font-articulat w-fit"} onClick={() => { setCategory(val?.title); setExpandedIndex(null); }}>{val?.title}</div>
                    })}
                </div>
                <div className="lg:col-span-10 md:col-span-9 sm:col-span-8">
                    {faq.filter(val => val?.title === category).map(ele => {
                        if (ele.faqs.length === 0) {
                            return <NoDataFound />
                        } else {
                            return ele.faqs.map((val, idx) => {
                                return (
                                    <div key={idx} className="p-3 mt-3 font-articulat bg-[#FBF5F1] rounded-md" onClick={() => setExpandedIndex(idx === expandedIndex ? null : idx)}>
                                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 relative">
                                            <div className="lg:col-span-11 md:col-span-11 sm:col-span-11 text-sm font-semibold quest pr-5">Q: {val.question}</div>
                                            <div className="lg:col-span-1 md:col-span-1 sm:col-span-1 text-right text-lg font-semibold q-icos cursor-pointer">
                                            {idx === expandedIndex ? (<Icons.IoIosArrowUp className="h-6 w-6"/>) : (<Icons.IoIosArrowDown className="h-6 w-6"/>)}
                                        </div>
                                            {idx === expandedIndex &&
                                                <div className="lg:col-span-11 md:col-span-10 sm:col-span-9 text-xs font-normal mt-1">
                                                    <span
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            lineHeight: "20px",
                                                            marginRight: "2px"
                                                        }}
                                                    >A:</span>
                                                    <span className="faqanswers" dangerouslySetInnerHTML={{ __html:val.answer}}/></div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    })}
                </div>
            </div>
            <HomeSubscribe />
        </>
    )
};

export default FAQ;