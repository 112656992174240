import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "../../../components/reactTable";
import { ErrorBoundary } from "../../../components/error_boundaries";
import DeleteConfirm from "../ProfileAccount/deleteConfirm";
import { removeFromCart, updateCartItem } from "../../../reducers/cart/actions";
import {
  deleteCartItem,
  getCouponDiscount,
  removeCouponCode,
  updateCartProducts,
} from "../../../services/checkout";
import { SpinLoader } from "../../../app/loader/loader";
import { useNavigate } from "react-router-dom";
import "../Shipping/review.css";
import "./checkout.css";
import LoginModal from "../../../components/login-modal";
import { DatetimeFormatter } from '../../../components/Shared/date_formatter';
import { Icons } from "../../../images/reactIcons";

const Checkout = () => {
  const [deleteId, setDeleteId] = useState(null);
  const userId = localStorage.getItem('userId');
  const cartStore = useSelector((state) => state.cartReducer.cart);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [loader, setLoader] = useState(false);
  const [dicount, setDiscount] = useState(0);
  const [loginModal, setLoginModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState(null);
  const [auth, setAuth] = useState(false);
  const { subscription } = useSelector((state) => state.subscriptionReducer);
  const navigate = useNavigate();

  const checkModal = (show) => {
    if (show) {
      handleRemoveFromCart("Ship");
    };
    setShowModal(false);
  };

  const data = useMemo(() =>
    cartStore?.map((val) => {
      const variant = val?.variants?.find(variant => variant.id === val.variant_id);
      const variantPrice = (variant?.discount_price !== null && parseFloat(variant?.discount_price) !== 0) ? parseFloat(variant?.discount_price)
        : parseFloat(variant?.price);
      const masterPrice = (val?.cost_price !== null && parseFloat(val?.cost_price) !== 0)
        ? parseFloat(val.cost_price)
        : parseFloat(val?.master_price) || 0;
      const price = variantPrice || masterPrice;
      const amount = price * val.quantity;
      return { ...val, amount: amount };
    }),
    [cartStore, auth]
  );

  const total = useMemo(() => data?.reduce((t, val) => t + val.amount, 0), [data]);


  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
        Cell: ({ row }) => {
          const rowItemTexture = row?.original?.textures?.length > 0 && row?.original?.textures.filter((t) => t.id === row?.original?.texture_id);
          const variantItemDetail = row?.original?.variants?.length > 0 && row?.original?.variants.filter((v) => v.id === row.original.variant_id);
          return (
            <div className="grid lg:grid-cols-10 md:grid-cols-12 sm:grid-cols-12 p-2 lg:gap-0 md:gap-7">
              <img
                src={row.original.attachment_url[0]}
                className="object-cover  lg:col-span-2 md:col-span-4 sm:col-span-4 h-20 lg:w-auto md:w-24"
                alt="Checkout-image"
                loading="lazy"
              />
              <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 text-left">
                {rowItemTexture?.length > 0 ?
                  <span>{row.original.name} {rowItemTexture[0]?.name && `(${rowItemTexture[0].name})`}</span> :
                  <span>{row.original.name} {row.original.texture_name && `(${row.original.texture_name})`}</span>
                }
                <br />
                {variantItemDetail?.length > 0 ? <span>{variantItemDetail[0].net_wt + variantItemDetail[0].unit}</span> : <span>{row.original.net_wt + row.original.unit}</span>}
              </div>
            </div>
          )
        },
      },
      {
        Header: "MRP",
        accessor: "master_price",
        Cell: ({ row }) => {
          const variantItemDetail = row?.original?.variants?.length > 0 && row.original.variants.filter((v) => v.id === row.original.variant_id);
          return (<>{variantItemDetail?.length > 0 ? <span>₹ {(variantItemDetail[0]?.discount_price !== null && parseInt(variantItemDetail[0].discount_price) !== 0) ? variantItemDetail[0]?.discount_price : variantItemDetail[0]?.price}</span> : <span> {(row.original.cost_price !== null && parseFloat(row.original.cost_price) !== 0) ? "₹ " + row.original.cost_price : "₹ " + row.original.master_price}</span>}
          </>)
        }
      },
      {
        Header: "QTY",
        accessor: "quantity",
      },
      {
        Header: "Subtotal",
        accessor: "amount",
        Cell: ({ row }) => {
          const variantItemDetail = row?.original?.variants?.length > 0 && row.original.variants.filter((v) => v.id === row.original.variant_id);
          return (
            <>
              {variantItemDetail.length > 0 ? (
                <span>
                  ₹ {(variantItemDetail[0]?.discount_price !== null && parseFloat(variantItemDetail[0]?.discount_price) !== 0)
                    ? (parseFloat(variantItemDetail[0]?.discount_price) * row.original.quantity)
                    : (parseFloat(variantItemDetail[0]?.price) * row.original.quantity)}
                </span>
              ) : (
                <span>₹ {row.original.amount}</span>
              )}
            </>
          );
        }
      },
      {
        Header: "Action",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <div className="text-center" style={{textAlign:"-webkit-center"}}>
              <Icons.FaTrash
                className="text-[#FF0000] cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setShowModal(true);
                  setDeleteId(row.original);
                }}
              />
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const applyCoupon = () => {
    if (coupon) {
      setLoader(true);
      setMsg(null);
      const data = {
        total_price: total,
        coupon_code: coupon.trim(),
      };
      getCouponDiscount(data)
        .then((res) => {
          setLoader(false);
          if (res?.data?.status === "Unused") {
            setMsg({ error: false, msg: res?.data?.message });
            setDiscount(parseFloat(res?.data?.discount_percentage));
          } else {
            setMsg({ error: true, msg: res?.data?.error });
          }
        })
        .catch((error) => {
          setLoader(false);
          setMsg({ error: true, msg: "Please enter a valid coupon code!" });
        });
    } else {
      setMsg({ error: true, msg: "Please enter coupon code!" });
    }
  };

  const removeCoupon = () => {
    setMsg(null);
    setDiscount(0);
    const data = {
      coupon_code: coupon?.trim(),
    };
    removeCouponCode(data)
      .then((res) => {
        setCoupon("");
      })
      .catch((error) => {
        setCoupon("");
      });
  };

  const handleRemoveFromCart = (val) => {
    const { id, variant_id, texture_id } = deleteId;
    if (val?.type !== 'cart') {
      if (auth) {
        deleteCartItem({ productId: userId, variantId: variant_id, textureId: texture_id });
      }
      dispatch(removeFromCart(id, variant_id, texture_id));

    } else if (val.type === "cart") {
      if (auth) {
        deleteCartItem({ productId: userId, variantId: variant_id, textureId: texture_id });
      }
      dispatch(removeFromCart(id, variant_id, texture_id));
    };
    if (cartStore?.length === 1) {
      setTimeout(() => {
        window.location.replace(window.location.origin + "/home");
      }, 2000)
    };
    setDeleteId(null);
  };

  const handleUpdateCartItem = (textureId, productId, quantity) => {
    updateCartProducts(productId, { quantity: quantity, texture_id: textureId, variant_id: productId });
    dispatch(updateCartItem(productId, quantity, textureId));
  };

  // const handleSubscribePlan = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, []);


  // const AddToPlan = (item) => {
  //   dispatch(addSubscription(item));
  //   setOpen(false);
  // };

  // const RemovePlan = () => {
  //   dispatch(removeSubscription());
  // };
  const calculateDeliveryDate = (date) => {
    const twelveHourFormat = date?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    if (twelveHourFormat) {
      const [time, period] = twelveHourFormat.split(" ");
      const [hour] = time.split(":").map(Number);

      const deliveryDate = new Date(date);
      if ((period === "PM" && hour > 1)) {
        deliveryDate.setDate(date.getDate() + 2);
      } else {
        deliveryDate.setDate(date.getDate() + 1);
      }
      return DatetimeFormatter(deliveryDate);

    }
    return null;
  };

  const handleButtonClick = () => {
    const date = new Date();
    setCurrentDate(date);
    const formattedDeliveryDate = calculateDeliveryDate(date);
    setOrderDeliveryDate(formattedDeliveryDate);
    localStorage.setItem("orderdate", formattedDeliveryDate);
    if (auth) {
      navigate("/shipping", { state: { discount: dicount } });
      setMsg(null);
    } else {
      setLoginModal(true);
    }
  };


  return (
    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 mt-40">
      <div className="lg:col-span-9 md:col-span-8 sm:col-span-12 m-1 mt-1 checkoutheading">
        <span className="text-lg font-articulat font-semibold">Check Out</span>
      </div>
      <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 m-1 mt-1 bg-[#000000] text-[#FF0000] rounded text-sm p-2 font-articulat font-semibold">
        Free delivery above Rs 399
      </div>
      <div className="lg:col-span-9 md:col-span-8 sm:col-span-12 m-1 mt-1">
        <ErrorBoundary>
          <ReactTable
            columns={columns}
            data={data}
            handleUpdateCartItem={handleUpdateCartItem}
            handleRemoveFromCart={handleRemoveFromCart}
            responsivedata={cartStore}
            setShowModal={setShowModal}
            setDeleteId={setDeleteId}
          />
        </ErrorBoundary>
      </div>

      {showModal && (
        <DeleteConfirm
          setShowModal={(e) => checkModal(e)}
          message="Are you sure you want to delete?"
        />
      )}

      <div className="lg:col-span-3 md:col-span-4 sm:col-span-12 mt-1 p-1">
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-2">
          <div className="discountbox">
            <span className="text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 discountboxvalue">
              Subtotal
            </span>
            <span className="text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 discountboxvalue">
              ₹{total}
            </span>
          </div>
          <span className="text-sm font-articulat font-semibold lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
            <span className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2 checkoutenterdisc">
              Enter Discount Code
            </span>
            <br />
            <div
              style={{
                margin: "10px 0px",
                textAlign: "center",
                border: "1px solid #000",
                borderRadius: "7px",
                display: "flex",
                position: "relative",
              }}
            >
              <input
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                  borderRadius: "5px",
                  padding: "8px 10px",
                  paddingRight: "35px",
                }}
                value={coupon}
                type="text"
                id="discountCode"
                placeholder="Enter Coupon Code"
                onChange={(e) => {
                  setCoupon(e.target.value);
                  setMsg(null);
                }}
              />
              {coupon?.length !== 0 && (
                <i
                  onClick={removeCoupon}
                  class="fa-regular fa-circle-xmark"
                  style={{
                    position: "absolute",
                    right: "75px",
                    top: "8px",
                    fontSize: "20px",
                  }}
                ></i>
              )}
              <button
                disabled={loader}
                className="buttoncommon"
                style={{
                  color: "white",
                  padding: "5px 15px",
                  fontWeight: 400,
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  width: "65px",
                }}
                onClick={() => {
                  applyCoupon();
                }}
              >
                {loader ? <SpinLoader height="20px" width="20px" /> : "Apply"}
              </button>
            </div>
            {msg && (
              <div
                style={{ color: msg.error ? "red" : "green", fontWeight: 400 }}
              >
                {msg?.msg}
              </div>
            )}
          </span>
          <div className="discountbox">
            <span className="text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-2 checkoutenterdisc">
              Discount {dicount ? `Flat(${dicount}%)` : null}
            </span>
            <span className="text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-2 checkoutenterdiscvalue">
              {`₹ ${(total * dicount) / 100}`}{" "}
            </span>
          </div>
          <div className="discountbox">
            <span className="text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-2 checkoutenterdisc">
              Total
            </span>
            <span className="text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-2 checkoutenterdiscvalue">
              ₹ {total - (total * dicount) / 100}
            </span>
          </div>
          <div className="discountbox">
            <span className="text-sm font-articulat font-semibold lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 checkoutenterdisc">
              Shipping Cost
            </span>
            <span className="text-xs font-articulat font-normal text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#999999]">
              Calculated at the next step
            </span>
          </div>
          {subscription ?
            <div className="discountbox">
              <span className="text-sm font-articulat font-semibold lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 checkoutenterdisc">
                Subscription
                <span className="text-xs font-articulat font-normal text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#999999]">
                  ({(subscription?.name).trim()})
                </span>
              </span>
              <span className="text-xs font-articulat font-semibold text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#000]">
                {subscription?.price}
              </span>
            </div>
            :
            null
          }
          <div className="discountbox">
            <span className="text-lg font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-3 discountboxvalue">
              Grand Total
            </span>
            <span className="text-lg font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-3 discountboxvalue checkoutenterdiscvalue">
              ₹ {(total - (total * dicount) / 100) + (subscription?.price ? parseFloat(subscription?.price) : 0)}
            </span>
          </div>
          {/*  subscription code*/}
          {/* {
            subscription ?
              <div className="discountbox">
                <span className="text-sm font-articulat font-semibold lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 checkoutenterdisc">
                  Remove Subscripton
                </span>
                <span className="text-xs font-articulat font-normal text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#999999]">
                  <div
                    className="lg:col-span-3 md:col-span-3 sm:col-span-3 xs:col-span-3 text-xl cursor-pointer"
                    onClick={() => {
                      RemovePlan()
                    }}
                  >
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </div>
                </span>
              </div>
              :
              <div class="font-articulat font-semibold lg:col-span-12 md:col-span-8 sm:col-span-8 mt-3 subscribeplanbox">
                <span class="subscriptionimgbox">
                  <img src={images.subscriptionicon} class="object-cover" />
                </span>
                <div class="subscriptiontext">
                  <span> Save upto 50% extra with our</span>
                  <button
                    class="subscribeplanbutton"
                    onClick={handleSubscribePlan}
                  >
                    Subscription Plans
                  </button>
                </div>
              </div>
          } */}
        </div>
        <div
          className={
            `m-1 mt-1  ${cartStore?.length > 0 ? 'buttoncommon' : 'bg-[#66baba]'} text-white rounded text-sm p-3 font-articulat font-semibold text-center cursor-pointer`
          }
          onClick={handleButtonClick}
        >
          <button disabled={cartStore?.length > 0 ? false : true}>Proceed to Checkout</button>
        </div>
      </div>
      {/* <Subscriptionplans open={open} setOpen={setOpen} AddToPlan={AddToPlan} /> */}
      <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} navigate={navigate} />
    </div>
  );
};

export default Checkout;