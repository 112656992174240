import React from "react";
import "./referfriend.css"
import CommingSoonMainPage from "../../../components/greatethings/commingsoonpage";
import ApplicationHelmet from "../../../navigation/navigation";


const ReferFriend = () => {
    return (
        <> 
            <ApplicationHelmet/>
            <CommingSoonMainPage />
        </>
    );
};

export default ReferFriend;
