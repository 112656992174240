// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profiledatediv{
    width: 100%;
    input{
        min-width: 96%;
        height: 38px;
        background-color: #fff;
        color: black;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/TextInputWithIcon/textinput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX;QACI,cAAc;QACd,YAAY;QACZ,sBAAsB;QACtB,YAAY;IAChB;AACJ","sourcesContent":[".profiledatediv{\n    width: 100%;\n    input{\n        min-width: 96%;\n        height: 38px;\n        background-color: #fff;\n        color: black;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
