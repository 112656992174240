import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Container } from "@mui/material";
import "./style.css";
import { fetchGetApi } from "../../../services/apis.functions/api.functions";
import { SpinLoader } from "../../../app/loader/loader";
import ApplicationHelmet from "../../../navigation/navigation";

const ReturnPolicy = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    fetchGetApi('/privacy_policies/return_policy', {})
      .then(res => {
        setLoading(false)
        if (res?.status === 200) {
          setData(res?.data);
        };
      })
      .catch(error => {
        return error;
      })
  }, []);
  return (
    <Container maxWidth={false} style={{ paddingTop: '150px', paddingBottom: '50px' }}>
      <ApplicationHelmet/>
      <Box>
        <Stack className="header-title">
          <Typography variant="h5" className="returnpolicys">
            Return and Cancellation
          </Typography>
        </Stack>
        <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
          {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
        </div>
        <Box className="returnContentbox" marginTop={2} padding={2}>
          <Box>
            <Typography variant="body2" className="returnpolicydiscription" dangerouslySetInnerHTML={{ __html: data?.description }} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ReturnPolicy;
