import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCartItem } from "../../../reducers/cart/actions";
import { updateCartProducts } from "../../../services/checkout";
import "./review.css";

const Review = (props) => {
  const cartStore = useSelector((state) => state.cartReducer.cart);
  const dispatch = useDispatch();

  const handleUpdateCartItem = (productId, quantity, textureId) => {
    updateCartProducts(productId, { quantity: quantity, texture_id: textureId, variant_id: productId});
    dispatch(updateCartItem(productId, quantity, textureId));
  };

  return (
    <>
      <div className="grid lg:grid-col-12 md:grid-col-12 sm:grid-col-12 reviewproductlistmainbox">
        <div className="text-lg font-articulat font-semibold mt-3 lg:col-span-12 md:col-span-12 sm:col-span-12">
          {!props?.order && "Product List :"}
        </div>
        <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-sm font-articulat font-semibold bg-[#FBF5F1] p-2">
          Product
        </div>
        <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-2 text-sm font-articulat font-semibold bg-[#FBF5F1] p-2">
          Qty
        </div>
        <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-2 text-sm font-articulat font-semibold bg-[#FBF5F1] p-2">
          Subtotal
        </div>
        {props?.order ? props?.orderDetails.map((ele, index) => {
          const eleTexture = ele?.textures?.length > 0 && ele?.textures.filter((t) => t?.id === ele?.texture_id);
          return (
            <React.Fragment key={index}>
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 mt-5 text-xs font-articulat font-normal">
                <img
                  src={ele.product?.attachment_url[0]}
                  className="object-cover h-20"
                  alt={ele.product?.name}
                  loading="lazy"
                />
              </div>
              <div className="lg:col-span-5 md:col-span-4 sm:col-span-4 mt-5 text-xs font-articulat font-normal ml-3 display: grid">
                <span>{ele.product?.name} {eleTexture?.length > 0 && `(${(eleTexture[0]?.name)})`}</span>
                <span>{ele.product.net_wt + ele.product.unit}</span>
              </div>
              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-5 text-xs font-articulat font-normal ml-3">
                <span style={{ padding: "10px" }}>{ele?.quantity}</span>
              </div>
              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-5 text-xs font-articulat font-normal ml-3">
                ₹{ele.quantity * ((ele.product.cost_price === null || ele.product.cost_price === 0.0) ? parseFloat(ele.product.master_price) : parseFloat(ele.product.cost_price))}
              </div>
            </React.Fragment>
          )
        }) : cartStore?.map((ele, index) => {
          const eleTexture = ele?.textures?.length > 0 && ele?.textures.filter((t) => t.id === ele?.texture_id);
          const variantItems = ele?.variants?.filter((v) => v.id === ele.variant_id);
          return (
            <React.Fragment key={index}>
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 mt-5 text-xs font-articulat font-normal">
                <img
                  src={ele.attachment_url[0]}
                  className="object-cover h-20"
                  alt={ele.name}
                  loading="lazy"
                />
              </div>
              <div className="lg:col-span-5 md:col-span-4 sm:col-span-4 mt-5 text-xs font-articulat font-normal ml-3 display: grid">
                <span>{ele.name} {eleTexture?.length > 0 && `(${(eleTexture[0]?.name)})`}</span>
                <span>{variantItems?.[0]?.net_wt + variantItems?.[0].unit}</span>
              </div>
              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-5 text-xs font-articulat font-normal ml-3">
                <span
                  style={{
                    border: "1px solid #000",
                    borderRadius: "3px",
                    padding: "5px 7px",
                  }}
                >
                  <button
                    onClick={() => {
                      if (ele?.quantity === 1) {
                      } else {
                        handleUpdateCartItem(ele?.variant_id, ele?.quantity - 1, ele?.texture_id);
                      }
                    }} 
                    className="text-xl"
                  >
                    -
                  </button>
                  <span style={{ padding: "10px" }}>{ele?.quantity}</span>
                  <button
                    onClick={() =>
                      handleUpdateCartItem(ele?.variant_id, ele?.quantity + 1,  ele?.texture_id)
                    }
                    className="text-base"
                  >
                    +
                  </button>
                </span>
              </div>
              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 mt-5 text-xs font-articulat font-normal ml-3">
                ₹{ele.quantity * ((variantItems?.[0]?.discount_price === null || variantItems?.[0]?.discount_price === 0.0) ? parseFloat(variantItems?.[0]?.price) : parseFloat(variantItems?.[0]?.discount_price))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="reviewproductlistmainbox-1">
        <div className="text-lg font-articulat font-semibold mt-3 lg:col-span-12 md:col-span-12 sm:col-span-12">
          {!props?.order && "Product List :"}
        </div>
        <div
          className="reviewproductlistresponsive"
          style={{ maxWidth: "640px", width: "100%", margin: "15px auto" }}
        >
          <table className="reviewrestable" style={{ width: "100%" }}>
            <thead className="reviewrestablehead">
              <tr>
                <th className="text-left" colSpan={2}>
                  Product
                </th>
                <th className="text-left">Qty</th>
                <th className="text-left">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {props.order ? props.orderDetails.map((ele, index) => {
                const eleTexture = ele?.textures?.length > 0 && ele?.textures.filter((t) => t.id === ele?.texture_id);
                return (
                  <tr key={index}>
                    <td className="text-left">
                      <img
                        src={ele.product?.attachment_url[0]}
                        alt={ele.product?.name}
                        loading="lazy"
                        className="object-cover h-12 shippingreviewproimg"
                      />
                    </td>
                    <td className="text-left" style={{ padding: "10px 0" }}>
                      {ele.product?.name} {eleTexture?.length > 0 && `(${(eleTexture[0]?.name)})`}
                      <div>
                        <span style={{ fontWeight: 600 }}>{ele.product?.net_wt + ele.product?.unit}</span>
                      </div>
                    </td>
                    <td className="text-left" style={{ maxWidth: "300px", overflow: "hidden" }}>
                      <span style={{ padding: "10px", fontWeight: 600 }}>{ele?.quantity}</span>
                    </td>
                    <td className="text-left" style={{ padding: "10px", fontWeight: 600 }}>
                      ₹{ele.quantity * ((ele.product.cost_price === null || ele.product.cost_price === 0.0) ? parseFloat(ele.product.master_price) : parseFloat(ele.product.cost_price))}
                    </td>
                  </tr>
                )
              }) : cartStore?.map((ele, index) => {
                const eleTexture = ele?.textures?.length > 0 && ele?.textures.filter((t) => t.id === ele?.texture_id);
                const variantItems = ele?.variants?.filter((v) => v.id === ele.variant_id);
                return (
                  <tr key={index}>
                    <td className="text-left">
                      <img
                        src={ele.attachment_url[0]}
                        alt={ele.name}
                        loading="lazy"
                        className="object-cover h-12 shippingreviewproimg"
                      />
                    </td>
                    <td className="text-left" style={{ padding: "10px 13px" }}>
                      {ele.name} {eleTexture?.length > 0 &&  `(${eleTexture[0]?.name})`}
                      <div>
                        <span style={{ fontWeight: 600 }}>{variantItems?.[0]?.net_wt + variantItems?.[0]?.unit}</span>
                      </div>
                    </td>
                    <td className="text-left" style={{ maxWidth: "300px", overflow: "hidden" }}>
                      <span
                        style={{
                          border: "1px solid #000",
                          borderRadius: "3px",
                          padding: "3px 1px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <button
                          onClick={() => {
                            if (ele?.quantity > 1) {
                              handleUpdateCartItem(ele?.variant_id, ele?.quantity - 1);
                            }
                          }}
                          style={{
                            flex: "none",
                            minWidth: "25px",
                          }}
                        >
                        <span className="text-lg">-</span> 
                        </button>
                        <span
                          style={{
                            padding: "0 3px",
                            flex: "none",
                            minWidth: "20px",
                          }}
                        >
                          {ele?.quantity}
                        </span>
                        <button
                          onClick={() =>
                            handleUpdateCartItem(ele?.variant_id, ele?.quantity + 1)
                          }
                          style={{
                            flex: "none",
                            minWidth: "25px",
                          }}
                        >
                         <span className="text-lg">+</span> 
                        </button>
                      </span>
                    </td>
                    <td className="text-left" style={{ padding: "10px", fontWeight: 600 }}>
                      ₹{ele.quantity * ((variantItems?.[0]?.discount_price === null || variantItems?.[0]?.discount_price === 0.0) ? parseFloat(variantItems?.[0]?.price) : parseFloat(variantItems?.[0]?.discount_price))}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Review;
