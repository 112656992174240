// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width:600px) {
    .referfriendsendbutton {
        width: 100%;
    }
}

@media screen and (max-width:900px) {
    .divd-con {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .divd-line {
        border-bottom: 1px solid #fff;
        width: 200px;
    }
}

@media screen and (min-width:900px) {
    .divd-con {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/view/public/ReferFriend/referfriend.css"],"names":[],"mappings":";AACA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,6BAA6B;QAC7B,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n@media screen and (max-width:600px) {\n    .referfriendsendbutton {\n        width: 100%;\n    }\n}\n\n@media screen and (max-width:900px) {\n    .divd-con {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n    }\n\n    .divd-line {\n        border-bottom: 1px solid #fff;\n        width: 200px;\n    }\n}\n\n@media screen and (min-width:900px) {\n    .divd-con {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
