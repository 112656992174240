import { capitalizeWords } from "../../../services/utils/function";
import { Typography } from "@mui/material";
import './popup.css';
import { Icons } from "../../../images/reactIcons";

const Description = (props) => {

    return (
        <>
            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 ">
                {(props?.product?.description?.length < 1 || props?.product?.description === undefined) && <p className="text-sm font-articulat font-semibold">No Description Found</p>}
            </div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-xs font-articulat font-normal text-semibold description-lists">
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: props?.product?.description }}
                    className="dynamin-contents"
                    sx={{ fontFamily: 'Greycliff CF', margin: '0 auto' }}
                />
            </div>
            {props?.product?.product_properties?.map((ele, index) => (
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3" key={index}>
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 productdescriptionlist">
                        <div className="flex items-center lg:col-span-2 md:col-span-3 sm:col-span-4 text-xs font-articulat font-normal text-semibold productdescriptionlistname">
                            <Icons.FaCircle style={{ fontSize: "6px" }}/>&nbsp;&nbsp;{ele.name}
                        </div>
                        <div className="lg:col-span-10 md:col-span-9 sm:col-span-8 text-xs font-articulat font-normal text-semibold">
                            {capitalizeWords(ele.value)}
                        </div>
                    </div>
                </div>
            ))}
            <br />
        </>
    );
};

export default Description;
