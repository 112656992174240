import React, { useState, useEffect } from "react";
import TextInput from "../../../components/Shared/TextInput";
import TextArea from "../../../components/Shared/TextArea";
import { addRating } from "../../../services";
import { notificationService } from "../../../services/notifications/notificationService";
import { checkAuth } from "../../../app/checkAuth";
import "./leavereviews.css";
import { Icons } from "../../../images/reactIcons";

const LeaveReview = (props) => {
    const { setShowModal, refresh } = props;
    const [payload, setPayload] = useState({});
    const [rating, setRating] = useState(0);
    const [msg, setMsg] = useState({ msg: "", error: false });

    const onSubmitHandler = () => {
        var error = false;
        const obj = {};
        if (rating > 0) {
            obj['rating'] = rating
        } else {
            setMsg({ msg: "Your Rating is Required!", error: true });
            error = true;
        };
        if (payload.name && payload.name !== "") {
            obj['name'] = payload.name
        } else {
            setMsg({ msg: "Name is Required!", error: true });
            error = true;
        };
        if (payload.review && payload.review !== "") {
            obj['content'] = payload.review
        } else {
            setMsg({ msg: "Review is Required!", error: true });
            error = true;
        };

        obj['content'] = payload.review
        obj['product_id'] = props.productId
        if (!error) {
            addRating(obj)
                .then((response) => {
                    if (response.success && response.receiveObj) {
                        setMsg({ msg: "Your rating has been submitted successfully!", error: false });
                        setTimeout(() => { refresh(); }, 500);
                    };
                })
                .catch((error) => {
                    setMsg({ msg: error.receiveObj.response?.data?.ExceptionMessage, error: false });
                    notificationService.sendMessage({ type: "error", title: "Add Rating", text: error.receiveObj.response?.data?.ExceptionMessage })
                });
        }
    };

    useEffect(() => {
        checkAuth();
        setMsg({ msg: "", error: false });
    }, []);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto m-5 leave-btns">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="relative p-4  flex-auto">
                            <div className="grid lg:grid-col-12 md:grid-col-12 sm:grid-col-12">
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
                                    <label for="rating" className="block mb-2 font-sm font-articulat font-semibold text-xs mt-2 text-center">Add Review</label>
                                    <div className="flex">
                                    <span className={"m-2 mr-2 ml-0 " + (rating === 1 && "text-[#E9C400]")} onClick={() => setRating(1)}>
                                        <div className="flex">
                                          <Icons.FaStar className="h-4 w-5" />
                                          </div>
                                    </span>
                                    <span className={"m-2 mr-2 " + (rating === 2 && "text-[#E9C400]")} onClick={() => setRating(2)}>
                                        <div className="flex">
                                          <Icons.FaStar className="h-4 w-5" />
                                          <Icons.FaStar className="h-4 w-5"/>
                                          </div>
                                    </span>
                                    <span className={"m-2 mr-2 " + (rating === 3 && "text-[#E9C400]")} onClick={() => setRating(3)}>
                                       <div className="flex">
                                          <Icons.FaStar className="h-4 w-5" />
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          </div>
                                    </span>
                                    <span className={"m-2 mr-2 " + (rating === 4 && "text-[#E9C400]")} onClick={() => setRating(4)}>
                                         <div className="flex">
                                          <Icons.FaStar className="h-4 w-5" />
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          </div>
                                    </span>
                                    <span className={"m-2 mr-0 " + (rating === 5 && "text-[#E9C400]")} onClick={() => setRating(5)}>
                                        <div className="flex">
                                          <Icons.FaStar className="h-4 w-5" />
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          <Icons.FaStar className="h-4 w-5"/>
                                          </div>
                                    </span>
                                    </div>
                                </div>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
                                    <TextInput
                                        onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                                        value={payload.name}
                                        type="text"
                                        id="name"
                                        errorMessage="Full Name is Required."
                                        placeholder="Enter Full Name"
                                        isLabelShow={true}
                                        showLabel="Full Name"
                                        error={payload.nameError}
                                    />
                                </div>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
                                    <TextArea
                                        onChange={(e) => setPayload({ ...payload, review: e.target.value })}
                                        value={payload.review}
                                        type="textarea"
                                        id="review"
                                        errorMessage="Your Review is Required."
                                        placeholder="Enter Your Review"
                                        isLabelShow={true}
                                        showLabel="Your Review"
                                        error={payload.reviewError}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*footer*/}
                        {msg?.msg && <span className="text-sm font-normal m-5 mt-0" style={{ display: "block", color: msg?.error ? "red" : 'green' }}>{msg.msg}</span>}
                        <div className="items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 leave-btns font-articulat">
                            <button
                                className="lg:col-span-6 md:col-span-6 sm:col-span-6 buttoncommon border text-white font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={onSubmitHandler}
                            >
                                Create
                            </button>
                            <button
                                className="text-[#FF0000] border lg:col-span-6 md:col-span-6 sm:col-span-6 bg-white font-bold px-6 py-2 text-sm rounded shadow hover:shadow-lg focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default LeaveReview;
