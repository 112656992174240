import axios from "axios";
import { AWS_API_URL } from "../../constant";

export const getToken = () => {
    let token = localStorage.getItem('token');
    return token ? token.replace(/"/g, "") : null;
};

export const postWithToken = async (url, data) => {
    const token = getToken();
    try {
        const response = await axios.post(AWS_API_URL + url, data, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const createCashfreeOrder = async (orderData) => {
    try {
        const response = await postWithToken('/create/payment', orderData, {
            headers: {
                'x-client-id': process.env.CASHFREE_CLIENT_ID,
                'x-client-secret': process.env.CASHFREE_SECRET_KEY,
                'Content-Type': 'application/json'
            }
        });

        return response;
    } catch (error) {
        console.error('Error creating Cashfree order:', error);
        throw error;
    }
};


export const deleteWithToken = async (url) => {
    const token = getToken();
    try {
        const response = await axios.delete(AWS_API_URL + url, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        return error
    }
};

export const axiosGetWithToken = async (url) => {
    const token = getToken();
    try {
        const response = await axios.get(AWS_API_URL + url, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const patchWithToken = async (url, data) => {
    const token = getToken();
    try {
        const response = await axios.patch(AWS_API_URL + url, data, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
      return error;
    }
};

export const createOrder = async (data) => {
    try {
        const response = await postWithToken('/orders', data);
        return response;
    } catch (error) {
        return error.response.data.error;
    }
};

export const proceedPayment = async (data) => {
    try {
        const response = await postWithToken('/create/payment', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const getOrderDetails = async (id) => {
    const token = getToken();
    try {
        const response = await axios.get(AWS_API_URL + `/orders/${id}`,
            {
                headers: { Authorization: token, responseType: 'blob' }
            }
        );
        return response;
    } catch (error) {
        return error;
    };
};

export const makeLivePayment = async (data) => {
    try {
        const response = await postWithToken('/capture/payment', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const fetchAboutUs = async (data) => {
    try {
        const response = await axiosGetWithToken('/our_stories/about_us');
        return response;
    } catch (error) {
        return error;
    }
};

export const addItemsToCart = async (data) => {
    try {
        const response = await postWithToken('/carts', data);
        return response;
    } catch (error) {
       return error;
    };
};

export const fetchCartProducts = async () => {
    try {
        const response = await axiosGetWithToken(`/carts`);
        return response;
    } catch (error) {
        return error;
    };
};

export const deleteCartItem = async (data) => {
    const { productId, textureId, variantId } = data;
    try {
        const response = await deleteWithToken(`/carts/${productId}?variant_id=${variantId}&texture_id=${textureId}`);
        return response;
    } catch (error) {
        return error;
    };
};

export const updateCartProducts = async (id, data) => {    
    try {
        const response = await patchWithToken(`/carts/${id}`, data);
        return response;
    } catch (error) {
        return error;
    };
};

export const orderHistory = async () => {
    try {
        const response = await axiosGetWithToken(`/orders`);
        return response;
    } catch (error) {
        return error;
    };
};

export const downloadInvoice = async (id) => {
    const token = getToken();
    try {
        const response = await axios.get(AWS_API_URL + `/invoices/${id}`, {
            headers: { Authorization: token },
            responseType: 'blob'
        });
        return response;
    } catch (error) {
        console.log(error);
    };
};

export const getCouponDiscount = async (data) => {
    const token = getToken();
    try {
        const response = await axios.post(AWS_API_URL + '/coupons/apply', data, {
            headers: { Authorization: token }
        });
        return response;
    } catch (error) {
        return error.response;
    };
};

export const removeCouponCode = async (data) => {
    const token = getToken();
    try {
        const response = await axios.delete(AWS_API_URL + '/coupons/remove', {
            headers: { Authorization: token },
            data: data
        });
        return response.data;
    } catch (error) {
       return error;
    }
};

export const clearCart = async () => {
    const token = getToken();
    try {
        const response = await axios.delete(AWS_API_URL + `/carts/reset_cart`, {
            headers: { Authorization: token }
        });
        return response;
    } catch (error) {
        return error;
    }
};


export function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => reject(false);
        document.body.appendChild(script);
    });
};
