import { useState } from "react";
import TextInputWithIcon from "../../../components/Shared/TextInputWithIcon";
import HomeSubscribe from "../Home/homeSubsrice";
import { orderStatus } from "../../../services/orders";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { getCurrentDate } from "../../../services/utils/function";
import { useNavigate } from "react-router-dom";
import './orderstatus.css'
import ApplicationHelmet, { navigateToNext } from "../../../navigation/navigation";
import { Icons } from "../../../images/reactIcons";

const OrderStatus = () => {
    const [order, setOrder] = useState(null);
    const [msg, setMsg] = useState("");
    const [orderDetails, setOrderDetails] = useState(null);
    const navigate = useNavigate();

    const fetchStatus = () => {
        setOrderDetails(null);
        if (order == null) {
            setMsg("Please enter order id!");
        } else {
            setMsg("");
            orderStatus(order)
                .then(res => {
                    if (res?.order) {
                        setOrderDetails(res);
                    };
                })
                .catch(error => {
                    setMsg("Something went wrong please try again!");
                });
        }
    };

    return (
        <>
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 mt-36">
                <ApplicationHelmet />
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xl p-2 font-articulat font-semibold" >Order Status</div>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm p-2 mt-2 font-articulat font-semibold"><button onClick={(e) => navigateToNext(e, "/home", navigate)}>Home</button> / <span className="text-[#009898]">Order Status</span></div>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                        <div className="lg:col-span-7 md:col-span-6 sm:col-span-12 mr-3">
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs p-2 font-articulat font-normal">To track your order please enter your Order ID in the box below and press the "Track" button. This was given to you on your receipt and in the confirmation email you should have received.</div>
                            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-3">
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                                    <TextInputWithIcon
                                        onChange={(e) => { setOrder(e.target.value); setMsg(""); }}
                                        defaultValue={order}
                                        icon={<Icons.FaListOl className="h-4 w-4"/>}
                                        type="text"
                                        id="order"
                                        pattern="[0-9]*"
                                        placeholder="Enter Order Number"
                                        isLabelShow={true}
                                        showLabel="Order Number"
                                        errorMessage="Order Number is Required."
                                    />
                                </div>
                                {msg && <div style={{ color: '#ff0000', marginTop: '10px' }} className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-articulat font-normal">{msg}</div>}
                                {orderDetails && (
                                    <div style={{ marginTop: '10px' }} className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-articulat font-normal">
                                        <Stack direction="row" spacing={1}>
                                            <Chip label={orderDetails?.status} color="success" variant="outlined" />
                                            <Chip label={`Order placed on - ${getCurrentDate(orderDetails?.order?.created_at)}`} color="success" variant="outlined" />
                                            <Chip label={`Delivered on - ${getCurrentDate(orderDetails?.order?.delivery_date)}`} color="success" variant="outlined" />
                                        </Stack>
                                    </div>
                                )}
                                <div className="lg:col-span-4 md:col-span-4 sm:col-span-6 text-center mt-4">
                                    <button
                                        className="rounded-lg buttoncommon w-full font-articulat text-xs text-white p-3 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                        type="button"
                                        onClick={fetchStatus}
                                    >
                                        Track Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeSubscribe />
        </>
    )
}
export default OrderStatus;