import CommingSoonMainPage from "../../../components/greatethings/commingsoonpage";
import ApplicationHelmet from "../../../navigation/navigation";

const Subscribe = () => {
  

  return (
    <>
      <ApplicationHelmet/>
      <CommingSoonMainPage/>
    </>
  );
};

export default Subscribe;
