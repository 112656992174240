// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscribeplaceview {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25rem;
}

.subscribeplanbutton {
  color: #009898;
  border: none;
  margin: 2px 4px;
  cursor: pointer;
  border-bottom: 1px solid #009898;
  display: inline-block;
  position: relative;
  font-size: 13px;
}

.subscribeplanbutton::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #009898;
}

@media screen and (max-width: 767px) {
  .checkoutheading {
    margin-top: -44px;
    margin-bottom: 14px;
  }
  .subscriptionimgbox {
    height: 18px !important;
  }
}

.subscribeplanbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscriptionimgbox {
  flex-shrink: 0;
  width: 16px;
  height: 38px;
}

.subscriptiontext {
  flex-grow: 1;
  margin-left: 0.5rem;
}

.subscriptiontext span {
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25rem;
}

.subscriptionplanbutton {
  white-space: nowrap;
}
@media screen and (max-width: 383px) {
  .subscriptionimgbox {
    height: 40px !important;
  }
}
@media screen and (min-width: 1439px){
  .subscriptionimgbox {
    height: 18px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/view/private/Checkout/checkout.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,eAAe;EACf,gCAAgC;EAChC,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,uBAAuB;EACzB;AACF;AACA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".subscribeplaceview {\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 1.25rem;\n}\n\n.subscribeplanbutton {\n  color: #009898;\n  border: none;\n  margin: 2px 4px;\n  cursor: pointer;\n  border-bottom: 1px solid #009898;\n  display: inline-block;\n  position: relative;\n  font-size: 13px;\n}\n\n.subscribeplanbutton::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background-color: #009898;\n}\n\n@media screen and (max-width: 767px) {\n  .checkoutheading {\n    margin-top: -44px;\n    margin-bottom: 14px;\n  }\n  .subscriptionimgbox {\n    height: 18px !important;\n  }\n}\n\n.subscribeplanbox {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.subscriptionimgbox {\n  flex-shrink: 0;\n  width: 16px;\n  height: 38px;\n}\n\n.subscriptiontext {\n  flex-grow: 1;\n  margin-left: 0.5rem;\n}\n\n.subscriptiontext span {\n  white-space: nowrap;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 1.25rem;\n}\n\n.subscriptionplanbutton {\n  white-space: nowrap;\n}\n@media screen and (max-width: 383px) {\n  .subscriptionimgbox {\n    height: 40px !important;\n  }\n}\n@media screen and (min-width: 1439px){\n  .subscriptionimgbox {\n    height: 18px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
