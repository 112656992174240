// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.reviewproductlistmainbox-1 {
  display: none;
}

@media screen and (max-width: 640px) {
  .reviewproductlistmainbox {
    display: none !important;
  }

  .reviewproductlistmainbox-1 {
    display: block;
  }

  .reviewrestablehead {
    background-color: #fbf5f1;
  }

  .reviewrestable {
    width: "100%";
    border-collapse: collapse;
    font-size: 12px;
    font-family:Greycliff CF;
    font-weight: 400;
    line-height: 20.28px;
  }

  .shippingreviewproimg {
    max-width: 2.5rem;
  }
}

@media screen and (max-width: 350px) {
  .shippingreviewproimg {
    max-width:4rem;
    height: 5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/view/private/Shipping/review.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,cAAc;IACd,YAAY;EACd;AACF","sourcesContent":[".popup-title {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.reviewproductlistmainbox-1 {\n  display: none;\n}\n\n@media screen and (max-width: 640px) {\n  .reviewproductlistmainbox {\n    display: none !important;\n  }\n\n  .reviewproductlistmainbox-1 {\n    display: block;\n  }\n\n  .reviewrestablehead {\n    background-color: #fbf5f1;\n  }\n\n  .reviewrestable {\n    width: \"100%\";\n    border-collapse: collapse;\n    font-size: 12px;\n    font-family:Greycliff CF;\n    font-weight: 400;\n    line-height: 20.28px;\n  }\n\n  .shippingreviewproimg {\n    max-width: 2.5rem;\n  }\n}\n\n@media screen and (max-width: 350px) {\n  .shippingreviewproimg {\n    max-width:4rem;\n    height: 5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
