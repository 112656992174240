import React from 'react';
import { Typography } from '@mui/material';
import './popup.css';

const Nutritionist = ({ nutritionistDetails }) => {
    return (
        <>
           <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 ">
              {(nutritionistDetails?.length < 1 || nutritionistDetails === undefined) && <p className="text-sm font-articulat font-semibold">No Nutrition Details Found</p>}
            </div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-xs font-articulat font-normal text-semibold description-lists">
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: nutritionistDetails }}
                    className="dynamin-contents"
                    sx={{ fontFamily: 'Greycliff CF', margin: '0 auto' }}
                />
            </div>
        </>
    )
}

export default Nutritionist;