import React from 'react';
import './style.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedShippingRoutes from './ProtectedShippingRoutes';
import Location from "../view/public/Location";
import ProfileAccount from '../view/private/ProfileAccount';
import PrivacyPolicy from '../view/public/PrivacyPolicy';
import TermsOfSale from '../view/public/TermsOfSale';
import Reviews from '../view/public/Reviews/reviews';
import ReturnPolicy from '../view/public/ReturnPolicy';
import Blog  from '../view/public/Blog';
import BlogDetails from '../view/public/Blog/blogView';
import Checkout from '../view/private/Checkout';
import Layout from './layout';
import Shipping from '../view/private/Shipping';
import OrderReceived from '../view/private/OrderReceived';
import Nutrition from '../view/public/Nutritionist';
import Subscribe from '../view/public/Subscribe';
import OurStory  from '../view/public/OurStory';
import OrderStatus from '../view/public/OrderStatus';
import DispatchDelivery from '../view/public/DispatchDelivery';
import FAQ from '../view/public/FAQ';
import ReferFriend from '../view/public/ReferFriend';
import PageNotFound from '../view/private/PageNotFound';
import Login from '../view/public/Login';
import HomePage from '../view/public/Home';
import Product from '../view/private/Product';
import ProductDetails from '../view/private/Product/productDetails';
import Profile from '../view/private/Profile';

const App = () => {
    return (
        <Router>
         <Layout>
                    <Routes>
                        <Route path='*' exact element={<PageNotFound />} />
                        <Route path="/" exact element={<HomePage />}/>
                        <Route path="/home" exact element={<HomePage />}/>
                        <Route path="/faq" exact  element={<FAQ />}/>
                        <Route path="/shipping" element={<ProtectedShippingRoutes element={Shipping} />} />
                        <Route path="/productdetails/:category/:subcategory/:id" exact element={<ProductDetails />}/>
                        <Route path="/dispatchdelivery" exact element={<DispatchDelivery />}/>
                        <Route path="/orderreceived"  element={<OrderReceived />}/>
                        <Route path="/ourstory" exact  element={<OurStory />}/>
                        <Route path="/ourblogs" exact  element={<Blog />}/>
                        <Route path="/blogdetails" exact  element={<BlogDetails />}/>
                        <Route path="/orderstatus" exact   element={<OrderStatus />}/>
                        <Route path="/login" exact element={<Login />}/>
                        <Route path="/product/:id"  element={<Product />}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/profileaccount" exact element={<ProfileAccount/>}/>
                        <Route path="/checkout"  element={<Checkout />}/>
                        <Route path='/location' exact  element={<Location />}/>
                        <Route path='/cart' exact element={<PageNotFound />} />
                        <Route path='/help' exact element={<PageNotFound />} />
                        <Route path='/reviews' exact  element={<Reviews />}/>
                        <Route path='/nutritionistconsultation' exact element={<Nutrition/>}/>
                        <Route path='/Subscribe' exact element={<Subscribe />} />
                        <Route path='/referfriend' exact element={<ReferFriend />} />
                        <Route path='/privacypolicy' exact   element={<PrivacyPolicy />}/>
                        <Route path='/termsandconditions' exact   element={<TermsOfSale />}/>
                        <Route path='/returncancellation' exact element={<ReturnPolicy />}/>
                    </Routes>
        </Layout>
        </Router>
    );
}

export default App;
