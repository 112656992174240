import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Container } from "@mui/material";
import "./style.css";
import { fetchGetApi } from "../../../services/apis.functions/api.functions";
import { SpinLoader } from "../../../app/loader/loader";
import ApplicationHelmet from "../../../navigation/navigation";

const PrivacyPolicy = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetchGetApi('/privacy_policies', {})
            .then(res => {
                setLoading(false)
                if (res?.data?.status === "Success") {
                    setData(res?.data?.data);
                };
            })
            .catch(error => {
                return error;
            })
    }, []);

    return (
        <Container maxWidth={false} style={{ paddingTop: '150px', paddingBottom: '50px' }}>
            <ApplicationHelmet/>
            <Box>
                <Stack className="header-title">
                    <Typography variant="h5" className="termsofservice">
                        Privacy Policy
                    </Typography>
                </Stack>
                <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                    {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                </div>
                <Box className="termContentbox" marginTop={2}>
                    {data.map(content => {
                        return (
                            <Box key={content?.id}>
                                <Typography variant="body2" style={{fontFamily:'Greycliff CF' }} className="discription" dangerouslySetInnerHTML={{ __html: content?.content }} />
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
