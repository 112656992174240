import { ADD_ALL_ITEMS_TO_CART, ADD_TO_CART, REMOVE_FROM_CART, RESET_CART_ITEMS, UPDATE_CART_ITEM } from "../constants";

export const addAllItemsToCart = (product) => ({
    type: ADD_ALL_ITEMS_TO_CART,
    payload: product,
})

export const addToCart = (product) => ({
    type: ADD_TO_CART,
    payload: product,
});

export const removeFromCart = (productId, variantId, textureId) => ({
    type: REMOVE_FROM_CART,
    payload: { productId, variantId, textureId }
});

export const updateCartItem = (productId, quantity, textureId) => ({
    type: UPDATE_CART_ITEM,
    payload: { productId, quantity, textureId },
});

export const resetCartData = () => ({
    type: RESET_CART_ITEMS
});