import { FaTrash,FaCircleUser,FaEnvelope,FaDownload,FaLocationDot,FaListOl } from "react-icons/fa6";
import { BiSolidXCircle } from "react-icons/bi";
import { HiCamera } from "react-icons/hi";
import { FaStar,FaCircle,FaPlus,FaMinus,FaCalendarCheck,FaAddressCard ,FaCreditCard,FaLock } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineLogout } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp  } from "react-icons/io";
import { ImPhone } from "react-icons/im";
import { AiFillHome } from "react-icons/ai";

export const Icons = {
    FaTrash,
    BiSolidXCircle,
    HiCamera,
    FaStar,
    FaCircle,
    FaPlus,
    FaMinus,
    IoLocationSharp,
    FaCircleUser,
    FaEnvelope,
    FaCalendarCheck,
    MdOutlineLogout,
    IoIosArrowDown,
    IoIosArrowUp,
    FaDownload,
    ImPhone,
    FaLocationDot,
    AiFillHome,
    FaCreditCard,
    FaAddressCard,
    FaLock,
    FaListOl
};