import React, { useState, useRef, useEffect } from "react";
import { Typography } from '@mui/material';
import './popup.css';
import { Icons } from "../../../images/reactIcons";
import ApplicationHelmet from "../../../navigation/navigation";

const Faq = ({ faqDetails }) => {
    const [tab, setTab] = useState({ idx: null, show: false });
    const faqRefs = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tab.show && tab.idx !== null && faqRefs.current[tab.idx] && !faqRefs.current[tab.idx].contains(event.target)) {
                setTab({ idx: null, show: false });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tab]);
    return (
        <> 
            <ApplicationHelmet/>
            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 ">
                {(faqDetails?.length < 1 || faqDetails === undefined) && <p className="text-sm font-articulat font-semibold">No FAQ Found</p>}
            </div>
            {faqDetails?.length !== 0 && <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-1 text-lg font-articulat font-semibold text-center">FAQ</div>}
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                {faqDetails?.map((ele, i) => (
                    <div key={i} ref={el => faqRefs.current[i] = el} className="p-3 mt-3 font-articulat bg-[#FBF5F1] rounded-xl" >
                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12" onClick={() => setTab({ idx: i, show: !tab.show || tab.idx !== i })}>
                            <div className="lg:col-span-11 md:col-span-10 sm:col-span-9 text-sm font-bold" style={{ display: 'flex' }}>Q:&nbsp;<Typography style={{ fontWeight: 'bold', fontFamily: 'Greycliff CF' }} variant="body2" dangerouslySetInnerHTML={{ __html: ele?.title }} /></div>
                            <div
                                className="lg:col-span-1 md:col-span-2 sm:col-span-3 text-right text-lg font-semibold justify-self-end"
                                style={{ fontWeight: 'bold' }}
                            >
                                {tab.idx === i && tab?.show ? (
                                    <Icons.IoIosArrowUp
                                        onClick={() => setTab({ idx: i, show: false })}
                                        className="cursor-pointer h-6 w-6"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <Icons.IoIosArrowDown
                                        onClick={() => setTab({ idx: i, show: true })}
                                        className="cursor-pointer h-6 w-6"
                                        aria-hidden="true"
                                    />
                                )}
                            </div>
                            {tab.idx === i && tab?.show &&
                                <div className="lg:col-span-11 md:col-span-10 sm:col-span-9 text-xs font-normal" style={{ display: 'flex' }}>
                                    <span style={{ fontSize: "16px", fontWeight: "bold", lineHeight: "20px", marginRight: "2px", fontFamily: 'Greycliff CF' }}>A:</span>
                                    <Typography style={{ fontFamily: 'Greycliff CF' }} variant="body2" dangerouslySetInnerHTML={{ __html: ele?.content }} />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Faq;
