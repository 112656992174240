import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DrawerAppBar from "./navbar";
import './navbar.css';
import { navigateToNext } from "../navigation/navigation";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import { Box } from "@mui/material";
import { headerPromotion } from "../services/navbar";
import useZoomLevel from "../app/useZoomLevel";

const Navbar = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = React.useState([]);
  const zoomLevel = useZoomLevel();
  const shouldIncreaseTextSize = zoomLevel < 0.75;
  const contentRef = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [id, setId] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      const width = contentRef.current.clientWidth;
      setContentWidth(width);
    }
  }, [contentRef]);

  const handleMouseEnter = (id) => {
    setId(id);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNavigate = (e, url) => {
    handleMouseLeave();
    navigateToNext(e, url, navigate);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % data?.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [data?.length]);


  const help = () => (
    <Box
      className="items-container-box"
      onMouseEnter={() => handleMouseEnter(1)}
      onMouseLeave={handleMouseLeave}
    >
      <List
        className="items-container"
        subheader={<ListSubheader className="items-head">Help</ListSubheader>}
      >

        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/privacypolicy")}>
            <ListItemText primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  id="privacy-policy" primary="Privacy Policy" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/termsandconditions")}>
            <ListItemText id="terms-of-sale" primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }} primary="Terms and Conditions" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/orderstatus")}>
            <ListItemText id="order_status"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Order Status" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/dispatchdelivery")}>
            <ListItemText id="dispatch_delivery" primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Dispatch and Delivery" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/returncancellation")}>
            <ListItemText id="return_policy"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Return and Cancellation" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/faq")}>
            <ListItemText id="faq" primary="FAQ"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const about = () => (
    <Box
      className="items-container-box"
      onMouseEnter={() => handleMouseEnter(2)}
      onMouseLeave={handleMouseLeave}
    >
      <List
        className="items-container"
        subheader={<ListSubheader className="items-head">About</ListSubheader>}
      >
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/ourstory")}>
            <ListItemText id="our_story"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Our Story" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/ourblogs")}>
            <ListItemText id="our_blogs"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Our Blogs" />
          </ListItemButton>
        </ListItem>
        <ListItem className="items-item">
          <ListItemButton onClick={(e) => handleNavigate(e, "/reviews")}>
            <ListItemText id="reviews"  primaryTypographyProps={{ style: { color: '#000000', fontSize: '15px' } }}  primary="Reviews" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const fetchBanners = () => {
    headerPromotion().then(res => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  // Made to order, <span className="font-bold text-sm">Freshly milled flours.</span> Order before <span className="font-bold text-sm">7:00 PM</span> for day after delivery in <span className="font-bold text-sm">Hyderabad</span>.

  return (
    <nav className="bg-white shadow fixed top-0 left-0 w-full z-10">
      <div className="mx-auto space-x-4">
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 bg-[#09b5b5] h-[53px]">
          <div className={`${shouldIncreaseTextSize ? 'text-lg sm:text-xs' : 'text-sm'
            } lg:col-span-6 md:col-span-12 sm:col-span-12 p-4 ml-2 text-center lg:text-left font-articulat text-white font-normal cursor-pointer navbarmainheading sm:text-sm`} ref={contentRef}>
            <div className="header-text">
              <span
                key={currentIndex}
                style={{ color: '#f6fa00' }}
              >
                {data[currentIndex]?.title}
              </span>
            </div> 
          </div>
          <div className="lg:col-span-6 md:col-span-0 sm:col-span-0 navbarHeaderDiv p-1 font-articulat text-[#f6fa00] font-light text-right">
            <div className="relative p-1 m-2 cursor-pointer font-normal inline-block rounded-t-lg text-xs">
              <div
                className="absolute inset-0 bg-[#09b5b5] opacity-80"
                aria-hidden="true"
              ></div>
              <div
                className="relative hover:text-[#3cfa00] hover:border-[#3cfa00] dark:hover:text-[#3cfa00] text-[#f6fa00] p-1"
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                Help
                {isHovered && id === 1 && help()}
              </div>
            </div>
            <span style={{ borderRight: '1px solid #8c7575' }}></span>
            <div className="relative p-1 m-2 cursor-pointer font-normal inline-block rounded-t-lg text-xs">
              <div
                className="absolute inset-0 bg-[#09b5b5] opacity-80"
                aria-hidden="true"
              ></div>
              <div
                className="relative hover:text-[#3cfa00] hover:border-[#3cfa00] dark:hover:text-[#3cfa00] text-[#f6fa00] p-1"
                onClick={(e) => navigateToNext(e, "/nutritionistconsultation", navigate)}
              >
                Nutritionist Consultation
              </div>
            </div>
            <span style={{ borderRight: '1px solid #8c7575' }}></span>
            <div className="relative p-1 m-2 cursor-pointer font-normal inline-block rounded-t-lg text-xs">
              <div
                className="absolute inset-0 bg-[#09b5b5] opacity-80"
                aria-hidden="true"
              ></div>
              <div
                className="relative hover:text-[#3cfa00] hover:border-[#3cfa00] dark:hover:text-[#3cfa00] text-[#f6fa00] p-1"
                onClick={(e) => navigateToNext(e, "/Subscribe", navigate)}
              >
                Subscribe
              </div>
            </div>
            <span style={{ borderRight: '1px solid #8c7575' }}></span>
            <div className="relative p-1 m-2 cursor-pointer font-normal inline-block rounded-t-lg text-xs">
              <div
                className="absolute inset-0 bg-[#09b5b5] opacity-80"
                aria-hidden="true"
              ></div>
              <div
                className="relative hover:text-[#3cfa00] hover:border-[#3cfa00] dark:hover:text-[#3cfa00] text-[#f6fa00] p-1"
                onClick={(e) => navigateToNext(e, "/referfriend", navigate)}
              >
                Refer Friends
              </div>
            </div>
            <span style={{ borderRight: '1px solid #8c7575' }}></span>
            <div className="relative p-1 m-2 cursor-pointer font-normal inline-block rounded-t-lg text-xs">
              <div
                className="absolute inset-0 bg-[#09b5b5] opacity-80"
                aria-hidden="true"
              ></div>
              <div
                className="relative hover:text-[#3cfa00] hover:border-[#3cfa00] dark:hover:text-[#3cfa00] text-[#f6fa00] p-1"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                About
                {isHovered && id === 2 && about()}
              </div>
            </div>
            <span style={{ borderRight: '1px solid #8c7575' }}></span>
          </div>
        </div>
        <DrawerAppBar />
        <div className="mt-2"></div>
      </div>
    </nav>
  );
};

export default Navbar;
