// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mrgt {
    margin-right: 10px;
}

.address-box {
    padding: 20px;
    border-radius: 10px;
    background-color: #FBF5F1;
}

.cart-boxs {
    display: grid;
}

.profile-tabing {
    display: flex;
}

@media only screen and (max-width:551px) {
    .profile-tabing {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;

        li {
            text-wrap: nowrap
        }
    }
}

@media only screen and (max-width:355px) {
    .save-btn {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .cancel-btn {
        width: 100% !important;
    }
}


@media screen and (max-width:640px) {
    .profile-pic {
        display: flex;
        justify-content: center;
        margin: 0px;
        padding: 0px;
    }

    .mrgt {
        margin-right: 0px;
    }

    .btn-cont {
        display: flex;
        justify-content: space-between;
    }

    .save-btn {
        width: 48%;
    }

    .cancel-btn {
        width: 48%;
    }

}`, "",{"version":3,"sources":["webpack://./src/view/private/ProfileAccount/profile.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,iBAAiB;QACjB,kBAAkB;;QAElB;YACI;QACJ;IACJ;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,sBAAsB;IAC1B;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,uBAAuB;QACvB,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;AAEJ","sourcesContent":[".mrgt {\n    margin-right: 10px;\n}\n\n.address-box {\n    padding: 20px;\n    border-radius: 10px;\n    background-color: #FBF5F1;\n}\n\n.cart-boxs {\n    display: grid;\n}\n\n.profile-tabing {\n    display: flex;\n}\n\n@media only screen and (max-width:551px) {\n    .profile-tabing {\n        display: flex;\n        flex-wrap: nowrap;\n        overflow-x: scroll;\n\n        li {\n            text-wrap: nowrap\n        }\n    }\n}\n\n@media only screen and (max-width:355px) {\n    .save-btn {\n        width: 100% !important;\n        margin-bottom: 15px;\n    }\n\n    .cancel-btn {\n        width: 100% !important;\n    }\n}\n\n\n@media screen and (max-width:640px) {\n    .profile-pic {\n        display: flex;\n        justify-content: center;\n        margin: 0px;\n        padding: 0px;\n    }\n\n    .mrgt {\n        margin-right: 0px;\n    }\n\n    .btn-cont {\n        display: flex;\n        justify-content: space-between;\n    }\n\n    .save-btn {\n        width: 48%;\n    }\n\n    .cancel-btn {\n        width: 48%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
