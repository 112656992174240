/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import './style.css';

const OtpInput = ({ length = 4, onOtpSubmit = () => { }, onChange = () => { }, handleSubmit, resendOtp }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    onChange(newOtp)
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      handleSubmit(combinedOtp);
    };

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index) {
      inputRefs.current[index].focus();
    }
  };

  
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0) {
      if (index && !otp[index]) {
        inputRefs.current[index - 1]?.focus();
      } 
    } else if (e.key === 'Enter') {
      const combinedOtp = otp.join("");
      handleSubmit(combinedOtp);
    }
  };

  useEffect(() => {
    setOtp(new Array(length).fill(""));
    inputRefs.current[0].focus();
  }, [length, resendOtp])

  return (
    <Grid container spacing={2}>
      {otp.map((value, index) => {
        return (
          <Grid key={index.toString()} item xs={3} sm={3} md={3} lg={3} xl={3}>
            <input
              key={index}
              type="text"
              ref={(input) => (inputRefs.current[index] = input)}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="border p-1.5 w-full text-center rounded-lg font-articulat m-1 otp"
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default OtpInput;