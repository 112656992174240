// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputNameDiv{
    input{
        color: #000;
        background-color: #fff;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/TextInput/textinput.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,sBAAsB;IAC1B;AACJ","sourcesContent":[".inputNameDiv{\n    input{\n        color: #000;\n        background-color: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
