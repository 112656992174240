// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_modal {
  min-height: 230px;
  width: 450px;
}

.login_popup_logo {
  height: 5rem;
}
.login_button{
  width: 120px
}

@media screen and (max-width:400) {
  .login_popup_logo {
    height: 4rem;
  }
  .login_button{
    width: 80px ;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/login-modal/LoginModal.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;EACE;AACF;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".login_modal {\n  min-height: 230px;\n  width: 450px;\n}\n\n.login_popup_logo {\n  height: 5rem;\n}\n.login_button{\n  width: 120px\n}\n\n@media screen and (max-width:400) {\n  .login_popup_logo {\n    height: 4rem;\n  }\n  .login_button{\n    width: 80px ;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
