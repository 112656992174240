// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    padding: 15px;
    background: #FBF5F1;
    font-family: Greycliff CF;
}

.head-styl{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.head-instyl{
   border-radius: 10px;
}

.header-tile {
    font-size: 12px;
    color: #999999;
}

.header-subtitle {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    margin-top: 5px;
}

.order-d-title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
}

.order-d-stitle {
    font-size: 12px;
    font-weight: 300;
}

.order-d-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}

.order-img {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 5px;
}

.order-d-text {
    font-size: 12px;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    .product-d-text {
       flex-direction: column
    }
}`, "",{"version":3,"sources":["webpack://./src/view/private/ProfileAccount/order.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;AACA;GACG,mBAAmB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;OACG;IACH;AACJ","sourcesContent":[".header-container {\n    padding: 15px;\n    background: #FBF5F1;\n    font-family: Greycliff CF;\n}\n\n.head-styl{\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n.head-instyl{\n   border-radius: 10px;\n}\n\n.header-tile {\n    font-size: 12px;\n    color: #999999;\n}\n\n.header-subtitle {\n    font-size: 12px;\n    color: #000;\n    font-weight: 600;\n    margin-top: 5px;\n}\n\n.order-d-title {\n    font-size: 12px;\n    font-weight: 600;\n    margin-bottom: 10px;\n}\n\n.order-d-stitle {\n    font-size: 12px;\n    font-weight: 300;\n}\n\n.order-d-box {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 2px;\n}\n\n.order-img {\n    object-fit: cover;\n    height: 80px;\n    width: 80px;\n    border-radius: 5px;\n}\n\n.order-d-text {\n    font-size: 12px;\n    font-weight: 400;\n}\n\n@media only screen and (max-width: 600px) {\n    .product-d-text {\n       flex-direction: column\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
