import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddressPage from "./address";
// import Payment from './payment';
import Review from './review';
import { createOrder, createCashfreeOrder, loadScript } from '../../../services/checkout';
import { getProfileList } from '../../../services/profile';
import Loader, { SpinLoader } from '../../../app/loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchCartList } from '../../public/Home';
import LoginModal from "../../../components/login-modal";
import { availablePinCodes } from '../../../services/orders';
import { resetCartData } from '../../../reducers/cart/actions';
import { Icons } from '../../../images/reactIcons';

const Shipping = () => {
    const [tab, setTab] = useState({ home: true, payment: false, review: false });
    const [currentTab, setCurrentTab] = useState("home");
    const [loginModal, setLoginModal] = useState(false);
    const [selectAddress, setSelectAddress] = useState(null);
    const [auth, setAuth] = useState(false);
    const cartStore = useSelector(state => state.cartReducer.cart);
    const { subscription } = useSelector((state) => state.subscriptionReducer);
    const dispatch = useDispatch();
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [msg, setMsg] = useState('');
    const [loader, setLoader] = useState(false);
    const [profile, setProfile] = useState('');
    const [discount, setDiscount] = useState(null);
    const [addressList, setAddressList] = useState([]);
    const [pickUpAdd, setPicUpAdd] = useState(null);
    const [modeShippings, setModeShippings] = useState(localStorage.getItem('shippingMode') || "ship");
    const [isVisible, setIsVisible] = useState(false);
    const [available, setAvailable] = useState(false);
    const [pinCheck, setPinCheck] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const orderDeliveryDate = localStorage.getItem("orderdate");
    const total = useMemo(() =>
        cartStore?.reduce((t, val) => {
            const variant = val?.variants?.find(variant => variant.id === val.variant_id);
            const variantPrice = (variant?.discount_price !== null && parseFloat(variant?.discount_price) !== 0) ? parseFloat(variant?.discount_price)
                : parseFloat(variant?.price);
            const masterPrice = (val?.cost_price !== null && parseFloat(val?.cost_price) !== 0)
                ? parseFloat(val.cost_price)
                : parseFloat(val?.master_price) || 0;
            const price = variantPrice || masterPrice;
            return t + (price * val.quantity);
        }, 0),
        [cartStore]
    );
    const shippingcost = modeShippings === "ship" && total <= 399 ? 40 : 0.00;

    useEffect(() => {
        fetchCartList(dispatch);
        getProfile();
    }, []);

    useEffect(() => {
        (location?.state?.discount !== undefined && location?.state?.discount !== null) ? setDiscount(location?.state?.discount) : setDiscount(0);
    }, [location]);

    const getProfile = () => {
        getProfileList()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    setProfile(data)
                }
            })
            .catch((error) => {
                localStorage.removeItem('token');
                localStorage.removeItem("userId");
                localStorage.removeItem("selectedAddress");
                setAuth(false);
            });
    };

    const submitHandler = async () => {
        setLoader(true);
        const res = await loadScript("https://sdk.cashfree.com/js/v3/cashfree.js");

        if (!res) {
            throw new Error("Cashfree SDK failed to load. Are you online?");
        }

        const dis = (total * discount) / 100;

        const gTotal = total - dis + shippingcost;

        try {
            const orderData = {
                "sub_total": total,
                "grand_total": gTotal,
                "discount_amount": dis,
                "shipping_fee": shippingcost,
                "total_amount": gTotal,
                "status": "initial",
                "payment_method": null,
                "notes": `Order with ${cartStore?.length} items`
            };


            if (modeShippings === "pickup") {
                orderData["store_id"] = pickUpAdd?.id;
            } else {
                orderData["shipping_address_id"] = selectedAddress?.id;
            }

            const createdOrder = await createOrder(orderData);
            if (!(createdOrder?.order?.id) && createdOrder?.response?.data?.error === 'Invalid or expired token') {
                localStorage.removeItem('token');
                localStorage.removeItem("userId");
                localStorage.removeItem("selectedAddress");
                setAuth(false);
                setLoginModal(true);
            } else {
                const paymentData = {
                    "order_id": JSON.stringify(createdOrder?.order?.id)
                };

                const filteredItems = createdOrder?.order?.order_items.find(item => item.product.id === 42);

                if (!filteredItems || (filteredItems && parseInt(profile.phone_number) === 8146972215)) {
                    const cashfreeOrderResponse = await createCashfreeOrder(paymentData);
                    if (window.Cashfree) {
                        const cashFree = window.Cashfree(cashfreeOrderResponse);
                        localStorage.setItem('lastOrderId', JSON.stringify(createdOrder?.order?.id));
                        let checkoutOptions = {
                            paymentSessionId: cashfreeOrderResponse.payment_session_id,
                            mode: cashfreeOrderResponse.mode || "sandbox",
                            returnUrl: `${window.location.origin}/orderreceived?id=${createdOrder?.order?.id}`,
                        }
                        cashFree.checkout(checkoutOptions).then(function async(result) {
                            if (result.error) {
                                console.log(result.error.message)
                            }
                            if (result.redirect) {
                                console.log("Redirection");
                            }
                        });
                    }
                }
            }
        } catch (error) {
            setLoader(false);
            setAuth(false);
            console.error('Error occurred:', error);
        }
    };


    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            setAuth(true);
        } else {
            setAuth(false);
        };
    }, []);


    const checkPinHandler = () => {
        setMsg(null);
        setLoader(true);
        const payload = { "pin_code": selectedAddress?.postal_code };

        availablePinCodes(payload)
            .then((response) => {
                setLoader(false);
                if (response.status) {
                    // setMsg({ error: false, msg: "Delivery available!" });
                    setAvailable(true);
                    setMsg('');
                    setCurrentTab("review");
                    setTab({ ...tab, home: true, review: true, payment: false })
                } else {
                    setMsg("We apologize, but we do not offer service in your area!");
                    setAvailable(false);
                };
            })
            .catch((error) => {
                setLoader(false);
                setAvailable(false);
                if (!error?.status) {
                    setMsg("We apologize, but we do not offer service in your area!");
                } else {
                    // setMsg({ error: true, msg: "Something went wrong. Please try again!" });
                };
            });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // For smooth scrolling, if supported
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 20) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);
    
    return (<>
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 shippingmaindiv">
            <div className="lg:col-span-9 md:col-span-8 sm:col-span-12 m-1 mt-1">
                <span className='text-lg font-articulat font-semibold'>Shipping</span>
                <div className="flex justify-around m-1 mt-1 shippingiconsbox">
                    <span className={(tab.home ? "text-[#009898]" : "") + ' cursor-pointer text-2xl lg:text-left md:text-left sm:text-center lg:col-span-4 md:col-span-4 sm:col-span-4 m-4 mt-3'}
                        onClick={(e) => {
                            setCurrentTab("home");
                            setTab({ ...tab, home: true, payment: false, review: false });
                            setAvailable(false);
                        }}>
                        <Icons.AiFillHome className="h-7 w-7 shippingicons" aria-hidden="true" />
                        <span className='text-sm font-articulat font-semibold text-black'>Shipping</span>
                    </span>
                    <span className={(tab.review ? "text-[#009898]" : "") + ' cursor-pointer justify-self-center text-2xl lg:text-center md:text-center sm:text-center lg:col-span-4 md:col-span-4 sm:col-span-4 m-4 mt-3'}
                        onClick={() => {
                            if (tab.home === true && tab.review === true) {
                                setMsg('');
                                setCurrentTab("review");
                                setTab({ ...tab, home: true, payment: false, review: true });
                            };
                        }}>
                      <Icons.FaAddressCard className="h-7 w-7 ml-2" aria-hidden="true" />
                        <span className='text-sm font-articulat font-semibold text-black'>Review</span>
                    </span>
                </div>
                {currentTab === "home" && <AddressPage scrollToTop={scrollToTop} msg={msg} setModeShippings={setModeShippings} setMsg={setMsg} setPicUpAdd={setPicUpAdd} setAddressList={setAddressList} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} selectAddress={selectAddress} chengeTab={(val) => { setCurrentTab("review"); setTab({ ...tab, home: true, review: true, payment: false }) }} />}
                {currentTab === "review" && <Review selectedAddress={selectedAddress} setPinCheck={setPinCheck} setMsg={setMsg} />} 
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-12">
                <div className='bg-[#000000] text-[#FF0000] rounded text-sm p-2 font-articulat font-semibold shippingfreedelivery'>Free delivery above Rs 399</div>
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-2">
                    <div className='shippingdeliveryboxdivide'>
                        <span className='text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8'>Subtotal</span>
                        <span className='text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4'>₹{total}</span>
                    </div>
                    <div className='shippingdeliveryboxdivide'>
                        <span className='text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-2'>Discount {discount ? `Flat(${discount}%)` : null}</span>
                        <span className='text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-2'>{`₹ ${(total * discount) / 100}`} </span>
                    </div>
                    <div className='shippingdeliveryboxdivide'>
                        <span className='text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-2'>Total</span>
                        <span className='text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-2'>₹ {total - ((total * discount) / 100)}</span>
                    </div>
                    <div className='shippingdeliveryboxdivide'>
                        <span className='text-sm font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-2'>Shipping Cost</span>
                        <span className='text-sm font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-2'>{`₹ ${shippingcost}`}</span>
                    </div>
                    {subscription ?
                        <div className="shippingdeliveryboxdivide discountbox">
                            <span className="text-sm font-articulat font-semibold lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 checkoutenterdisc">
                                Subscription
                                <span className="text-xs font-articulat font-normal text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#999999]">
                                    ({(subscription?.name).trim()})
                                </span>
                            </span>
                            <span className="text-xs font-articulat font-semibold text-right lg:col-span-6 md:col-span-6 sm:col-span-6 mt-2 text-[#000]">
                                {subscription?.price}
                            </span>
                        </div>
                        :
                        null
                    }
                    <div className='shippingdeliveryboxdivide'>
                        <span className='text-lg font-articulat font-semibold lg:col-span-8 md:col-span-8 sm:col-span-8 mt-3'>Grand Total</span>
                        <span className='text-lg font-articulat font-semibold text-right lg:col-span-4 md:col-span-4 sm:col-span-4 mt-3'>₹ {(total - ((total * discount) / 100)) + (subscription?.price ? parseInt(subscription?.price) : 0) + shippingcost}</span>
                    </div>
                    <button className={`lg:col-span-12 md:col-span-12 sm:col-span-12 text-white rounded text-sm p-3 font-articulat font-semibold text-center cursor-pointer focus:ring-blue-200 dark:focus:ring-[#009898] dark:ring-offset-blue-800 focus:ring-2 dark:bg-blue-700 dark:border-blue-600 shippingproceedbutton ${cartStore?.length > 0 ? 'buttoncommon' : 'bg-[#66baba]'}`}
                        onClick={() => {
                            if (auth) {
                                if (modeShippings === "pickup") {
                                    if (currentTab === "home") {
                                        setMsg('');
                                        setCurrentTab("review");
                                        setTab({ ...tab, home: true, review: true})
                                    } else if (currentTab === "review") {
                                        submitHandler();
                                    } 
                                } else {
                                    if (addressList.length === 0) {
                                        setMsg('Please add a shiping address!')
                                    } else if (selectedAddress === null) {
                                        setMsg('Please select delivery address!')
                                    } else if (selectedAddress !== null && currentTab === "home") {
                                        checkPinHandler();
                                        if (available) {
                                            setMsg('');
                                            setCurrentTab("review");
                                            setTab({ ...tab, home: true, review: true, payment: false })
                                        };
                                    } else if (selectedAddress !== null && currentTab === "review") {
                                        if (!pinCheck) {
                                            submitHandler();
                                        } else {
                                            setMsg('First remove unavailable products from cart.');
                                        };
                                    }
                                };
                            } else {
                                setLoginModal(true);
                                dispatch(resetCartData());
                            }
                        }}
                        disabled={cartStore?.length > 0 ? false : true}
                    >

                        <span>{loader ? <SpinLoader height="20px" width="20px" /> : currentTab === 'review' ? 'Make a Payment ' : 'Proceed'}</span>
                    </button>
                    {msg !== '' && <div style={{ margin: "10px 2px", color: "red", fontWeight: "bold" }} className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-normal color:red">{msg}</div>}
                </div>
                {orderDeliveryDate && <div className='flex text-sm justify-center items-center h-12'>
                    Your order will be delivered on {orderDeliveryDate}
                </div>}
                <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} navigate={navigate} />
            </div>
        </div>
        {loader && <Loader />}
    </>
    )
}

export default Shipping;
