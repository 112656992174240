import { useState, useEffect } from "react";
import { updateProfile } from "../../../services/profile";
import { checkAuth } from "../../../app/checkAuth";
import ProfileInput from "../../../components/Shared/ProfileInput";
import TextInput from "../../../components/Shared/TextInput";
import TextInputWithIcon from "../../../components/Shared/TextInputWithIcon";
import SelectInput from "../../../components/Shared/SelectInput";
import { DateFormatter } from "../../../components/Shared/date_formatter";
import './profile.css';
import images from "../../../images/images";
import { SpinLoader } from "../../../app/loader/loader";
import { toast } from 'react-toastify';
import { Icons } from "../../../images/reactIcons";

const AccountInfo = (props) => {
    const [profile, setProfile] = useState({});
    const [profileImge, setProfileImge] = useState(null);
    const [loader, setLoader] = useState(false);
    const [edit, setEdit] = useState(false);

    const submitHandler = (val) => {
        if (val) {
            let error = false;
            const formData = new FormData();
            if (profile.title && profile.title !== "Title") {
                formData.append("user[title]", profile.title);
            } else {
                setProfile(prevProfile => ({ ...prevProfile, title_error: true }));
                error = true;
            }

            if (profile.first_name) {
                formData.append("user[first_name]", profile.first_name);
            } else {
                setProfile(prevProfile => ({ ...prevProfile, first_name_error: true }));
                error = true;
            }
            const isEmail = emailValidate(profile.email);
            if (isEmail) {
                formData.append("user[email]", profile.email);
            } else {
                setProfile(prevProfile => ({ ...prevProfile, email_error: true }));
                error = true;
            }

            if (!error) {
                setLoader(true);

                if (profile.last_name || profile.last_name === '') formData.append("user[last_name]", profile.last_name);
                if (profileImge && typeof profileImge === "object") formData.append("user[attachment]", profileImge);
                if (profile.dob) formData.append("user[dob]", profile.dob);
                if (profile.doa) formData.append("user[doa]", profile.doa);
                if (profile.state) formData.append("user[state]", profile.state);
                if (profile.pin_code) formData.append("user[pin_code]", profile.pin_code);
                if (profile.country) formData.append("user[country]", profile.country);
                if (profile.district) formData.append("user[district]", profile.district);

                updateProfile(formData)
                    .then((response) => {
                        setLoader(false);
                        if (response.success && response.receiveObj) {
                            toast.success("Your profile has been updated successfully.");
                            setEdit(!edit);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoader(false);
                        toast.error("Something went wrong. Please try again!");
                    });
            }
        } else {
            setEdit(!edit);
        }
    };

    const maskedNumber = (number) => {
        let numberStr = number?.toString();
        if (numberStr?.length >= 10) {
            let maskedNumber = "******" + numberStr.slice(-4);
            return maskedNumber;
        } else {
            return numberStr;
        }
    }

    const emailValidate = (email) => {
        let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/
        if (email?.match(pattern) && email) {
            return true;
        } else {
            return false;
        };
    };

    const handleProfile = (e) => {
        setProfileImge(e.target.files[0]);
    };

    useEffect(() => {
        checkAuth();
        setProfile(props.profile);
        setProfileImge(props?.profile?.attachment_url)
    }, [props.profile]);

    return (
        <>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold mt-4">Account Infomation</div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-lg font-articulat font-semibold mt-2">
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                    <div className="profile-pic lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-12 mt-3 text-lg p-2 font-articulat font-semibold"><ProfileInput disabled={!edit} defaultValue={profileImge} onChange={handleProfile} /></div>
                    <div className="lg:col-span-10 md:col-span-10 sm:col-span-10 xs:col-span-12 mt-3">
                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                            <div className="lg:col-span-2 md:col-span-2 sm:col-span-2 my-1 mrgt">
                                <SelectInput
                                    onChange={(e) => setProfile({ ...profile, title: e.target.value, title_error: false })}
                                    defaultValue={profile.title ? profile.title : ""}
                                    type="text"
                                    id="title"
                                    disabled={!edit}
                                    placeholder="Enter Title"
                                    isLabelShow={true}
                                    showLabel="Title"
                                    options={["Title", "Mr.", "Mrs.", "Ms.", "Other"]}
                                    error={profile.title_error}
                                />
                                {profile.title_error && <span className="text-sm font-normal text-[#FF0000]">Title is Required.</span>}
                            </div>
                            <div className="lg:col-span-5 md:col-span-5 sm:col-span-5 my-1 mrgt">
                                <TextInput
                                    onChange={(e) => setProfile({ ...profile, first_name: e.target.value, first_name_error: false })}
                                    value={profile.first_name ? profile.first_name : ""}
                                    type="text"
                                    id="first_name"
                                    disabled={!edit}
                                    errorMessage="First Name is Required."
                                    placeholder="Enter First Name"
                                    isLabelShow={true}
                                    showLabel="First Name"
                                    error={profile.first_name_error}
                                />
                                {profile.first_name_error && <span className="text-sm font-normal text-[#FF0000]">First Name is Required.</span>}
                            </div>
                            <div className="lg:col-span-5 md:col-span-5 sm:col-span-5 my-1">
                                <TextInput
                                    onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
                                    value={profile.last_name !== "undefined" ? profile.last_name : ""}
                                    type="text"
                                    id="last_name"
                                    disabled={!edit}
                                    errorMessage="Last Name is Required."
                                    placeholder="Enter Last Name"
                                    isLabelShow={true}
                                    showLabel="Last Name"
                                    error={profile.lastNameError}
                                />
                            </div>
                        </div>
                        <TextInputWithIcon
                            onChange={(e) => setProfile({ ...profile, email: e.target.value, email_error: false })}
                            defaultValue={profile.email ? profile.email : ""}
                            icon={<Icons.FaEnvelope/>}
                            type="text"
                            id="email"
                            disabled={!edit}
                            placeholder="Enter Email Address"
                            isLabelShow={true}
                            showLabel="Email"
                            error={profile.email_error}
                            errorMessage="Email Address is Required."
                        />
                        {profile.email_error && <span className="text-sm font-normal text-[#FF0000]">Please Enter Valid Email .</span>}

                        <span className="block mb-2 font-sm font-articulat font-semibold text-xs mt-3">Phone</span>
                        <div className="flex lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 border-gray-200 border rounded-md mt-3 p-1 bg-[#FBF5F1]">
                            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3 text-xs font-articulat font-normal p-1.5 text-center">{profile.country_code}</div>
                            <div className="lg:col-span-10 md:col-span-6 sm:col-span-6 text-xs font-articulat font-normal block w-full p-1.5">{maskedNumber(profile?.phone_number)}</div>
                            <div className="lg:col-span-1 md:col-span-3 sm:col-span-3 text-xs font-articulat  font-normal p-1.5 text-right"><img src={images.right} loading="lazy" className="object-cover" alt="profile-rightImage"/></div>
                        </div>
                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 my-1 mrgt">
                                <TextInputWithIcon
                                    defaultValue={DateFormatter(profile.doa)}
                                    onChange={(e) => setProfile({ ...profile, doa: e.target.value })}
                                    icon={<Icons.FaCalendarCheck/>}
                                    type="date"
                                    id="doa"
                                    disabled={!edit}
                                    placeholder="DD-MM-YYYY"
                                    isLabelShow={true}
                                    showLabel="Date of Anniversary"
                                    error={false}
                                />
                            </div>
                            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 my-1">
                                <TextInputWithIcon
                                    defaultValue={DateFormatter(profile.dob)}
                                    onChange={(e) => setProfile({ ...profile, dob: e.target.value })}
                                    icon={<Icons.FaCalendarCheck/>}
                                    type="date"
                                    id="dob"
                                    disabled={!edit}
                                    placeholder="Enter Date of birth"
                                    isLabelShow={true}
                                    showLabel="Date of birth"
                                    error={false}
                                />
                            </div>
                        </div>
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 btn-cont">
                            <button
                                className="save-btn mr-3 select-none w-28 rounded-md font-articulat buttoncommon text-center text-xs text-white p-3 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                type="button" onClick={() => submitHandler(edit)}>
                                {loader ? <SpinLoader height="20px" width="20px"/> : (!edit ? "Edit" : "Save")}
                            </button>
                            {edit &&
                                <button
                                    className="cancel-btn select-none border  w-28 rounded-md text-[#FF0000] font-articulat bg-white text-center text-xs p-3 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                    type="button" onClick={() => setEdit(!edit)}>
                                    Cancel
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AccountInfo;