import TextInput from "../../../components/Shared/TextInput";
import TextInputWithIcon from "../../../components/Shared/TextInputWithIcon";
import { useState, useEffect } from "react";
import {
  getAddressList,
  getStoresAddressList,
  setAddressList
} from "../../../services/shipping";
import "./style.css";
import NoDataFound from "../../../services/utils/noDataPopUp";
import { getProfileList } from "../../../services/profile";
import { SpinLoader } from "../../../app/loader/loader";
import { toast } from 'react-toastify';
import { Icons } from "../../../images/reactIcons";
import ApplicationHelmet from "../../../navigation/navigation";

const AddressPage = (props) => {
  const [addressForm, setAddressForm] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone_number: "",
    address_line_1: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    country_code: "+91",
    default: false
  });
  const [errors, setErrors] = useState([]);
  const [modeShipping, setModeShipping] = useState(localStorage.getItem('shippingMode') || "ship");
  const [address, setAddress] = useState([]);
  const [newAddress, setNewAddress] = useState(false);
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState({});
  const userId = localStorage.getItem('userId');
  const [pickUpList, setPicUpList] = useState([]);

  const redirectToMapByAddress = (address) => {
    window.open(`https://www.google.com/maps/search/+${address}/`, "_blank");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone_number') {
      const phoneNumber = value.replace(/\D/g, '');
      if (phoneNumber?.length > 10) {
        setAddressForm({ ...addressForm, [name]: phoneNumber.slice(0, 10) });
      } else {
        setAddressForm({ ...addressForm, [name]: phoneNumber });
      }
      if (phoneNumber.length < 10) {
        setErrors({ ...errors, [name]: "Phone Number must be 10 digits." });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    } else {
      setAddressForm({ ...addressForm, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      first_name,
      mail,
      phone_number,
      address_line_1,
      postal_code,
      city,
      state,
      country
    } = addressForm;

    const newErrors = {};

    if (first_name.trim() === "") {
      newErrors.first_name = "First Name is required.";
    }

    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!mailRegex.test(mail)) {
      newErrors.mail = "Please enter a valid email address.";
    }

    if (phone_number === "") {
      newErrors.phone_number = "Please enter phone number.";
    }

    if (address_line_1 === "") {
      newErrors.address_line_1 = "Address is required.";
    }

    if (postal_code === "") {
      newErrors.postal_code = "Please enter a valid pin code.";
    }

    if (city === "") {
      newErrors.city = "District is required.";
    }

    if (state === "") {
      newErrors.state = "State is required.";
    }

    if (country === "") {
      newErrors.country = "Country is required.";
    }

    // Set errors if any
    setErrors(newErrors);

    // If no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      const payloadData = {
        shipping_address: { ...addressForm, user_id: profile?.id }
      };

      setAddressList(payloadData)
        .then((response) => {
          setLoader(false);
          if (response.success && response.receiveObj) {
            getAllAddress();
            setNewAddress(false);
            toast.success("Address added successfully!");
            props.scrollToTop();
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Unable to add address!");
        });
    }
  };

  const getProfile = () => {
    getProfileList()
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj;
          setProfile(data);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const getAllAddress = () => {
    getAddressList()
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj;
          data?.map((val) => {
           const selectedValue = localStorage.getItem('selectedAddress');
           if(selectedValue) {
            props.setSelectedAddress(JSON.parse(selectedValue));
           } else {
            if (val.default === true) {
              props.setSelectedAddress(val);
            }
          }
          });
          props.setAddressList(data);
          setAddress(data);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const getAllPickUpAddress = () => {
    getStoresAddressList()
      .then((response) => {
        if (response.success && response.receiveObj) {
          const data = response.receiveObj.data;
          props.setPicUpAdd(data[0]);
          setPicUpList(data);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    getProfile();
    getAllAddress();
    getAllPickUpAddress();
    if (props.selectAddress) {
      props.selectAddress.type === "ship" &&
        props.setSelectedAddress(props.selectAddress.address);
      setModeShipping(props.selectAddress ? props.selectAddress.type : "ship");
    }
  }, []);

  return (
    <>
      <ApplicationHelmet/>
      <div className="text-lg font-articulat font-semibold mt-3">Delivery</div>
      <div className="text-sm font-articulat font-normal mt-1">
        is this address you'd like to use display below? If so, click the
        corresponding “Deliver to this address” button. or you can enter a new
        address.
      </div>
      <div className="gap-10 grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 shippingradiobox">
        <div className="inline-flex items-center bg-[#FBF5F1] rounded-lg lg:col-span-6 md:col-span-6 sm:col-span-6 m-1 mt-3">
          <label
            className="relative flex items-center p-3 rounded-full cursor-pointer"
            htmlFor="html"
          >
            <input
              name="type"
              type="radio"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-[#009898] before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-[#009898] hover:before:opacity-10"
              id="html"
              checked={modeShipping === "ship"}
              onChange={(e) => {
                setModeShipping(e.target.checked && "ship");
                props.setModeShippings(e.target.checked && "ship");
                localStorage.setItem("shippingMode", "ship");
                props.setMsg("");
              }}
            />
            <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
          </label>
          <label
            className="mt-px text-gray-700 cursor-pointer select-none text-sm font-articulat font-semibold"
            htmlFor="html"
          >
            Ship
          </label>
        </div>
        <div className="inline-flex items-center bg-[#FBF5F1] rounded-lg lg:col-span-6 md:col-span-6 sm:col-span-6 m-1 mt-3">
          <label
            className="relative flex items-center p-3 rounded-full cursor-pointer"
            htmlFor="react"
          >
            <input
              name="type"
              type="radio"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-[#009898] before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-[#009898] hover:before:opacity-10"
              id="react"
              checked={modeShipping === "pickup"}
              onChange={(e) => {
                setModeShipping(e.target.checked && "pickup");
                props.setModeShippings(e.target.checked && "pickup");
                localStorage.setItem("shippingMode", "pickup");
                props.setMsg("");
              }}
            />
            <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
          </label>
          <label
            className="mt-px text-gray-700 cursor-pointer select-none text-sm font-articulat font-semibold"
            htmlFor="react"
          >
            Pick up from store
          </label>
        </div>
       </div>
      {address.length !== 0 && modeShipping === "ship" && (
        <div className="text-lg font-articulat font-semibold mt-3">
          Select a delivery address
        </div>
      )}
      {address.length !== 0 && modeShipping === "ship" && (
        <div className="text-sm font-articulat font-normal mt-1">
          is this address you'd like to use display below? If so, click the
          corresponding “Deliver to this address” button. or you can enter a new
          address.
        </div>
      )}
      {modeShipping === "pickup" && (
        <div className="text-lg font-articulat font-semibold mt-3 pickupboxheading">
          Pickup locations
        </div>
      )}
      {modeShipping === "pickup" && (
        <div className="text-sm font-articulat font-normal mt-1 pickupboxheading">
          There is 1 store with stock close to your location.
        </div>
      )}
      {modeShipping === "ship" ? (
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
          {address.length !== 0
            ? address.map((ele, i) => {
              return (
                <div key={i} className="lg:col-span-5 md:col-span-6 sm:col-span-12 font-articulat mt-2 bg-[#FBF5F1] p-3  rounded-lg shippingaddressdetails">
                  <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                    <div className="addresstitleinfo">
                      <div className="lg:col-span-11 md:col-span-10 sm:col-span-11 text-sm font-semibold">
                        {(ele?.title ? ele?.title : "") +
                          " " +
                          (ele?.first_name ? ele?.first_name : "") +
                          " " +
                          (ele?.last_name ? ele?.last_name : "")}
                      </div>
                      <div className="lg:col-span-1 md:col-span-2 sm:col-span-1 text-xl">
                        <div className="flex items-right mb-4">
                          <label className="container">
                            <input
                              type="radio"
                              name="radio"
                              checked={ele?.id === props.selectedAddress?.id}
                              onChange={(e) => {
                                props.setSelectedAddress(
                                  e.target.checked ? ele : null)
                                  localStorage.setItem('selectedAddress', JSON.stringify(ele))
                                }
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-normal">
                      {(ele?.address_line_1 ? ele?.address_line_1 : "") +
                        " " +
                        (ele?.address_line_2 ? ele?.address_line_2 : "") +
                        " " +
                        (ele?.city ? ele?.city : "") +
                        " " +
                        (ele?.state ? ele?.state : "") +
                        " " +
                        (ele?.country ? ele?.country : "") +
                        " " +
                        (ele?.postal_code ? ele?.postal_code : "")}
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-normal">
                      {ele?.country_code + "-" + ele?.phone_number}
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xs font-normal">
                      {ele?.mail}
                    </div>
                  </div>
                </div>
              );
            })
            : !newAddress && <NoDataFound />}
          <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
              {!newAddress && (
                <button
                  className={
                    (newAddress ? "bg-[#ccc]" : "buttoncommon") +
                    " m-1 mt-1 text-white rounded text-sm p-3 font-articulat font-semibold text-center cursor-pointer lg:col-span-4 md:col-span-4 sm:col-span-4 focus:ring-blue-200 dark:focus:ring-[#009898] dark:ring-offset-blue-800 focus:ring-2 dark:bg-blue-700 dark:border-blue-600"
                  }
                  onClick={() => {
                    setNewAddress((e) => !e);
                    props.setMsg("");
                  }}
                >
                  Add a New Address
                </button>
              )}
            </div>
          </div>
          {newAddress && (
            <>
              <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 p-1">
                <hr />
              </div>
              <div className="text-sm font-articulat font-semibold mt-3 lg:col-span-12 md:col-span-12 sm:col-span-12 p-1">
                Add a new delivery address
              </div>
              <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 border rounded-xl p-5">
                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3">
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 gap-4 gap-y-0">
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-6">
                        <TextInput
                          onChange={handleChange}
                          defaultValue={addressForm.first_name}
                          type="text"
                          id="first_name"
                          name="first_name"
                          errorMessage="First Name is Required."
                          placeholder="Enter First Name"
                          isLabelShow={true}
                          showLabel="First Name"
                        />
                        {errors.first_name && (
                          <span className="text-red-500 text-xs">
                            {errors.first_name}
                          </span>
                        )}
                      </div>
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-6">
                        <TextInput
                          onChange={handleChange}
                          defaultValue={addressForm.last_name}
                          type="text"
                          id="last_name"
                          name="last_name"
                          errorMessage="Last Name is Required."
                          placeholder="Enter Last Name"
                          isLabelShow={true}
                          showLabel="Last Name"
                          error={addressForm.lastNameError}
                        />
                      </div>
                    </div>
                    <TextInputWithIcon
                      onChange={handleChange}
                      defaultValue={addressForm.mail}
                      icon={<Icons.FaEnvelope/>}
                      type="text"
                      id="mail"
                      name="mail"
                      placeholder="Enter email address"
                      isLabelShow={true}
                      showLabel="Email"
                    />
                    {errors.mail && (
                      <span className="text-red-500 text-xs">
                        {errors.mail}
                      </span>
                    )}
                    <TextInputWithIcon
                      onChange={handleChange}
                      defaultValue={addressForm.phone_number}
                      icon={<Icons.ImPhone />}
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      pattern="[0-9]*"
                      maxLength="10"
                      placeholder="Enter Phone Number"
                      isLabelShow={true}
                      showLabel="Phone Number"
                      errorMessage="Phone Number is Required."
                    />
                    {errors.phone_number && (
                      <span className="text-red-500 text-xs">
                        {errors.phone_number}
                      </span>
                    )}
                    <TextInputWithIcon
                      onChange={handleChange}
                      defaultValue={addressForm.address_line_1}
                      icon={
                        <Icons.FaLocationDot/>
                      }
                      type="text"
                      id="address_line_1"
                      name="address_line_1"
                      placeholder="Enter Address"
                      isLabelShow={true}
                      showLabel="Address"
                      errorMessage="Address is Required."
                    />
                    {errors.address_line_1 && (
                      <span className="text-red-500 text-xs">
                        {errors.address_line_1}
                      </span>
                    )}
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 gap-y-0 gap-5">
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12">
                        <TextInputWithIcon
                          onChange={handleChange}
                          defaultValue={addressForm.postal_code}
                          icon={
                            <Icons.FaLocationDot/>
                          }
                          type="text"
                          id="postal_code"
                          name="postal_code"
                          pattern="[0-9]*"
                          maxlength="6"
                          value={addressForm?.postal_code}
                          placeholder="Enter Pin Code"
                          isLabelShow={true}
                          showLabel="Pin Code"
                          errorMessage="Pin Code is Required."
                          error={addressForm.pinCodeError}
                        />
                        {errors.postal_code && (
                          <span className="text-red-500 text-xs">
                            {errors.postal_code}
                          </span>
                        )}
                      </div>
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12">
                        <TextInputWithIcon
                          onChange={handleChange}
                          defaultValue={addressForm.city}
                          icon={
                          <Icons.FaLocationDot/>
                          }
                          type="text"
                          id="city"
                          name="city"
                          placeholder="Enter District"
                          isLabelShow={true}
                          showLabel="District"
                          errorMessage="District is Required."
                          error={addressForm.districtError}
                        />
                        {errors.city && (
                          <span className="text-red-500 text-xs">
                            {errors.city}
                          </span>
                        )}
                      </div>
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12">
                        <TextInputWithIcon
                          onChange={handleChange}
                          defaultValue={addressForm.state}
                          icon={
                            <Icons.FaLocationDot/>
                          }
                          type="text"
                          id="state"
                          name="state"
                          placeholder="Enter State"
                          isLabelShow={true}
                          showLabel="State"
                          errorMessage="State is Required."
                          error={addressForm.stateError}
                        />
                        {errors.state && (
                          <span className="text-red-500 text-xs">
                            {errors.state}
                          </span>
                        )}
                      </div>
                      <div className="lg:col-span-6 md:col-span-6 sm:col-span-12">
                        <TextInputWithIcon
                          onChange={handleChange}
                          defaultValue={addressForm.country}
                          icon={
                           <Icons.FaLocationDot/>
                          }
                          type="text"
                          id="country"
                          name="country"
                          placeholder="Enter Country"
                          isLabelShow={true}
                          showLabel="Country"
                          errorMessage="Country is Required."
                          error={addressForm.countryError}
                        />
                        {errors.country && (
                          <span className="text-red-500 text-xs">
                            {errors.country}
                          </span>
                        )}
                      </div>

                      <div
                        className="lg:col-span-6 md:col-span-6 sm:col-span-12 mt-3 mb-3"
                        style={{ display: "flex" }}
                      >
                        <input
                          style={{
                            marginRight: "10px",
                            accentColor: "#009898"
                          }}
                          type="checkbox"
                          id="default"
                          name="default"
                          value={addressForm.default}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "default",
                                value: e.target.checked
                              }
                            })
                          }
                        />
                        <label style={{ fontSize: "12px" ,fontFamily:'Greycliff CF'}} for="default">
                          Make default address.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-center">
                    <button
                      className="mr-3 select-none w-24 rounded-lg font-articulat buttoncommon text-center text-xs text-white p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                      type="button"
                      onClick={(e) => { loader === false && handleSubmit(e) }}
                    >
                      {loader ? <SpinLoader height="20px" width="20px"/> : 'Add address'}
                    </button>
                    <button
                      className="mr-3 select-none w-24 rounded-lg font-articulat buttoncommon text-center text-xs text-white p-2 hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                      type="button"
                      onClick={() => setNewAddress((e) => !e)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 bg-[#FBF5F1] rounded-xl p-2">
          <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
            <div className="shippingdeliveryboxdivide">
              <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 text-sm font-articulat font-semibold mt-2">
                {pickUpList[0]?.name}, {pickUpList[0]?.city}
              </div>
              <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 text-sm font-articulat font-semibold mt-2 text-right">
                Free
              </div>
            </div>
            <div className="pickuplocationbox">
              <div
                className="lg:col-span-6 md:col-span-6 sm:col-span-6 text-xs font-articulat font-normal mt-2 text-[#009898] underline cursor-pointer"
                onClick={() =>
                  redirectToMapByAddress(
                    `${pickUpList[0]?.address_line_1}, ${pickUpList[0]?.address_line_2}, ${pickUpList[0]?.city}, ${pickUpList[0]?.state} - ${pickUpList[0]?.postal_code}`
                  )
                }
              >{`${pickUpList[0]?.address_line_1}, ${pickUpList[0]?.address_line_2}, ${pickUpList[0]?.city}, ${pickUpList[0]?.state}(${pickUpList[0]?.country}) - ${pickUpList[0]?.postal_code}`}</div>
              <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 text-xs font-articulat font-normal text-[#FF0000] mt-2 text-right cursor-pointer">
                Usually ready in 24 hours
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressPage;
