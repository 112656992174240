import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getBlogShow } from "../../../services/footer";
import { DateFormatter } from "../../../components/Shared/date_formatter";
import './blogview.css'
import { SpinLoader } from "../../../app/loader/loader";
const BlogDetails = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => search.substring(4, search.length), [search]);
    const [blog, setBlog] = useState({});
    const [blogrecent, setBlogRecent] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const getBlog = (id) => {
        getBlogShow(id)
            .then((response) => {
                setLoading(false)
                if (response.success && response.receiveObj) {
                    const data = response?.receiveObj;
                    setBlog(data?.blog);
                    setBlogRecent(data?.recent_blogs)
                };
            })
            .catch((error) => {
                setBlog({});
                setBlogRecent([])
            });
    };

    useEffect(() => {
        setLoading(true)
        if (queryParams) {
            getBlog(queryParams);
        }
    }, [queryParams]);

    const redirectPage = (url) => {
        navigate(url);
    };


    return (
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-4 mt-32">
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-2xl p-2 mt-4 font-articulat font-semibold blogdetailsheading">Blog Detail</div>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm p-2 font-articulat font-normal cursor-pointer">
                <span onClick={() => redirectPage('/home')}>Home</span> /
                <span className="text-[#009898]">Blog Detail</span>
            </div>
            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
            </div>
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-12 p-2 mt-4">
                <img src={blog?.image?.url} className="cursor-pointer mt-2 rounded-xl w-full h-auto blogdetailsmainimg" alt="blogImage" loading="lazy"/>
                <div className="text-2xl font-articulat font-semibold mt-5 blogdetailsheading">{blog.title}</div>
                <div className=" text-xs font-articulat font-light text-gray-500 mt-5">{DateFormatter(blog.created_at, 'LL')}</div>
                <div className=" text-sm font-articulat font-normal mt-5 blogdescriptionbox" dangerouslySetInnerHTML={{ __html: blog.description }}></div>
            </div>


            <div className="lg:col-span-4 md:col-span-4 sm:col-span-12 p-2 mt-5 ml-2">
                <div className="text-sm font-articulat font-semibold underline">Recent Blogs</div>
                {
                    blogrecent && blogrecent.map((item) => {
                        return (
                            <div className="grid lg:grid-cols-12 md:grid-cols-12 mt-4" key={item?.id}>
                                <div className="recentblogdetails">
                                    <img src={item?.image?.url} alt="blogItemImage" className="cursor-pointer mt-2 rounded-xl h-28 lg:col-span-4 md:col-span-5 sm:col-span-5 recentlyimgblog" onClick={() => redirectPage('/blogdetails?id=' + item.id)} loading="lazy" />
                                    <span className="lg:col-span-8 md:col-span-7 sm:col-span-7 ml-4">
                                        <div className="text-sm font-articulat font-semibold mt-2 recentblogheadingsize" onClick={() => redirectPage('/blogdetails?id=' + item.id)}>{item.title}</div>
                                        <div className=" text-xs font-articulat font-light text-gray-500 mt-2 recentblogdatebox">{DateFormatter(item.created_at, 'LL')}</div>
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="mt-9"></div>
        </div>
    )
}
export default BlogDetails;