import { useEffect, useState } from "react";
import HomeSubscribe from "../Home/homeSubsrice";
import { getBlogList } from "../../../services/footer";
import { notificationService } from '../../../services/notifications/notificationService';
import { DateFormatter } from "../../../components/Shared/date_formatter";
import './blogview.css'
import { useNavigate } from "react-router-dom";
import { SpinLoader } from "../../../app/loader/loader";
import ApplicationHelmet from "../../../navigation/navigation";

const Blog = () => {
    const [blog, setBlog] = useState([]);
    const [currentSubName, setCurrentSubName] = useState("");
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const getBlog = (payload = {}) => {
        getBlogList(payload)
            .then((response) => {
                if (response.success && response.receiveObj) {
                    setLoading(false)
                    const data = response.receiveObj;
                    setBlog(data);
                }
            })
            .catch((error) => {
                setBlog([]);
                notificationService.sendMessage({ type: "error", title: "Blog List", text: error.receiveObj.message });
            });
    }
    const redirectPage = (url) => {
        navigate(url);
    }
    useEffect(() => {
        setLoading(true)
        getBlog();
    }, []);

    const setSearchHandler = (id) => {
        const payload = {
            "category_id": id
        };
        setLoading(true)
        getBlog(payload);
    };

    return (
        <> 
            <ApplicationHelmet/>
            <div className="grid lg:grid-cols-12 md:grid-cols-12 lg:gap-x-[114px] md:gap-x-16 sm:gap-x-12 sm:grid-cols-12 p-4 mt-36">
                <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                    {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}
                </div>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-xl p-2 font-articulat font-semibold cursor-pointer">Blog</div>
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm p-2 mt-2 font-articulat font-semibold cursor-pointer">
                <span onClick={() => redirectPage('/home')}>Home</span> /
                <span className="text-[#009898]">Blogs</span>
                </div>
                <div className="lg:col-span-8 md:col-span-6 sm:col-span-6 lg:order-1  md:order-1 sm:order-1 xxs:order-2 sm:mt-0 xxs:mt-8">
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 mt-5">
                        {blog?.blogs?.map(ele =>
                            <div className="lg:col-span-6 md:col-span-12 sm:col-span-12 m-2 rounded-xl responsiveblogs" key={ele?.id}>
                                <img src={ele?.image?.url} alt="blogImage" className="w-full cursor-pointer ourblogimgdiv" onClick={() => redirectPage('/blogdetails?id=' + ele.id)} loading="lazy" />
                                <div className="text-sm p-2 font-articulat font-semibold cursor-pointer" onClick={() => redirectPage('/blogdetails?id=' + ele.id)}>{ele.title}</div>
                                <div className="text-xs ml-2 font-articulat font-normal text-gray-500 cursor-pointer">{DateFormatter(ele.created_at, 'LL')}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="lg:col-span-3 md:col-span-5 sm:col-span-6 xs:col-span-6 p-2 lg:w-80 blogsyoumaybox mt-8 lg:order-2 md:order-2 sm:order-2 sm:overflow-hidden xxs:order-1 xxs:overflow-y-scroll">
                    <div style={{ textAlign: 'center' }} className="mt-2 text-xl font-articulat font-semibold mb-1 blogsyoumay">
                        You May Also Like
                    </div>
                    <div className="flex justify-center mb-2 items-center">
                        <div className="bg-[#003300] h-1 w-32">

                        </div>
                    </div>
                    <div className="flex flex-col p-2 mb-2">
                        {blog?.categories?.map((ele, index) => (
                            <div className="flex justify-between items-center py-1 border-b-2 mb-1.5">
                                <div
                                    key={ele.id}
                                    className={
                                        "text-md font-articulat cursor-pointer capitalize-first hover:underline hover:text-[#005956] font-semibold" +
                                        (currentSubName === ele.title ? " text-[#005956]" : " text-[#6b6b6b]")
                                    }
                                    onClick={() => {
                                        setCurrentSubName(ele.title);
                                        setSearchHandler(ele.id);
                                    }}
                                >
                                    {ele?.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <HomeSubscribe />
        </>
    )
}
export default Blog;