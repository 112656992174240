import { useState, useEffect } from "react";
import { getProductDetails, checkAvailableZip } from "../../../services/index";
import Description from "./description";
import Reviews from "./reviews";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import TextInput from "../../../components/Shared/TextInput";
import { notificationService } from "../../../services/notifications/notificationService";
import { useNavigate, useParams, Link } from "react-router-dom";
import Nutritionist from "./Nutritionist";
import Faq from './Faq';
import Popup from "./popup";
import { getFaq } from "../../../services/footer";
import "./Product.css";
import { capitalizeWords } from "../../../services/utils/function";
import { fetchCartList } from "../../public/Home";
import {
    addToCart,
    removeFromCart,
    updateCartItem,
} from "../../../reducers/cart/actions";
import {
    addItemsToCart,
    deleteCartItem,
    updateCartProducts,
} from "../../../services/checkout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { navigateToNext } from "../../../navigation/navigation";
import images from "../../../images/images";
import { Helmet } from 'react-helmet';
import { SpinLoader } from "../../../app/loader/loader";
import { Icons } from "../../../images/reactIcons";

const ProductDetails = () => {
    let { id } = useParams();
    const [faq, setFaq] = useState([]);
    const [productDetail, setProductDetail] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const userId = localStorage.getItem('userId');
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('');
    const [count, setCount] = useState(1);
    const [varient, setVarient] = useState(null);
    const [variantID, setVariantID] = useState(null);
    const [varientIndex, setVarientIndex] = useState(null);
    const [cost, setCost] = useState(null);
    const [disCost, setDisCost] = useState(null);
    const [addProduct, setAddProduct] = useState(false);
    const [tab, setTab] = useState(0);
    const [currentImg, setCurrentImg] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [pinCode, setPinCode] = useState("");
    const [texture, setTexture] = useState(null);
    const [zipAvailability, setZipAvailability] = useState({ check: false, available: false });
    const dispatch = useDispatch();
    const cartStore = useSelector(state => state.cartReducer.cart);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const getFaqList = () => {
        getFaq()
            .then((response) => {
                if (response.success && response.receiveObj) {
                    const data = response?.receiveObj;
                    setFaq(data);
                }
            })
            .catch((error) => {
                setFaq({});
                notificationService.sendMessage({
                    type: "error",
                    title: "FAQ List",
                    text: error?.receiveObj?.message,
                });
            });
    };

    const getProductsList = (id) => {
        getProductDetails(id)
            .then((response) => {
                setLoading(false)
                if (response.success && response.receiveObj) {
                    const data = response.receiveObj;
                    setProductDetail(data);
                    setCost(data.product.cost_price);
                    setDisCost(data.product.master_price);
                    setVarient(data.product.net_wt + data.product.unit);
                    data?.product?.variants.length > 0 && setVariantID(data?.product?.variant_id);
                    data?.product?.textures?.length > 0 ? setTexture(data?.product?.textures[0]) : setTexture(null);
                    setVarientIndex({
                        price: data.product.master_price,
                        unit: data.product.unit,
                        net_wt: data.product.net_wt,
                    });
                    setCurrentImage(data.product.attachment_url[0]);
                }
            })
            .catch((error) => {
                setProductDetail(null);
                notificationService.sendMessage({
                    type: "error",
                    title: "Product Details",
                    text: error?.receiveObj?.message,
                });
            });
    };

    const checkPinHandler = () => {
        const regex = /[^0-9]/g;
        if (pinCode?.length !== 6 || regex?.test(pinCode)) {
            setMessage({
                error: true,
                msg: "Please enter a valid 6-digit PIN code number!",
            });
        } else {
            setMessage(null);
            const payload = {
                id: id,
                zip_code: pinCode,
            };

            checkAvailableZip(payload)
                .then((response) => {
                    if (response.success && response.receiveObj) {
                        const data = response.receiveObj;
                        if (data.available) {
                            setMessage({ error: false, msg: "Delivery available" });
                            setZipAvailability({ check: true, available: true });
                        } else {
                            setMessage({
                                error: false,
                                msg: "We apologize, but we do not offer service in your area!",
                            });
                            setZipAvailability({ check: true, available: false });
                        }
                    }
                })
                .catch((error) => {
                    if (!error?.success) {
                        setMessage({
                            error: true,
                            msg: "We apologize, but we do not offer service in your area!",
                        });
                        setZipAvailability({ check: true, available: false });
                    } else {
                        setMessage({
                            error: true,
                            msg: "Something went wrong. Please try again!",
                        });
                        setZipAvailability({ check: false, available: false });
                    }
                });
        }
    };

    const rating = (n = 0) => {
        const arr = [];
        for (let i = 0; i < n; i++) {
            arr.push(i);
        }
        return arr;
    };

    useEffect(() => {
        if (token) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, [token]);


    const checkProductInCart = (id) => {
        const productInCart = cartStore?.find((item) => {
            return item.id === id && item.variant_id === variantID && (item?.texture_id !== null && item?.texture_id !== undefined ? item?.texture_id === texture?.id : true)
        });
        return productInCart;
    };

    const checkQuatityInCart = (id, variantId, textureId) => {
        const quantityInCart = cartStore?.find((item) => item.id === id && item.variant_id === variantId && (item?.texture_id !== null && item?.texture_id !== undefined ? item?.texture_id === texture?.id : true));
        quantityInCart ? setCount(quantityInCart?.quantity) : setCount(1);
    }

    const handleAddToCart = (product) => {
        setMessage(null);
        const { id, variant_id, textures } = product;
        const textureId = textures?.length > 0 ? textures[0]?.id : null;
        if (checkProductInCart(product?.id, variantID)) {
            if (auth) {
                deleteCartItem({ productId: userId, variantId: variant_id, textureId: textureId }).then(() => {
                    fetchCartList(dispatch);
                });
            }
            dispatch(removeFromCart(id, variant_id, textureId));
        } else {
            if (auth) {
                addItemsToCart({ product_id: product.id, quantity: count, texture_id: (product.textures?.length > 0 ? (texture?.id ? texture?.id : product?.textures[0]?.id) : null), variant_id: variantID ? variantID : product?.variant_id }).then(() => {
                    fetchCartList(dispatch);
                });
            }
            !variantID && setVariantID(product?.variant_id);
            dispatch(addToCart({ ...product, quantity: count, variant_id: variantID ? variantID : product?.variant_id, texture_id: (product.textures?.length > 0 ? (texture?.id ? texture?.id : product?.textures[0]?.id) : null) }));
        }

    };

    const handleRemoveFromCart = (val) => {
        setCount(val - 1);
        if (checkProductInCart(productDetail?.product?.id, variantID)) {
            const prductId = productDetail?.product?.id;
            const textureId = texture?.id;
            deleteCartItem({ productId: userId, variantId: variantID, textureId: textureId }).then(() => {
                fetchCartList(dispatch);
            });
            dispatch(removeFromCart(prductId, variantID, textureId));
        }
    };

    const handleUpdateCartItem = (val) => {
        productDetail?.product?.quantity ? setCount(productDetail?.product?.quantity + 1) : setCount(val + 1);
        if (checkProductInCart(productDetail?.product?.id, variantID)) {
            updateCartProducts(variantID, { "quantity": val + 1, texture_id: texture?.id, variant_id: variantID }).then(() => { fetchCartList(dispatch) });
        };
        dispatch(updateCartItem(variantID, val + 1, texture?.id));
    };

    const setCartHandler = (product) => {
        const productInCart = cartStore?.find((item) => item.id === product.id);
        const { id, variant_id, textures } = product;
        const textureId = textures?.length > 0 ? textures[0]?.id : null;
        if (productInCart) {
            deleteCartItem({ productId: userId, variantId: variant_id, textureId: textureId }).then(() => {
                fetchCartList(dispatch);
            });
            dispatch(removeFromCart(id, variant_id, textureId));
        } else {
            addItemsToCart({ product_id: product.id, quantity: 1, variant_id: variant_id, texture_id: textureId }).then(() => {
                fetchCartList(dispatch);
            });
            dispatch(addToCart({ ...product, quantity: 1 }));
            setAddProduct({ ...product, quantity: 1 });
            setTimeout(() => {
                setAddProduct(null);
            }, 2000);
        }
    };

    useEffect(() => {
        setLoading(true)
        getProductsList(id);
        getFaqList();
    }, [id]);

    useEffect(() => {
        if (productDetail && checkProductInCart(productDetail?.product?.id, variantID, texture?.id)) {
            const obj = cartStore?.find(element => element.id === productDetail?.product?.id && element.variant_id === productDetail.product.variant_id && (element?.texture_id !== null && element?.texture_id !== undefined ? element?.texture_id === productDetail.product.texture_id : true));
            setCount(obj?.quantity);
        };
        if (cartStore?.length > 0) {
            const obj = cartStore.find(ele => ele.id === productDetail?.product?.id && ele.variant_id === productDetail.product.variant_id && (ele?.texture_id !== null && ele?.texture_id !== undefined ? ele?.texture_id === productDetail.product.texture_id : true));
            obj ? setCount(obj?.quantity) : setCount(1);
        }
    }, [productDetail]);

    return (
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-2 mt-32 mb-10 productdetailsmaincontainder">
            <Helmet>
                <title>{productDetail?.product?.meta_title}</title>
                <meta name="description" content={productDetail?.product?.meta_description} />
                <meta property="og:title" content={productDetail?.product?.meta_title} />
                <meta property="og:description" content={productDetail?.product?.meta_description} />
                <meta property="og:image" content={currentImage} />
            </Helmet>
            {addProduct && <Popup product={addProduct} />}
            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                {isLoading && <div className="mt-20"><SpinLoader height="55px" width="55px" /></div>}</div>
            {productDetail && (
                <>
                    <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 m-5 productsubcard">
                        {productDetail?.product?.attachment_url?.map((ele, i) =>
                            <img src={ele} alt="product-detailImage" style={{ width: "150px", height: "170px" }} key={i} loading="lazy" className={(currentImg === i ? "" : "") + " object-contain  h-28"} onClick={() => { setCurrentImage(ele); setCurrentImg(i) }} />
                        )}
                    </div>
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm m-5 font-articulat font-semibold cursor-pointer productdetailslink-1">
                        <span onClick={(e) => navigateToNext(e, "/home", navigate)}>Home  /  </span>
                        <span onClick={(e) => navigateToNext(e, `/product/${productDetail?.product?.category?.id}`, navigate)}>{productDetail?.product?.category?.name}  /  </span>
                        <span className="text-[#009898]">{productDetail?.product?.subcategory?.name}</span>
                    </div>
                    <div className="lg:col-span-4 md:col-span-9 sm:col-span-9 m-5 bg-[#FBF5F1] rounded-md inset-0 flex pt-12 pb-12 h-auto mb-10">
                        <img src={currentImage} alt="product-image" loading="lazy" className="h-96 w-96 m-auto rounded-lg" />
                    </div>
                    <div className="ml-5 productsubcard-1 gap-3">
                        {productDetail?.product?.attachment_url?.map((ele, i) =>
                            <img src={ele} alt="productImage" loading="lazy" style={{ width: "100px", height: "100px" }} key={i} className={(currentImg === i ? "" : "") + "  object-contain  h-28"} onClick={() => { setCurrentImage(ele); setCurrentImg(i) }} />
                        )}
                    </div>

                    <div className="lg:col-span-6 md:col-span-12 sm:col-span-12 m-5">
                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-sm font-articulat font-semibold cursor-pointer productdetailslink">
                                <span onClick={(e) => navigateToNext(e, "/home", navigate)}>Home  /  </span>
                                <span onClick={(e) => navigateToNext(e, `/product${productDetail?.product?.category?.id}`, navigate)}>{productDetail?.product?.category?.name}  /  </span>
                                <span className="relative">
                                    <span className="absolute inset-0 bg-white opacity-70" aria-hidden="true"></span>
                                    <span className="relative text-[#009898]">
                                        {productDetail?.product?.subcategory?.name}
                                    </span>
                                </span>
                            </div>
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4 text-2xl font-articulat font-normal">
                                {capitalizeWords(productDetail?.product?.name)}
                            </div>
                            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 mt-1 text-2xl font-articulat font-semibold ">
                                ₹{(cost === null || cost === 0.0) ? disCost : cost}  {disCost && <span className="text-[#667479] line-through font-normal text-xl"> {(cost === null || cost === 0.0) ? "" : "₹" + disCost}</span>}
                            </div>
                            <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 mt-1 text-right">
                                {tab === 3 && productDetail?.product?.overall_average_rating !== 0 && (
                                    <span className="flex text-xl font-articulat font-normal justify-end items-center">
                                        {rating(productDetail?.product?.overall_average_rating).map((r, index) => (
                                            <Icons.FaStar key={index} className="text-[#E9C400] h-6 w-6" />
                                        ))}
                                        <span className="text-lg p-2 font-base">
                                            &nbsp;&nbsp;{productDetail?.product?.overall_average_rating} (5 Reviews)
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4 text-sm font-articulat font-normal">
                                <Typography
                                    variant="body2"
                                    dangerouslySetInnerHTML={{ __html: productDetail?.product?.short_description }}
                                    className="dynamin-content short-des"
                                    sx={{ fontFamily: 'Greycliff CF' }}
                                />
                            </div>

                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-5 text-sm font-articulat font-semibold">
                                Net wt:
                            </div>
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 productdetailsnetwt gap-2 mt-2">
                                    {productDetail?.product?.variants?.map((ele, i) => {
                                        return ele.net_wt + ele.unit !== varient ? (
                                            <button
                                                className="lg:col-span-2 md:col-span-4 sm:col-span-3 xs:col-span-4 focus:outline-none focus:ring-[#163f16] focus:ring-4 border border-['#FBF5F1'] rounded-lg hover:bg-[#163f16] hover:text-white font-md text-sm px-4 py-2"
                                                onClick={() => {
                                                    setVarient(ele.net_wt + ele.unit);
                                                    setVariantID(ele.id);
                                                    setCost(ele.discount_price);
                                                    setDisCost(ele.price);
                                                    setVarientIndex(ele);
                                                    checkQuatityInCart(productDetail?.product?.id, ele.id, texture?.id)
                                                }}
                                                key={i}
                                            >
                                                {ele.net_wt + ele.unit}
                                            </button>
                                        ) : (
                                            <button
                                                className="lg:col-span-2 md:col-span-4 sm:col-span-3 xs:col-span-3 focus:outline-none font-md rounded-lg text-sm px-4 py-2 text-white buttoncommon hover:bg-[#163f16] hover:text-white focus:ring-4 focus:ring-[#163f16] border"
                                                onClick={() => {
                                                    setVarient(ele.net_wt + ele.unit);
                                                    setVariantID(ele.id);
                                                    setCost(ele.discount_price);
                                                    setDisCost(ele.price);
                                                    setVarientIndex(ele);
                                                    checkQuatityInCart(productDetail?.product?.id, ele.id, texture?.id)
                                                }}
                                                key={i}
                                            >
                                                {ele.net_wt + ele.unit}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            {productDetail?.product?.is_texture && (
                                <>
                                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-sm font-articulat font-semibold">
                                        Texture:
                                    </div>
                                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-1">
                                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 productdetailsnetwt">
                                            {productDetail?.product?.textures?.map((ele, i) =>
                                                texture?.name !== ele.name ? (
                                                    <button
                                                        className="m-1 lg:col-span-3 md:col-span-4 sm:col-span-4 focus:outline-none focus:ring-[#163f16] focus:ring-4 border border-['#FBF5F1'] rounded-lg hover:bg-[#163f16] hover:text-white font-md text-sm px-4 py-2"
                                                        onClick={() => {
                                                            setTexture(ele);
                                                            checkQuatityInCart(productDetail?.product?.id, variantID, ele?.id);
                                                        }}
                                                        key={i}
                                                    >
                                                        {capitalizeWords(ele.name)}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="m-1 lg:col-span-3 md:col-span-4 sm:col-span-4 focus:outline-none font-md rounded-lg text-sm px-4 py-2 text-white buttoncommon hover:bg-[#163f16]  hover:text-white focus:ring-4 focus:ring-[#163f16] border"
                                                        onClick={() => {
                                                            setTexture(ele);
                                                            checkQuatityInCart(productDetail?.product?.id, variantID, ele?.id);
                                                        }}
                                                        key={i}
                                                    >
                                                        {capitalizeWords(ele.name)}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="flex items-center gap-1 lg:col-span-12 md:col-span-12 sm:col-span-12 mt-5 text-sm font-articulat font-semibold">
                                <Icons.IoLocationSharp />
                                Check pin code availability
                            </div>
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12">
                                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 productdetailspinbox gap-2 mt-3">
                                    <div className="lg:col-span-9 md:col-span-8 sm:col-span-8 productdetailspininput">
                                        <TextInput
                                            onChange={(e) => {
                                                setPinCode(e.target.value);
                                                setMessage(null);
                                                setZipAvailability({ check: false, available: false });
                                            }}
                                            value={pinCode}
                                            type="text"
                                            id="pin"
                                            pattern="[0-9]*"
                                            maxLength="6"
                                            errorMessage="Pin Code is Required."
                                            placeholder="Enter Pin Code"
                                            isLabelShow={false}
                                            showLabel="Pin Code"
                                            error={false}
                                        />
                                    </div>
                                    <button
                                        className="lg:col-span-3 md:col-span-4 sm:col-span-4 text-center focus:outline-none text-white w-fulll buttoncommon hover:bg-[#163f16] hover:text-white focus:ring-4 focus:ring-[#163f16] font-medium rounded-lg text-sm p-2 productdetailspinbutton"
                                        onClick={() => checkPinHandler()}
                                    >
                                        Check
                                    </button>
                                </div>
                            </div>
                            {message && (
                                <div
                                    className={
                                        (message?.error === false
                                            ? "text-[#32CD32] "
                                            : "text-[#FF0000] ") +
                                        "lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2 text-sm font-articulat font-normal mb-2"
                                    }
                                >
                                    {message?.msg}
                                </div>
                            )}
                            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-5">
                                <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 productdetailsaddcard gap-2">
                                    <div className="lg:col-span-4 md:col-span-4 sm:col-span-4">
                                        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 text-center border border-[#FBF5F1] divide-x rounded-md cursor-pointer productdetailsaddinput">
                                            <span
                                                className={
                                                    (count <= 1
                                                        ? "cursor-not-allowed "
                                                        : "cursor-pointer ") +
                                                    "lg:col-span-4 md:col-span-4 self-center sm:col-span-3 focus:outline-none focus:ring-4 font-md text-sm px-7 py-2.5 p-1 focus:ring-[#163f16]"
                                                }
                                                onClick={() => {
                                                    count > 1 && handleRemoveFromCart(count);
                                                }}
                                            >
                                                <Icons.FaMinus />
                                            </span>
                                            <span className="lg:col-span-4 md:col-span-4 sm:col-span-6 focus:outline-none focus:ring-4 font-md text-sm px-5 py-2.5 p-1 text-center">
                                                {count}
                                            </span>
                                            <span
                                                className="lg:col-span-4 md:col-span-4 sm:col-span-3 self-center focus:outline-none font-md text-sm px-7 py-2.5 p-1 text-white buttoncommon hover:bg-[#163f16] hover:text-white focus:ring-4 focus:ring-[#163f16]"
                                                onClick={() => {
                                                    handleUpdateCartItem(count);
                                                }}
                                                style={{
                                                    borderTopRightRadius: "5px",
                                                    borderBottomRightRadius: "5px"
                                                }}
                                            >
                                                <Icons.FaPlus />
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        className="lg:col-span-8 md:col-span-8 sm:col-span-8 focus:outline-none font-md rounded-lg text-sm px-5 py-2.5 p-1 text-[#163f16] border-[#163f16] focus:ring-4 focus:ring-[#163f16] border productaddbutton"
                                        onClick={() => handleAddToCart(productDetail?.product)}
                                    >
                                        {checkProductInCart(productDetail?.product?.id)
                                            ? "Remove Item"
                                            : "Add To Cart"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 m-2">
                <ul className="flex flex-wrap -mb-px lg:col-span-4 md:col-span-4 sm:col-span-12 text-sm font-articulat font-semibold cursor-pointer">
                    <li className="me-2" onClick={() => setTab(0)}>
                        <span
                            className={
                                tab === 0
                                    ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active"
                                    : "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898]"
                            }
                            aria-current="page"
                        >
                            Description
                        </span>
                    </li>
                    <li className="me-2" onClick={() => setTab(1)}>
                        <span
                            className={
                                tab === 1
                                    ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active"
                                    : "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898]"
                            }
                            aria-current="page"
                        >
                            Nutrition Details
                        </span>
                    </li>
                    <li className="me-2" onClick={() => setTab(2)}>
                        <span
                            className={
                                tab === 2
                                    ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active"
                                    : "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898]"
                            }
                            aria-current="page"
                        >
                            FAQ
                        </span>
                    </li>
                    <li className="me-2" onClick={() => setTab(3)}>
                        <span
                            className={
                                tab === 3
                                    ? "inline-block p-1 border-b-4 border-[#009898] rounded-t-lg active"
                                    : "inline-block p-1 border-b-4 border-transparent rounded-t-lg hover:text-[#009898]"
                            }
                            aria-current="page"
                        >
                            Reviews
                        </span>
                    </li>
                </ul>
                <span className="text-[#FBF5F1]">
                    <hr />
                </span>
            </div>

            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 m-2">
                {tab === 0 ? (
                    <Description product={productDetail?.product} />
                ) : tab === 1 ? <Nutritionist nutritionistDetails={productDetail?.product?.nutrition_description} /> : tab === 2 ? (
                    <Faq faqDetails={productDetail?.product?.faqs} />
                ) :
                    <Reviews
                        reviews={productDetail?.product?.reviews}
                        productId={id}
                        refresh={() => {
                            getProductsList(id);
                        }}
                    />}
            </div>

            {productDetail?.related_products?.length !== 0 && (
                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-1 text-lg p-2 pb-5 font-articulat font-semibold text-center">
                    You May Also Like
                </div>
            )}
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 productdetailsyoumaybox">
                <Grid container spacing={2} className='p-5'>
                    {productDetail?.related_products?.map((ele, i) => {
                        let cartShow = cartStore?.find((item) => item?.id === ele.id);
                        const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;
                        return (
                            <Grid key={ele.id} item xs={6} sm={6} md={4} lg={3} xl={3}><Box>
                                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 m-3">
                                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 rounded-xl inset-0 flex">
                                        <Link to={productUrl}>
                                            <img src={ele.attachment_url[0]} alt="productUrlImage" loading="lazy" className="2xl:w-full 2xl:h-full sm:w-[290px] sm:h-[220px] m-auto rounded-lg cursor-pointer"
                                            />
                                        </Link>
                                    </div>
                                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal mt-2 hover:text-[#268126]">
                                        <Link to={productUrl}>
                                            {ele.name}
                                        </Link>
                                    </div>
                                    <Grid container spacing={2} className="product-content" style={{ marginTop: "-10px" }} >
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="product-content1 bg-white">
                                            <Box>
                                                <span className="text-[#333333] text-xs font-articulat font-normal">Net wt. : </span>
                                                <span className=" text-xs font-articulat font-semibold">{ele.net_wt + ele.unit}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1} lg={1} xl={1} className="home-divd">
                                            <Divider orientation="vertical" />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7} lg={7} xl={7} className="product-content2">
                                            <Grid container spacing={1} >
                                                <Grid item xs={8} sm={8} md={9} lg={9} xl={9} className="text-right bg-white">
                                                    <span className="text-[#667479] text-xs font-articulat font-normal">MRP : </span>
                                                    <span className=" text-xs font-articulat font-semibold">₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price} </span>
                                                    {ele.cost_price && <span className="text-[#333333] text-xs font-articulat font-light line-through"> {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? "₹" + ele.master_price : ''}</span>}
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="text-right mr-10 productaddcardbut">
                                                    <span className="cart-icn" style={{ textAlign: '-webkit-right' }}>
                                                        <img className="cursor-pointer w-7 h-7" alt="cartActiveImage" loading="lazy" src={cartShow ? images.cartActive : images.cartInactive} onClick={() => setCartHandler(ele)} />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                            </Grid>
                        )
                    })}
                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-9"><hr /></div>
                </Grid>
            </div>
            <div className="productdetailsyoumaybox-1 p-4">
                {productDetail?.related_products?.map((ele) => {
                    let cartShow = cartStore?.find((item) => item.id === ele.id);
                    const productUrl = `/productdetails/${encodeURIComponent(ele?.category?.name)}/${encodeURIComponent(ele?.subcategory?.name)}/${ele.id}`;
                    return (
                        <div className="" key={ele?.id}>
                            <div className="productdetailsyoumayboxsubbox">
                                <Link to={productUrl}>
                                    <img
                                        alt="productImageSingle"
                                        loading="lazy"
                                        src={ele.attachment_url[0]}
                                        className="w-full m-auto rounded-lg cursor-pointer"
                                    />
                                </Link>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 text-base font-articulat font-normal mt-2 sm:h-10 xxs:h-[66px] cursor-pointer hover:text-[#268126]">
                                    <Link to={productUrl}> {ele.name}</Link>
                                </div>
                                <div className="productdetailsyoumayboxpricebox bg-white">
                                    <div>
                                        <div>
                                            <span className="text-[#333333] text-xs font-articulat font-normal">
                                                Net wt. :
                                            </span>
                                            <span className=" text-xs font-articulat font-semibold">
                                                {ele.net_wt + ele.unit}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="text-[#667479] text-xs font-articulat font-normal">
                                                MRP :
                                            </span>
                                            <span className=" text-xs font-articulat font-semibold">
                                                ₹{(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? ele.cost_price : ele.master_price}
                                            </span>
                                            {ele.cost_price && <span className="text-[#333333] text-xs font-articulat font-light line-through">
                                                {(ele.cost_price !== null && parseFloat(ele.cost_price) !== 0) ? "₹" + ele.master_price : ''}
                                            </span>}
                                        </div>
                                    </div>
                                    <div>
                                        <Tooltip
                                            anchorSelect=".cart"
                                            place="left"
                                            style={{ backgroundColor: "#FBF5F1", color: "#222" }}
                                        >
                                            Add to cart
                                        </Tooltip>
                                        <div className="text-right lg:col-span-3 md:col-span-3 sm:col-span-3">
                                            <span style={{ textAlign: '-webkit-right' }}>
                                                <img className="cursor-pointer" alt="cartActiveInactive" loading="lazy" src={cartShow ? images.cartActive : images.cartInactive} onClick={() => setCartHandler(ele)} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};
export default ProductDetails;
