import { useEffect, useState } from "react";
import { fetchVouchers } from "../../../services/profile";
import { getCurrentDate } from "../../../services/utils/function";
import NoDataFound from "../../../services/utils/noDataPopUp";
import { Typography } from "@mui/material";

const Vouchers = () => {
    const [vouchers, setVouchers] = useState([]);
    const [showCode, setShowCode] = useState([]);
    const [showCopyCode, setCopyCode] = useState(null);

    const getAllVouchers = () => {
        fetchVouchers()
            .then((response) => {
                if (response?.constructor === Array) {
                    setVouchers(response)
                };
            })
            .catch((error) => {
                return error;
            });
    };

    const copyCode = (val, i) => {
        navigator.clipboard.writeText(val?.code);
        setCopyCode({ idx: i, show: true });
        setTimeout(() => {
            setCopyCode({ idx: i, show: false });
        }, 1000);
    };

    const addOrRemoveItemFromArray = (itemToAddOrRemove) => {
        let newArray = [...showCode];

        const index = newArray.indexOf(itemToAddOrRemove?.id);
        if (index !== -1) {
            newArray.splice(index, 1);
        } else {
            newArray.push(itemToAddOrRemove?.id);
        };

        setShowCode(newArray);
    };

    const checkIfItemExists = (itemToCheck) => {
        return showCode.includes(itemToCheck);
    }

    useEffect(() => {
        getAllVouchers();
    }, []);

    return (
        <>
            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 font-articulat mt-4 mb-4" style={{ fontSize: '16px', fontWeight: '600' }}>Vouchers</div>
            {vouchers.length !== 0 ? (
                vouchers.map((ele, i) => {
                    return (
                        <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 font-articulat mt-2 bg-[#FBF5F1] p-4 m-2 rounded-lg" key={i}>
                            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12" style={{ height: '100%' }}>
                                <div className="lg:col-span-9 md:col-span-9 sm:col-span-9 text-sm font-semibold">{(ele?.title)?.toUpperCase()}</div>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12" style={{ fontSize: '14px', fontWeight: '300' }}>
                                    <Typography variant="body2" className="discription" dangerouslySetInnerHTML={{ __html: ele?.description }} />
                                </div>
                                <div className="lg:col-span-12 md:col-span-12 sm:col-span-12"
                                    style={{
                                        display: "flex",
                                        marginTop: "10px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{
                                        fontSize: "14px",
                                        color: "#999999"
                                    }}>Valid till {getCurrentDate(ele?.expiry_date)}</div>
                                    <div style={{ display: "flex", marginRight: "10px", position: 'relative' }}>
                                        {ele?.status !== 'Unused' ? (
                                            <div style={{ fontSize: '14px', color: '#999999' }}>{ele?.status}</div>
                                        ) : (
                                            <>
                                                {!checkIfItemExists(ele.id) ? (
                                                    <button
                                                        style={{
                                                            padding: "6px 15px",
                                                            color: "#fff",
                                                            fontSize: "14px",
                                                            borderRadius: "5px"
                                                        }}
                                                        className="buttoncommon"
                                                        onClick={() => { addOrRemoveItemFromArray(ele) }}
                                                    >Get Code</button>
                                                ) : (
                                                    <>
                                                        <div style={{ marginRight: "15px", fontSize: '14px' }}>{(ele?.code).toUpperCase()}</div>
                                                        {showCopyCode?.idx === i && showCopyCode?.show && (
                                                            <div
                                                                style={{
                                                                    marginRight: "15px",
                                                                    fontSize: "12px",
                                                                    position: "absolute",
                                                                    backgroundColor: "#a4dd9f",
                                                                    padding: "5px 10px",
                                                                    borderRadius: "5px",
                                                                    color: "#107010",
                                                                    top: "-31px",
                                                                    left: "73%"
                                                                }}
                                                            >Copied</div>
                                                        )}
                                                        <button className="code" onClick={() => { copyCode(ele, i); }}>
                                                            <i style={{ fontSize: "18px" }} className="fa-regular fa-copy" aria-hidden="true"></i>
                                                        </button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) : (
                <NoDataFound />
            )}
        </>
    )
}
export default Vouchers;