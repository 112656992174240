import CommingSoonMainPage from "../../../components/greatethings/commingsoonpage";
import ApplicationHelmet from "../../../navigation/navigation";

const Nutritionist = () => {

    return (
        <> 
           <ApplicationHelmet/>
           <CommingSoonMainPage/>
        </>
    );
};

export default Nutritionist;