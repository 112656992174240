// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q-icos{
    position: absolute;
    right: 0;
}
.faqanswers p{
    display: inline;
}
.faqanswers ul{
    padding: revert;
    list-style: disc;
}


@media screen and (max-width:639px) {
    .faq-head {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        /* Enable vertical scrolling */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        /* Hide scrollbar for Chrome, Safari, and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        margin-bottom: 10px;

        div {
            text-wrap: nowrap;
            margin-right: 10px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/view/public/FAQ/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI;QACI,aAAa;QACb,iBAAiB;QACjB,kBAAkB;QAClB,8BAA8B;QAC9B,wBAAwB;QACxB,gBAAgB;QAChB,qBAAqB;QACrB,YAAY;;QAEZ,iDAAiD;QACjD;YACI,aAAa;QACjB;QACA,mBAAmB;;QAEnB;YACI,iBAAiB;YACjB,kBAAkB;QACtB;IACJ;AACJ","sourcesContent":[".q-icos{\n    position: absolute;\n    right: 0;\n}\n.faqanswers p{\n    display: inline;\n}\n.faqanswers ul{\n    padding: revert;\n    list-style: disc;\n}\n\n\n@media screen and (max-width:639px) {\n    .faq-head {\n        display: flex;\n        flex-wrap: nowrap;\n        overflow-x: scroll;\n        /* Enable vertical scrolling */\n        -ms-overflow-style: none;\n        /* IE and Edge */\n        scrollbar-width: none;\n        /* Firefox */\n\n        /* Hide scrollbar for Chrome, Safari, and Opera */\n        &::-webkit-scrollbar {\n            display: none;\n        }\n        margin-bottom: 10px;\n\n        div {\n            text-wrap: nowrap;\n            margin-right: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
