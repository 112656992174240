// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileselectdiv{
    select{
        background-color:#fff;
        height: 38px;
        color: #000;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SelectInput/selectinput.css"],"names":[],"mappings":"AAAA;IACI;QACI,qBAAqB;QACrB,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".profileselectdiv{\n    select{\n        background-color:#fff;\n        height: 38px;\n        color: #000;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
